import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'

import { Button, Item, Group } from '@prostpost/uikit'

type Props = {
	onClick: () => void
}

export const DangerZone = ({ onClick }: Props) => {
	const theme = useTheme()
	const { t } = useTranslation()

	return (
		<Group.Item>
			<Group.ItemContent>
				<Item variant="labeled">
					<Item.Title>{t('account.delete.actionDescription', 'Completely remove your account')}</Item.Title>
				</Item>
			</Group.ItemContent>
			<Group.ItemSide>
				<Button.Action
					size="mid"
					detail="danger"
					variant="secondary"
					icon={<Icons.Trash size={16} strokeWidth={2.5} color={theme.colors.white_100} />}
					onClick={onClick}
				>
					{t('account.delete.actionLabel', 'Delete account')}
				</Button.Action>
			</Group.ItemSide>
		</Group.Item>
	)
}
