import { useState, useEffect } from 'react'
import { Observer } from 'mobx-react-lite'

import { notReachable } from '@prostpost/utils'
import { Modal, GroupList, VStack } from '@prostpost/uikit'

import { routesUrls } from 'app/routes/urls'

import { useSignout } from 'app/domains/User/hooks'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { ChangeEmail } from 'app/domains/User/features/ChangeEmail'
import { LinkBotToUser } from 'app/domains/Bot/features/LinkBotToUser'
import { ChangePassword } from 'app/domains/User/features/ChangePassword'
import { DeleteAccount } from 'app/domains/User/features/DeleteAccount'
import { UnlinkBotFromUser } from 'app/domains/Bot/features/UnlinkBotFromUser'
import { useUpdateUserPreferences } from 'app/domains/UserPreferences/hooks'
import { UpdateTimeRegion } from 'app/domains/UserPreferences/features/UpdateTimeRegion'
import { UpdateFeedViewPreferences } from 'app/domains/UserPreferences/features/UpdateFeedViewPreferences'

import { ReactModal } from 'app/modals'

import { Header, AccountTab, Limits, PlansGroup } from './layout'
import type { Tabs } from './types'

type AccountTabNestedModals = 'change_email' | 'change_password' | 'connect_bot' | 'disconnect_bot' | 'delete_account'

type Props =
	| {
			currentTab: 'account'
			nestedModal: 'change_email' | 'change_password' | 'connect_bot' | 'disconnect_bot' | 'delete_account' | null
	  }
	| {
			currentTab: 'plan' | 'preferences'
	  }

const UserSettingsContent = ({ onClose, ...props }: Props & { onClose: () => void }) => {
	const { preferences } = useOnboardedUserStore()

	const { signOut } = useSignout({ redirectTo: routesUrls.auth.signin })
	const { mutate: mutatePreferences, loadingState: preferencesLoadingState } = useUpdateUserPreferences()

	const [currentTab, setCurrentTab] = useState<Tabs>(props.currentTab || 'account')
	const [nestedPopupOpen, setNestedPopupOpen] = useState<null | AccountTabNestedModals>(null)

	// open nested modal if it was passed as prop
	useEffect(() => {
		switch (props.currentTab) {
			case 'account':
				setNestedPopupOpen(props.nestedModal)
				break
			case 'plan':
			case 'preferences':
				break
			default:
				notReachable(props)
		}
	}, [])

	return (
		<Modal variant="content" mobileScrollOffset={94}>
			<Header currentTab={currentTab} setCurrentTab={setCurrentTab} onClose={onClose} />
			<Modal.Content>
				{(() => {
					switch (currentTab) {
						case 'account':
							return (
								<AccountTab
									onMsg={msg => {
										switch (msg.type) {
											case 'on_trigger_change_email':
												setNestedPopupOpen('change_email')
												break
											case 'on_trigger_change_password':
												setNestedPopupOpen('change_password')
												break
											case 'on_trigger_connect_bot':
												setNestedPopupOpen('connect_bot')
												break
											case 'on_trigger_disconnect_bot':
												setNestedPopupOpen('disconnect_bot')
												break
											case 'on_trigger_delete_account':
												setNestedPopupOpen('delete_account')
												break
											default:
												notReachable(msg)
										}
									}}
								/>
							)
						case 'plan':
							return (
								<Observer>
									{() => (
										<VStack space={7}>
											{(() => {
												switch (preferences.plan.name) {
													case 'TRIAL':
														return (
															<Limits.Trial postsLimit={preferences.plan.limits.posts} />
														)
													case 'STARTER':
													case 'PRO':
													case 'CUSTOM':
														return <Limits.Plan />
													default:
														return notReachable(preferences.plan)
												}
											})()}
											<PlansGroup />
										</VStack>
									)}
								</Observer>
							)
						case 'preferences':
							return (
								<GroupList variant="item">
									<UpdateTimeRegion
										loadingState={preferencesLoadingState}
										onMsg={msg => {
											switch (msg.type) {
												case 'on_change_language':
													mutatePreferences({ lang: msg.value })
													break
												case 'on_change_time_format':
													mutatePreferences({ timeFormat24: msg.value })
													break
												case 'on_change_time_zone':
													mutatePreferences({ timeZone: msg.value })
													break
												default:
													notReachable(msg)
											}
										}}
									/>
									<UpdateFeedViewPreferences
										loadingState={preferencesLoadingState}
										onMsg={msg => {
											switch (msg.type) {
												case 'on_change_show_post_images':
													mutatePreferences({ more: { simpleFeedView: !msg.value } })
													break
												case 'on_change_show_unsupported_posts':
													mutatePreferences({ more: { supportedOnlyFeedView: !msg.value } })
													break
												default:
													notReachable(msg)
											}
										}}
									/>
								</GroupList>
							)
						default:
							return notReachable(currentTab)
					}
				})()}
			</Modal.Content>

			<ReactModal shouldCloseOnEsc shouldCloseOnOverlayClick isOpen={!!nestedPopupOpen}>
				{(() => {
					if (nestedPopupOpen) {
						switch (nestedPopupOpen) {
							case 'change_email':
								return (
									<ChangeEmail
										onMsg={msg => {
											switch (msg.type) {
												case 'on_close':
												case 'on_success':
													setNestedPopupOpen(null)
													break
												default:
													notReachable(msg)
											}
										}}
									/>
								)
							case 'change_password':
								return (
									<ChangePassword
										onMsg={msg => {
											switch (msg.type) {
												case 'on_close':
												case 'on_success':
													setNestedPopupOpen(null)
													break
												default:
													notReachable(msg)
											}
										}}
									/>
								)
							case 'connect_bot':
								return (
									<LinkBotToUser
										variant="modal"
										isOpen={nestedPopupOpen === 'connect_bot'}
										onMsg={msg => {
											switch (msg.type) {
												case 'on_bot_link_modal_close':
												case 'on_bot_is_linked_to_user':
													setNestedPopupOpen(null)
													break
												default:
													notReachable(msg)
											}
										}}
									/>
								)
							case 'disconnect_bot':
								return (
									<UnlinkBotFromUser
										variant="modal"
										isOpen={nestedPopupOpen === 'disconnect_bot'}
										onMsg={msg => {
											switch (msg.type) {
												case 'on_bot_unlink_modal_close':
													setNestedPopupOpen(null)
													break
												default:
													notReachable(msg.type)
											}
										}}
									/>
								)
							case 'delete_account':
								return (
									<DeleteAccount
										isOpen={nestedPopupOpen === 'delete_account'}
										onMsg={msg => {
											switch (msg.type) {
												case 'on_close':
													setNestedPopupOpen(null)
													break
												case 'on_success':
													signOut()
													break
												default:
													notReachable(msg)
											}
										}}
									/>
								)
							default:
								return notReachable(nestedPopupOpen)
						}
					}

					return null
				})()}
			</ReactModal>
		</Modal>
	)
}

// we need it as a separate component, so we render "observer" in portal, and it doesn't exist in React DOM tree
// when a modal is not opened (while this component UserSettingsModal always exists in the React DOM)
export const UserSettings = ({ isOpen, ...props }: Props & { isOpen: boolean; onClose: () => void }) => {
	return (
		<ReactModal
			isOpen={isOpen}
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			onRequestClose={e => {
				e.stopPropagation()
				e.preventDefault()
				props.onClose()
			}}
		>
			<UserSettingsContent {...props} />
		</ReactModal>
	)
}
