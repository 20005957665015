import { memo } from 'react'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'

import { Flex, Text } from '@prostpost/uikit'
import { shortNumber } from '@prostpost/utils'

type Props = {
	forwards: number
}

const arePropsEqual = (prevProps: Props, nextProps: Props) => {
	return prevProps.forwards === nextProps.forwards
}

export const Forwards = memo(function Forwards({ forwards = 0 }: Props) {
	const theme = useTheme()
	return (
		<Flex align="center">
			<Icons.ArrowRightLeft size={14} strokeWidth={2} color={theme.colors.blue_40} />
			<Text ml={1} size={13} color="blue_40">
				{shortNumber(forwards)}
			</Text>
		</Flex>
	)
}, arePropsEqual)
