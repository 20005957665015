import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { getEmojiDataFromNative } from 'emoji-mart'

import { Text, Flex, HStack } from '@prostpost/uikit'
import { shortNumber } from '@prostpost/utils'

import { TelegramAnimatedEmoji } from 'app/shared/components'

import type { Reactions as PostReactions } from 'app/domains/Post'

type Props = {
	reactions: PostReactions
}

type ReactionProps = {
	emojiCode: string
	count: number
}

const StyledReaction = styled(Flex)`
	overflow: hidden;
	padding: 3px 6px;
	border-radius: 12px;
	border: 1px solid #d9e6ff;
	background: #f3f7ff;
`

const Reaction = ({ emojiCode, count }: ReactionProps) => {
	const [emojiNative, setEmojiNative] = useState<string>(emojiCode)
	useEffect(() => {
		getEmojiDataFromNative(emojiCode)
			.then(({ native }: { native: string }) => setEmojiNative(native || emojiCode))
			.catch(console.error)
	}, [])

	return (
		<StyledReaction align="center" just="center">
			<Text size={13} color="blue_40">{`${emojiNative} ${shortNumber(count)}`}</Text>
		</StyledReaction>
	)
}

const CustomReaction = ({ emojiCode, count }: ReactionProps) => (
	<StyledReaction align="center" just="center">
		<HStack space={1} align="center">
			<TelegramAnimatedEmoji key={emojiCode} emojiId={emojiCode} loopAnimation={false} />
			<Text size={13} color="blue_40">
				{count}
			</Text>
		</HStack>
	</StyledReaction>
)

export const Reactions = observer(function Reactions({ reactions }: Props) {
	getEmojiDataFromNative(Object.keys(reactions)[0]).then(console.log).catch(console.error)
	return Object.entries(reactions)
		.sort((a, b) => b[1] - a[1])
		.map(([emoji, count], index) => {
			if (/^\d+$/.test(emoji)) return <CustomReaction key={emoji} emojiCode={emoji} count={count} />
			return <Reaction key={index} emojiCode={emoji} count={count} />
		})
})
