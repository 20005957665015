import { z } from 'zod'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import * as Icons from 'lucide-react'
import type { LucideIcon } from 'lucide-react'

import { useCurrentMedia } from '@prostpost/css'
import { notReachable } from '@prostpost/utils'
import { Flex, Button } from '@prostpost/uikit'
import { Input } from '@prostpost/uikit-next'

import { useCheckChannelRef } from 'app/domains/Channel/hooks'
import { useAddNewChannel } from 'app/domains/Channel/features/AddChannel/hooks'
import { useNewChannelAnalyticsEvents } from 'app/config/analytics/events/channel'
import type { ChannelNotArchived } from 'app/domains/Channel'

export type Msg = { type: 'on_new_channel_added'; channel: ChannelNotArchived }
type MsgProp = {
	onMsg: (msg: Msg) => void
}

type Props = {
	size?: 'normal' | 'mid'
	submitButton?: {
		label?: string
		icon?: LucideIcon
	}
}

type FormData = {
	channelName: string
}

export const AddChannelForm = ({ size = 'mid', submitButton, onMsg }: Props & MsgProp) => {
	const { t } = useTranslation()
	const SubmitActionIcon = submitButton?.icon || Icons.Plus
	const channelEvents = useNewChannelAnalyticsEvents()

	const isMobile = useCurrentMedia() === 'MOBILE'

	const { checkChannelRef } = useCheckChannelRef()
	const { addNewChannelStatus, addNewChannel } = useAddNewChannel()

	const formSchema: z.ZodSchema<FormData> = z.object({
		channelName: z.string().min(1, t('onboard.channel.requiredError', 'Channel name is required')),
	})

	const {
		watch,
		control,
		setError,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		reValidateMode: 'onSubmit',
		resolver: zodResolver(formSchema),
		defaultValues: {
			channelName: '',
		},
	})

	const channelNameInput = watch('channelName')

	useEffect(() => {
		switch (addNewChannelStatus.type) {
			case 'idle':
			case 'loading':
				break
			case 'error':
				setError('channelName', { message: addNewChannelStatus.message })
				break
			case 'success':
				onMsg({ type: 'on_new_channel_added', channel: addNewChannelStatus.data })
				break
			default:
				notReachable(addNewChannelStatus)
		}
	}, [addNewChannelStatus.type])

	return (
		<Flex gap={3} as="form" w="100%" dir={isMobile ? 'column' : 'row'} maxW={isMobile ? '100%' : '500px'}>
			<Controller
				name="channelName"
				control={control}
				render={({ field }) => (
					<Input
						size={size === 'mid' ? 'xs' : 'md'}
						hasAutocomplete={false}
						iconPosition="head"
						useIcon={Icons.AtSign}
						message={errors.channelName?.message}
						variant={errors.channelName ? 'error' : 'normal'}
						placeholder={t('onboard.channel.inputPlaceholder', 'your-awesome-channel')}
						{...field}
						onChange={value => {
							field.onChange(value)
							clearErrors()
						}}
					/>
				)}
			/>

			<Button.Action
				submit
				size={size}
				variant="primary"
				onClick={e => {
					e?.preventDefault()
					e?.stopPropagation()

					channelEvents.clicks.submitNewChannel(channelNameInput)
					const channelRef = checkChannelRef(channelNameInput)

					switch (channelRef.state) {
						case 'SUCCESS':
							handleSubmit(() => addNewChannel({ channelName: channelRef.name }))(e)
							break
						case 'ERROR':
							switch (channelRef.error) {
								case 'INVALID_LINK':
									channelEvents.errors.invalidChannelName(channelNameInput)
									setError('channelName', {
										message: t(
											'channel.addChannel.error.invalidUrl',
											"Use t.me link or just a channel's name",
										),
									})
									break
								default:
									notReachable(channelRef.error)
							}
							break
						default:
							notReachable(channelRef)
					}
				}}
				state={addNewChannelStatus.type === 'loading' ? 'pending' : 'normal'}
				fullWidth={isMobile}
				icon={(() => {
					switch (size) {
						case 'normal':
							return <SubmitActionIcon size={20} strokeWidth={2.5} />
						case 'mid':
							return <SubmitActionIcon size={18} strokeWidth={3} />
						default:
							return notReachable(size)
					}
				})()}
			>
				{submitButton?.label || t('channel.addNewChannel.actionLabel', 'Add channel')}
			</Button.Action>
		</Flex>
	)
}
