import type { DraftScheduled, ExternalLink } from 'app/domains/Draft'

export type Reactions = Record<string, number>

export type PostHistoryStickerMeta = {
	type: 'sticker'
	setName: string
}

export type PostHistoryPollMeta = {
	type: 'poll'
	question: string
	isAnonymous: boolean
	allowsMultipleAnswers: boolean
	options: string[]
	totalVoterCount: number
	correctOptionId?: number
	explanation?: string
}

export type PostHistoryLocationMeta = {
	type: 'location'
	longitude: number
	latitude: number
}

export type PostHistoryDocumentMeta = {
	type: 'document'
	fileSize: number
	fileName?: string
}

export type PostHistoryAudioMeta = {
	type: 'audio'
	duration: number
	fileName?: string
}

export type PostHistoryVideoMeta = {
	type: 'video'
	duration: number
	fileName?: string
}

export type PostHistoryVideoNoteMeta = {
	type: 'video_note'
	duration: number
}

export type PostHistoryVoiceMeta = {
	type: 'voice'
	duration: number
}

export type PostHistoryTextMeta = {
	type: 'text'
}

export type PostHistoryPhotoMeta = {
	type: 'photo'
}

export type PostHistoryPhotoWithCaptionMeta = {
	type: 'photo_with_caption'
}

export type PostHistoryGalleryMeta = {
	type: 'gallery'
}

export type PostHistoryUnknownMeta = {
	type: 'unknown'
}

export type PostHistoryMeta =
	| PostHistoryAudioMeta
	| PostHistoryDocumentMeta
	| PostHistoryLocationMeta
	| PostHistoryPollMeta
	| PostHistoryStickerMeta
	| PostHistoryVideoMeta
	| PostHistoryVideoNoteMeta
	| PostHistoryVoiceMeta
	| PostHistoryTextMeta
	| PostHistoryPhotoMeta
	| PostHistoryPhotoWithCaptionMeta
	| PostHistoryGalleryMeta
	| PostHistoryUnknownMeta

type PostCommon = {
	tgId: number
	uuid: string
	views: number
	forwards: number
	publishedAt: string
	scheduled: boolean
	silent: boolean

	images: string[]
	tags: string[]
	channelUuid: string

	text?: string

	webPage?: string
	editedAt?: string
	reactions?: Reactions
}

export type PostHistory = PostCommon & {
	type: 'HISTORY'
	createdAt: string
	imagesToLoad: number
	mediaGroupId?: string
	ad: boolean // it will be always "false" because we set it on API zod schema level but we can't set if false here because of type issues in store
	meta?: PostHistoryMeta
	forwardFromId?: string
	forwardFromTitle?: string
}

export type PostInternal = PostCommon & {
	type: 'INTERNAL'
	ad: boolean
	createdAt: string
	updatedAt?: string
	publishAt?: string
	colorTag?: string
	externalLink?: ExternalLink
	unpublishedVersion?: string
	mdText?: string | null
}

export type PostInternalPublishing = Omit<PostInternal, 'tgId' | 'type'> & {
	type: 'INTERNAL_PUBLISHING'
}

export type Post = PostHistory | PostInternal
export type FeedPost = Post | DraftScheduled | PostInternalPublishing

export type PostBackup = (DraftScheduled | Omit<PostInternal, 'tgId' | 'views' | 'forwards' | 'scheduled'>) & {
	userUuid: string
	draftUuid: string
	withGallery: boolean
}

export type PublishingForbiddenReason =
	| 'VALID'
	| 'IMAGES_CANT_BE_FULLY_REMOVED'
	| 'LINK_PREVIEW_CANT_BE_ADDED_TO_CAPTION'
	| 'EXTERNAL_LINK_NOT_POSSIBLE_FOR_GALLERY_WITHOUT_TEXT'

export const unsupportedPostTypes = [
	'poll',
	'audio',
	'video',
	'voice',
	'document',
	'location',
	'sticker',
	'video_note',
	'unknown',
] as const

export const supportedPostTypes = ['text', 'photo', 'photo_with_caption', 'gallery'] as const
export const postTypes = [...unsupportedPostTypes, ...supportedPostTypes] as const

export type PostType = (typeof postTypes)[number]
export type SupportedPostType = (typeof supportedPostTypes)[number]
export type UnsupportedPostType = (typeof unsupportedPostTypes)[number]
