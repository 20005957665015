import moment from 'moment';
import { jwtDecode as jwtDecode$1 } from 'jwt-decode';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

var serial = function serial(funcs) {
    return funcs.reduce(function (promise, func) {
        return promise.then(function (result) {
            return func(result).then(Array.prototype.concat.bind(result));
        });
    }, Promise.resolve({}));
};
var fileExists = function fileExists(url, callback) {
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    var exists = http.status !== 404;
    callback === null || callback === void 0 ? void 0 : callback(exists);
    return exists;
};

var canUseDOM = function canUseDOM(callback) {
    var hasDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
    if (callback && hasDOM) callback(hasDOM);
    return hasDOM;
};
var getDisplayName = function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};
var inIframe = function inIframe() {
    try {
        return window.self !== window.top;
    } catch (e) {
        console.error(e);
        return true;
    }
};

var noop = function noop() {};
var constant = function constant(r) {
    return function () {
        return r;
    };
};

var sample = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
var uuid = function uuid() {
    var N = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 4;
    return Array(N)
        .join()
        .split(',')
        .map(function () {
            return sample.charAt(Math.floor(Math.random() * sample.length));
        })
        .join('');
};

var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;
var localhostDomainRE = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/;
var nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}$/;
var isUrl = function isUrl(str) {
    if (typeof str !== 'string') {
        return false;
    }
    var match = protocolAndDomainRE.exec(str);
    if (!match) {
        return false;
    }
    var everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
        return false;
    }
    return localhostDomainRE.test(everythingAfterProtocol) || nonLocalhostDomainRE.test(everythingAfterProtocol);
};
var shortNumber = function shortNumber() {
    var num = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
    var absNum = Math.abs(num);
    if (!num) {
        return '0';
    }
    if (absNum < 1000) {
        return ''.concat(num);
    }
    return absNum < 1000000 ? ''.concat((num / 1000).toFixed(1), 'k') : ''.concat((num / 1000000).toFixed(1), 'm');
};
var numberWithSpaces = function numberWithSpaces(num) {
    return !num ? '0' : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};
var appendS = function appendS(unit, num) {
    return num !== 1 ? ''.concat(unit, 's') : unit;
};
var truncate = function truncate(str) {
    var length = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 99999;
    return str.length <= length ? str : ''.concat(str.substr(0, length), '...');
};
var escapeHTML = function escapeHTML(str) {
    return str ? str.replace(/<(?:.|\n)*?>/gm, '') || '' : '';
};
var toFixed = function toFixed(value, _ref) {
    var _ref$precision = _ref.precision,
        precision = _ref$precision === void 0 ? -1 : _ref$precision;
    if (value === null) {
        return Number(0).toFixed(precision);
    }
    if (typeof value === 'number') {
        value = value.toString();
    }
    var splited = value.split('.');
    if (splited.length === 2 && splited[1].length <= precision) {
        return parseFloat(value).toFixed(precision);
    }
    return value.slice(0, value.indexOf('.') + (precision > 0 ? precision + 1 : 0));
};
var capitalize = function capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
};

function _arrayLikeToArray(r, a) {
    (null == a || a > r.length) && (a = r.length);
    for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
    return n;
}
function _arrayWithHoles(r) {
    if (Array.isArray(r)) return r;
}
function _createForOfIteratorHelper(r, e) {
    var t = ('undefined' != typeof Symbol && r[Symbol.iterator]) || r['@@iterator'];
    if (!t) {
        if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e) {
            t && (r = t);
            var n = 0,
                F = function () {};
            return {
                s: F,
                n: function () {
                    return n >= r.length
                        ? {
                              done: !0,
                          }
                        : {
                              done: !1,
                              value: r[n++],
                          };
                },
                e: function (r) {
                    throw r;
                },
                f: F,
            };
        }
        throw new TypeError(
            'Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
        );
    }
    var o,
        a = !0,
        u = !1;
    return {
        s: function () {
            t = t.call(r);
        },
        n: function () {
            var r = t.next();
            return (a = r.done), r;
        },
        e: function (r) {
            (u = !0), (o = r);
        },
        f: function () {
            try {
                a || null == t.return || t.return();
            } finally {
                if (u) throw o;
            }
        },
    };
}
function _defineProperty(e, r, t) {
    return (
        (r = _toPropertyKey(r)) in e
            ? Object.defineProperty(e, r, {
                  value: t,
                  enumerable: !0,
                  configurable: !0,
                  writable: !0,
              })
            : (e[r] = t),
        e
    );
}
function _iterableToArrayLimit(r, l) {
    var t = null == r ? null : ('undefined' != typeof Symbol && r[Symbol.iterator]) || r['@@iterator'];
    if (null != t) {
        var e,
            n,
            i,
            u,
            a = [],
            f = !0,
            o = !1;
        try {
            if (((i = (t = t.call(r)).next), 0 === l));
            else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
        } catch (r) {
            (o = !0), (n = r);
        } finally {
            try {
                if (!f && null != t.return && ((u = t.return()), Object(u) !== u)) return;
            } finally {
                if (o) throw n;
            }
        }
        return a;
    }
}
function _nonIterableRest() {
    throw new TypeError(
        'Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.',
    );
}
function ownKeys(e, r) {
    var t = Object.keys(e);
    if (Object.getOwnPropertySymbols) {
        var o = Object.getOwnPropertySymbols(e);
        r &&
            (o = o.filter(function (r) {
                return Object.getOwnPropertyDescriptor(e, r).enumerable;
            })),
            t.push.apply(t, o);
    }
    return t;
}
function _objectSpread2(e) {
    for (var r = 1; r < arguments.length; r++) {
        var t = null != arguments[r] ? arguments[r] : {};
        r % 2
            ? ownKeys(Object(t), !0).forEach(function (r) {
                  _defineProperty(e, r, t[r]);
              })
            : Object.getOwnPropertyDescriptors
              ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t))
              : ownKeys(Object(t)).forEach(function (r) {
                    Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
                });
    }
    return e;
}
function _objectWithoutProperties(e, t) {
    if (null == e) return {};
    var o,
        r,
        i = _objectWithoutPropertiesLoose(e, t);
    if (Object.getOwnPropertySymbols) {
        var s = Object.getOwnPropertySymbols(e);
        for (r = 0; r < s.length; r++)
            (o = s[r]), t.includes(o) || ({}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]));
    }
    return i;
}
function _objectWithoutPropertiesLoose(r, e) {
    if (null == r) return {};
    var t = {};
    for (var n in r)
        if ({}.hasOwnProperty.call(r, n)) {
            if (e.includes(n)) continue;
            t[n] = r[n];
        }
    return t;
}
function _slicedToArray(r, e) {
    return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _toPrimitive(t, r) {
    if ('object' != typeof t || !t) return t;
    var e = t[Symbol.toPrimitive];
    if (void 0 !== e) {
        var i = e.call(t, r || 'default');
        if ('object' != typeof i) return i;
        throw new TypeError('@@toPrimitive must return a primitive value.');
    }
    return ('string' === r ? String : Number)(t);
}
function _toPropertyKey(t) {
    var i = _toPrimitive(t, 'string');
    return 'symbol' == typeof i ? i : i + '';
}
function _unsupportedIterableToArray(r, a) {
    if (r) {
        if ('string' == typeof r) return _arrayLikeToArray(r, a);
        var t = {}.toString.call(r).slice(8, -1);
        return (
            'Object' === t && r.constructor && (t = r.constructor.name),
            'Map' === t || 'Set' === t
                ? Array.from(r)
                : 'Arguments' === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t)
                  ? _arrayLikeToArray(r, a)
                  : void 0
        );
    }
}

var notReachable = function notReachable(_) {
    throw new Error('Should not be reached '.concat(_));
};

var SERVER_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
var SERVER_DATE_FORMAT = 'YYYY-MM-DD';
var CLIENT_DATETIME_FORMAT = 'DD MMM [at] HH:mm';
var CLIENT_DATE_NO_YEAR_FORMAT = 'DD MMM';
var CLIENT_DATE_FORMAT = 'DD MMM YYYY';
var WITH_AM_PM = ' [at] hh:mm a';
var WITH_24 = ' [at] HH:mm';
var SESSION_STORAGE_DATETIME_OFFSET = 'datetime.offset';
var SESSION_STORAGE_DATETIME_USE_24 = 'datetime.use24';
var generateFormatStr = function generateFormatStr(value) {
    var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref$timeFormat = _ref.timeFormat,
        timeFormat = _ref$timeFormat === void 0 ? undefined : _ref$timeFormat,
        _ref$time = _ref.time,
        time = _ref$time === void 0 ? 'keep' : _ref$time,
        _ref$year = _ref.year,
        year = _ref$year === void 0 ? 'auto' : _ref$year;
    var formatStr = '';
    var use24 =
        timeFormat === undefined && canUseDOM()
            ? sessionStorage.getItem(SESSION_STORAGE_DATETIME_USE_24)
                ? !!parseInt(sessionStorage.getItem(SESSION_STORAGE_DATETIME_USE_24) || '')
                : true
            : timeFormat === '24h';
    if (year === 'auto') {
        formatStr =
            new Date().getFullYear() === parseInt(value.format('YYYY'), 10)
                ? CLIENT_DATE_NO_YEAR_FORMAT
                : CLIENT_DATE_FORMAT;
    } else if (year === 'no') {
        formatStr = CLIENT_DATE_NO_YEAR_FORMAT;
    } else if (year === 'yes') {
        formatStr = CLIENT_DATE_FORMAT;
    }
    if (time !== 'trim' && !use24) {
        formatStr += WITH_AM_PM;
    } else if (time !== 'trim') {
        formatStr += WITH_24;
    }
    return formatStr;
};
var formatMoment = function formatMoment(value) {
    var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref2$timeFormat = _ref2.timeFormat,
        timeFormat = _ref2$timeFormat === void 0 ? undefined : _ref2$timeFormat,
        _ref2$time = _ref2.time,
        time = _ref2$time === void 0 ? 'keep' : _ref2$time,
        _ref2$year = _ref2.year,
        year = _ref2$year === void 0 ? 'auto' : _ref2$year;
    var formatStr = generateFormatStr(value, {
        timeFormat: timeFormat,
        time: time,
        year: year,
    });
    return moment(value).format(formatStr);
};
function datetime(value) {
    var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    var _ref3 = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {},
        _ref3$to = _ref3.to,
        to = _ref3$to === void 0 ? 'local' : _ref3$to,
        _ref3$inputFormat = _ref3.inputFormat,
        inputFormat = _ref3$inputFormat === void 0 ? undefined : _ref3$inputFormat,
        _ref3$offset = _ref3.offset,
        offset = _ref3$offset === void 0 ? undefined : _ref3$offset,
        _ref3$timeFormat = _ref3.timeFormat,
        timeFormat = _ref3$timeFormat === void 0 ? '12h' : _ref3$timeFormat,
        _ref3$time = _ref3.time,
        time = _ref3$time === void 0 ? 'keep' : _ref3$time,
        _ref3$year = _ref3.year,
        year = _ref3$year === void 0 ? 'auto' : _ref3$year;
    var _offset =
        offset === undefined && canUseDOM()
            ? parseInt(sessionStorage.getItem(SESSION_STORAGE_DATETIME_OFFSET) || '') || 0
            : offset;
    var _inputFormat = inputFormat || to === 'local' ? SERVER_DATETIME_FORMAT : CLIENT_DATETIME_FORMAT;
    var mm = typeof value === 'string' ? moment(value, _inputFormat) : moment(value);
    if (!value || !mm || !mm.isValid()) {
        return null;
    }
    var m = to === 'local' ? mm.add(_offset, 'minutes') : mm.subtract(_offset, 'minutes');
    if (!format) {
        return m;
    } else {
        if (to === 'utc') {
            switch (time) {
                case 'keep':
                    return m.format(SERVER_DATETIME_FORMAT);
                case 'trim':
                    return m.format(SERVER_DATE_FORMAT);
                case 'dayStart':
                    m.set({
                        hours: 0,
                        minutes: 0,
                        second: 0,
                    });
                    return m.format(SERVER_DATETIME_FORMAT);
                case 'dayEnd':
                    m.set({
                        hours: 23,
                        minutes: 59,
                        second: 59,
                    });
                    return m.format(SERVER_DATETIME_FORMAT);
                default:
                    return notReachable(time);
            }
        } else {
            return formatMoment(m, {
                timeFormat: timeFormat,
                time: time,
                year: year,
            });
        }
    }
}
function getNow(format) {
    var _ref4 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
        _ref4$to = _ref4.to,
        to = _ref4$to === void 0 ? 'local' : _ref4$to,
        _ref4$timeFormat = _ref4.timeFormat,
        timeFormat = _ref4$timeFormat === void 0 ? undefined : _ref4$timeFormat,
        _ref4$time = _ref4.time,
        time = _ref4$time === void 0 ? 'keep' : _ref4$time,
        _ref4$year = _ref4.year,
        year = _ref4$year === void 0 ? 'auto' : _ref4$year;
    var _offset = (canUseDOM() && parseInt(sessionStorage.getItem(SESSION_STORAGE_DATETIME_OFFSET) || '')) || 0;
    var m = moment.utc(new Date()).add(_offset, 'minutes');
    if (!format) {
        if (to === 'utc') return m;
        return moment(m.format(SERVER_DATETIME_FORMAT));
    } else if (to === 'utc') {
        if (time) {
            return moment.utc(new Date()).format(SERVER_DATETIME_FORMAT);
        } else {
            return moment.utc(new Date()).format(SERVER_DATE_FORMAT);
        }
    } else {
        return formatMoment(m, {
            timeFormat: timeFormat,
            time: time,
            year: year,
        });
    }
}
var toMoment = function toMoment(value) {
    var possibleFormats = [
        CLIENT_DATETIME_FORMAT,
        ''.concat(CLIENT_DATE_NO_YEAR_FORMAT).concat(WITH_24),
        ''.concat(CLIENT_DATE_NO_YEAR_FORMAT).concat(WITH_AM_PM),
        ''.concat(CLIENT_DATE_FORMAT).concat(WITH_24),
        ''.concat(CLIENT_DATE_FORMAT).concat(WITH_AM_PM),
        CLIENT_DATE_NO_YEAR_FORMAT,
        CLIENT_DATE_FORMAT,
        SERVER_DATETIME_FORMAT,
        SERVER_DATE_FORMAT,
    ];
    var result = null;
    var _iterator = _createForOfIteratorHelper(possibleFormats.entries()),
        _step;
    try {
        for (_iterator.s(); !(_step = _iterator.n()).done; ) {
            var _step$value = _slicedToArray(_step.value, 2),
                index = _step$value[0],
                format = _step$value[1];
            var m = moment(value, format);
            if (m.isValid()) {
                result = m;
                if ([1, 2, 5].includes(index)) {
                    result.set('year', getNow(false).year());
                }
                break;
            }
        }
    } catch (err) {
        _iterator.e(err);
    } finally {
        _iterator.f();
    }
    return result;
};
var dateToPeriod = function dateToPeriod(date) {
    var _datetime;
    var noDateText = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'No date';
    var to = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'local';
    return !date || !toMoment(date)
        ? noDateText
        : (_datetime = datetime(date, false, {
                to: to,
                time: 'keep',
            })) === null || _datetime === void 0
          ? void 0
          : _datetime.fromNow();
};
var displayDaysRange = function displayDaysRange(range) {
    if (!range) {
        return 'Select dates';
    }
    var _from = datetime(range[0], true, {
        time: 'trim',
    });
    if (range[0] && !range[1]) {
        return _from;
    }
    return ''.concat(_from, ' - ').concat(
        datetime(range[1], true, {
            time: 'trim',
        }),
    );
};
var displayAxisDate = function displayAxisDate(v) {
    return datetime(moment(v, SERVER_DATE_FORMAT), false).format('ddd');
};

var toClipboard = function toClipboard(input, value) {
    var _input = input || document.createElement('input');
    if (!input && value) {
        _input.setAttribute('style', 'position: absolute; left: -1000px; top: -1000px; opacity: 0;');
        _input.value = value;
        document.body.appendChild(_input);
        setTimeout(function () {
            return document.body.removeChild(_input);
        });
    }
    _input.select();
    _input.setSelectionRange(0, 99999);
    document.execCommand('copy');
    return _input.value;
};

var jwtDecode = function jwtDecode(token) {
    return jwtDecode$1(token);
};
var jwtExpired = function jwtExpired(token) {
    var decoded = null;
    try {
        decoded = jwtDecode(token);
    } catch (e) {
        return true;
    }
    var diff = decoded.exp ? Math.floor((decoded.exp - parseInt(moment.utc().format('X').toString(), 10)) / 60) : -1;
    return diff < 0;
};

var isMobile = function isMobile() {
    var isMobile = false;
    if (canUseDOM()) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
                navigator.userAgent,
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                navigator.userAgent.substr(0, 4),
            )
        ) {
            isMobile = true;
        }
        return isMobile;
    }
    return false;
};

var _plainObjectStringValues = function plainObjectStringValues(rs) {
    var r = [];
    for (var _i = 0, _Object$values = Object.values(rs); _i < _Object$values.length; _i++) {
        var value = _Object$values[_i];
        if (typeof value === 'string' || value instanceof String) {
            value[0] === '/' ? r.push(value) : r.push('/'.concat(value));
        } else {
            r.push.apply(r, _plainObjectStringValues(value));
        }
    }
    return r;
};

var _excluded = ['response'];
var authRefresher = {
    proxy: axios.create({
        baseURL: '/api/auth',
    }),
    url: '/refresh',
    exclude: ['/login', '/logout'],
    retry: true,
    onRefreshFailed: function onRefreshFailed() {
        return localStorage.removeItem('id_token');
    },
    onRefreshSucceed: function onRefreshSucceed() {
        return null;
    },
};
var defaultInterceptor = function defaultInterceptor(c) {
    return c;
};
var createAxiosInstance = function createAxiosInstance() {
    var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var options =
        arguments.length > 1 && arguments[1] !== undefined
            ? arguments[1]
            : {
                  loginFormRoute: '/auth/in',
              };
    var auth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : authRefresher;
    var interceptor = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : defaultInterceptor;
    var instance = axios.create(config);
    auth.proxy.interceptors.response.use(
        function (response) {
            var _response$data$meta;
            response.config.baseURL = '';
            if (
                (_response$data$meta = response.data.meta) !== null &&
                _response$data$meta !== void 0 &&
                _response$data$meta.accessCsrfToken
            ) {
                localStorage.setItem('accessCsrfToken', response.data.meta.accessCsrfToken);
            } else {
                console.error('[AUTH]: Access CSRF token not found in refresh response');
            }
            localStorage.setItem('id_token', Cookies.get('id_token') || '');
            Cookies.remove('id_token');
            auth.onRefreshSucceed();
            return response;
        },
        function (err) {
            var error = err.response;
            auth.onRefreshFailed();
            var idToken = localStorage.getItem('id_token');
            localStorage.removeItem('id_token');
            localStorage.removeItem('accessCsrfToken');
            localStorage.removeItem('refreshCsrfToken');
            if (
                canUseDOM() &&
                location &&
                options.loginFormRoute &&
                location.pathname !== options.loginFormRoute &&
                ((error !== null && error !== void 0 && error.status) || err.config) &&
                (error === null || error === void 0 ? void 0 : error.config.url) === auth.url &&
                idToken &&
                !jwtExpired(idToken)
            ) {
                window.location.assign(options.loginFormRoute);
            } else {
                window.location.assign('/');
            }
        },
    );
    var authTokenRequest;
    function resetAuthTokenRequest() {
        authTokenRequest = null;
        return null;
    }
    function getAuthToken() {
        var nowTimestamp = Math.round(new Date().getTime() / 1000);
        var noRefresh = localStorage.getItem('no_refresh');
        if (canUseDOM() && noRefresh) {
            var noRefreshExpired = nowTimestamp - (noRefresh ? parseInt(noRefresh, 10) : nowTimestamp) > 5;
            if (noRefreshExpired) {
                localStorage.removeItem('no_refresh');
            }
        }
        if (canUseDOM() && authTokenRequest == null && !localStorage.getItem('no_refresh')) {
            localStorage.setItem('no_refresh', ''.concat(nowTimestamp));
            setTimeout(function () {
                return localStorage.removeItem('no_refresh');
            }, 5000);
            authTokenRequest = auth.proxy.post(auth.url, null, {
                headers: _objectSpread2(
                    _objectSpread2({}, auth.proxy.defaults.headers),
                    {},
                    {
                        'X-CSRF-TOKEN': localStorage.getItem('refreshCsrfToken') || '',
                    },
                ),
            });
            authTokenRequest.then(resetAuthTokenRequest, resetAuthTokenRequest).catch(console.error);
        } else if (canUseDOM() && localStorage.getItem('no_refresh')) {
            var delayedPromise = new Promise(function (resolve) {
                return setTimeout(resolve, 1000);
            });
            return delayedPromise.then(resetAuthTokenRequest, resetAuthTokenRequest);
        }
        return authTokenRequest;
    }
    instance.interceptors.request.use(function (config) {
        var c = config;
        if (config.method !== 'get') {
            c.headers = config.headers || {};
            c.headers['X-CSRF-TOKEN'] = localStorage.getItem('accessCsrfToken') || '';
        }
        return interceptor(c);
    });
    instance.interceptors.response.use(
        function (response) {
            return response;
        },
        function (_ref) {
            var error = _ref.response,
                rest = _objectWithoutProperties(_ref, _excluded);
            if (
                axios.isCancel(
                    _objectSpread2(
                        {
                            response: error,
                        },
                        rest,
                    ),
                )
            ) {
                return Promise.reject(
                    _objectSpread2(
                        {
                            response: error,
                        },
                        rest,
                    ),
                );
            }
            if (error && (error.status || rest.config) && error.status !== 401) {
                return Promise.reject(
                    _objectSpread2(
                        {
                            response: error,
                        },
                        rest,
                    ),
                );
            }
            if (
                error &&
                error.status === 401 &&
                error.config.url !== auth.url &&
                !auth.exclude.includes(error.config.url || '') &&
                !error.config.__isRetryRequest
            ) {
                return (getAuthToken() || Promise.resolve()).then(function () {
                    if (auth.retry) {
                        error.config.__isRetryRequest = true;
                        error.config.headers = _objectSpread2(
                            _objectSpread2({}, error.config.headers),
                            {},
                            {
                                'X-CSRF-TOKEN': localStorage.getItem('accessCsrfToken') || '',
                            },
                        );
                        return axios.request(error.config);
                    }
                    return null;
                });
            }
            return Promise.reject(error);
        },
    );
    return instance;
};

var useDebounce = function useDebounce(value, delay) {
    var _useState = useState(value),
        _useState2 = _slicedToArray(_useState, 2),
        debouncedValue = _useState2[0],
        setDebouncedValue = _useState2[1];
    useEffect(
        function () {
            var handler = setTimeout(function () {
                setDebouncedValue(value);
            }, delay);
            return function () {
                clearTimeout(handler);
            };
        },
        [value, delay],
    );
    return debouncedValue;
};

var useElementSize = function useElementSize(target) {
    var _useState = useState(),
        _useState2 = _slicedToArray(_useState, 2),
        size = _useState2[0],
        setSize = _useState2[1];
    useLayoutEffect(
        function () {
            var _target$current;
            setSize(
                (_target$current = target.current) === null || _target$current === void 0
                    ? void 0
                    : _target$current.getBoundingClientRect(),
            );
        },
        [target],
    );
    useResizeObserver(target, function (entry) {
        return setSize(entry.contentRect);
    });
    return (
        size || {
            width: undefined,
            height: undefined,
        }
    );
};

var useOpenWindow = function useOpenWindow() {
    return function (url) {
        return canUseDOM() && window.open(url, '_blank');
    };
};

var usePreviousProp = function usePreviousProp(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
};

var useWindowSize = function useWindowSize() {
    var _useState = useState([0, 0]),
        _useState2 = _slicedToArray(_useState, 2),
        size = _useState2[0],
        setSize = _useState2[1];
    useEffect(function () {
        var updateSize = function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', updateSize);
        updateSize();
        return function () {
            return window.removeEventListener('resize', updateSize);
        };
    }, []);
    return {
        width: size[0],
        height: size[1],
    };
};

var useNetworkStatus = function useNetworkStatus() {
    var _useState = useState(true),
        _useState2 = _slicedToArray(_useState, 2),
        isOnline = _useState2[0],
        setOnline = _useState2[1];
    var updateNetworkStatus = function updateNetworkStatus() {
        setOnline(navigator.onLine);
    };
    useEffect(function () {
        updateNetworkStatus();
    }, []);
    useEffect(
        function () {
            window.addEventListener('load', updateNetworkStatus);
            window.addEventListener('online', updateNetworkStatus);
            window.addEventListener('offline', updateNetworkStatus);
            return function () {
                window.removeEventListener('load', updateNetworkStatus);
                window.removeEventListener('online', updateNetworkStatus);
                window.removeEventListener('offline', updateNetworkStatus);
            };
        },
        [navigator.onLine],
    );
    return {
        isOnline: isOnline,
    };
};

export {
    SESSION_STORAGE_DATETIME_OFFSET,
    SESSION_STORAGE_DATETIME_USE_24,
    appendS,
    authRefresher,
    canUseDOM,
    capitalize,
    constant,
    createAxiosInstance,
    dateToPeriod,
    datetime,
    displayAxisDate,
    displayDaysRange,
    escapeHTML,
    fileExists,
    formatMoment,
    getDisplayName,
    getNow,
    inIframe,
    isMobile,
    isUrl,
    jwtDecode,
    jwtExpired,
    noop,
    notReachable,
    numberWithSpaces,
    _plainObjectStringValues as plainObjectStringValues,
    serial,
    shortNumber,
    toClipboard,
    toFixed,
    toMoment,
    truncate,
    useDebounce,
    useElementSize,
    useNetworkStatus,
    useOpenWindow,
    usePreviousProp,
    useWindowSize,
    uuid,
};
