import * as React from "react";
const SvgDeinline = (props) => /* @__PURE__ */ React.createElement("svg", { xmlSpace: "preserve", xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 82.65 82.65", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M41.33 0a41.33 41.33 0 0 0-39.2 28.22h78.39A41.33 41.33 0 0 0 41.32 0z", fill: "#1A1A1A" }), /* @__PURE__ */ React.createElement("linearGradient", { gradientTransform: "rotate(90 -109.18 -87.53)", gradientUnits: "userSpaceOnUse", id: "a", x1: 32.78, x2: 61, y1: -238.04, y2: -238.04 }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#fcd447"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.62, style: {
  stopColor: "#fcd447"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#ffde78"
} })), /* @__PURE__ */ React.createElement("path", { d: "M41.33 82.65a41.33 41.33 0 0 0 39.19-28.22H2.13a41.33 41.33 0 0 0 39.2 28.22z", fill: "url(#a)" }), /* @__PURE__ */ React.createElement("linearGradient", { gradientUnits: "userSpaceOnUse", id: "b", x1: 0, x2: 82.65, y1: 41.33, y2: 41.33 }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#ed2224"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#e61e25"
} })), /* @__PURE__ */ React.createElement("path", { d: "M82.65 41.33c0-4.58-.75-8.99-2.13-13.1H2.13a41.28 41.28 0 0 0 0 26.2h78.39a41.28 41.28 0 0 0 2.13-13.1z", fill: "url(#b)" }));
export default SvgDeinline;
