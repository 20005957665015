/* eslint-disable sonarjs/no-nested-conditional */
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ThemeProvider } from '@emotion/react'
import { QueryClientProvider } from 'react-query'
import { HelmetProvider } from 'react-helmet-async'
import type { i18n as I18n } from 'i18next'

import { theme } from '@prostpost/uikit'
import { themeLight, ThemeProvider as ThemeProviderNext } from '@prostpost/uikit-next'

import { config } from 'app/config'

import { ErrorBoundary } from 'app/domains/Error/components'
import { CookieBanner } from 'app/domains/User/features/CookieBanner'
import { useIsUserSignedIn } from 'app/domains/User/hooks'

import { routesUrls } from 'app/routes/urls'

import { Loadings } from './routes/Loadings'
import { RoutesDataManager, AuthRoutesDataManager } from './routes'
import { WithUserChecker } from './routes/Wrappers'

const helmetContext = {}

export const App = ({ i18n = i18next }: { i18n?: I18n }) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const isUserSignedIn = useIsUserSignedIn()
	const isLoadingRoute = isUserSignedIn === null

	return (
		<QueryClientProvider client={config.query.client}>
			<ThemeProvider theme={theme}>
				<ThemeProviderNext theme={themeLight}>
					<HelmetProvider context={helmetContext}>
						<ErrorBoundary
							actionTitle={t('errorBoundary.poster.actionInbox', 'Go to Inbox')}
							onClickAction={() => navigate(routesUrls.inbox)}
						>
							<CookieBanner
								onClickLearnMore={() => navigate(routesUrls.policy.pp)}
								onAccept={() => console.log('TODO: Track event')}
							/>
							{isLoadingRoute ? (
								<Loadings.AlmostThere />
							) : isUserSignedIn ? (
								<WithUserChecker>
									<RoutesDataManager i18n={i18n} />
								</WithUserChecker>
							) : (
								<AuthRoutesDataManager />
							)}
						</ErrorBoundary>
					</HelmetProvider>
				</ThemeProviderNext>
			</ThemeProvider>
		</QueryClientProvider>
	)
}
