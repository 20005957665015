import { useEffect, useState } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'

import { canUseDOM } from '@prostpost/utils'

import { useCannySSO as useCannySSOApi } from '../api'

export const getCannyRedirectUrl = (ssoToken: string, redirect: string, companyID: string) => {
	if (redirect.indexOf('https://') !== 0 || !companyID) return null
	return `https://canny.io/api/redirects/sso?companyID=${companyID}&ssoToken=${ssoToken}&redirect=${redirect}`
}

export const useCannySSO = (signInUrl: string, redirectToOnError: string): boolean => {
	const location = useLocation()
	const [searchParams] = useSearchParams()
	const [isCannyChecked, setIsCannyChecked] = useState(false)

	const { authCannyMutation } = useCannySSOApi()

	const cannyRedirectUrl = searchParams.get('redirect')
	const cannyCompanyID = searchParams.get('companyID')

	useEffect(() => {
		if (!canUseDOM() || location.pathname !== signInUrl || !cannyCompanyID || !cannyRedirectUrl) {
			setIsCannyChecked(true)
			return
		}

		authCannyMutation
			.mutateAsync()
			.then(cannySSOToken => {
				const redirectURL = getCannyRedirectUrl(cannySSOToken, cannyRedirectUrl, cannyCompanyID)
				if (redirectURL) window.location.assign(redirectURL)
				else window.location.assign(redirectToOnError)
				setIsCannyChecked(true)
			})
			.catch(e => {
				console.error(e)
				window.location.assign(redirectToOnError)
				setIsCannyChecked(true)
			})
	}, [cannyRedirectUrl, cannyCompanyID])

	return isCannyChecked
}
