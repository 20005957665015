import { Ava } from '@prostpost/uikit'

export const useLanguages = () => [
	{
		value: 'en',
		label: 'English',
		prefix: <Ava alt="en" size="tiny" src="https://hatscripts.github.io/circle-flags/flags/gb.svg" />,
	},
	// {
	// 	value: 'ru',
	// 	label: 'Russian',
	// 	prefix: <Ava alt="fr" size="tiny" src="https://hatscripts.github.io/circle-flags/flags/fr.svg" />,
	// },
]
