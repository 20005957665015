import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'

import { useCopyDraft as useCopyDraftApi } from 'app/domains/Draft/api'
import type { DraftNotScheduled } from 'app/domains/Draft'

type Args = {
	uuid: string
	onError?: () => void
	onSuccess?: (draft: DraftNotScheduled) => void
}

export const useCopyDraft = ({ uuid, onError, onSuccess }: Args) => {
	const { t } = useTranslation()

	const copyError = useToast({
		type: 'error',
		text: t('post.copyDraft.error', 'Unable to copy post'),
	})

	const copySuccess = useToast({
		type: 'success',
		text: t('post.copyDraft.success', 'Draft created from post'),
	})

	// we use callbacks here because ContextMenu is unmounted after click
	// and useEffect with request status checking won't work
	const { copyDraftMutation } = useCopyDraftApi(uuid, {
		onSuccess: data => {
			copySuccess.show()
			onSuccess?.(data)
		},
		onError: () => {
			copyError.show()
			onError?.()
		},
	})

	return { copyDraft: copyDraftMutation.mutate, isCreatingDraftCopy: copyDraftMutation.isLoading }
}
