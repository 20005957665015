import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import * as Icons from 'lucide-react'
import type { ComponentProps } from 'react'

import { VStack, Modal } from '@prostpost/uikit'
import { Typography } from '@prostpost/uikit-next'
import { notReachable } from '@prostpost/utils'

import { ReactModal } from 'app/modals'
import { FeedbackForm } from 'app/domains/Support/features/FeedbackForm'

type FeedbackFormProps = ComponentProps<typeof FeedbackForm>

type Props = {
	isOpen: boolean
	onClose: () => void
	onSubmit?: () => void
} & Pick<FeedbackFormProps, 'defaultCategory' | 'categories'>

export const UserFeedback = ({ isOpen, categories, defaultCategory, onClose, onSubmit }: Props) => {
	const theme = useTheme()
	const { t } = useTranslation()

	return (
		<ReactModal
			isOpen={isOpen}
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			onRequestClose={e => {
				e.stopPropagation()
				e.preventDefault()
				onClose()
			}}
		>
			<Modal variant="content" mobileScrollOffset={94}>
				<Modal.Content>
					<VStack space={5}>
						<Typography variant="heading" color="common_black" size={3}>
							{t('content:modals.trialBlock.feedbackTitle', 'Contact us')}
						</Typography>
						<FeedbackForm
							isUserAuthorized
							defaultCategory={defaultCategory}
							categories={categories}
							secondaryAction={{
								icon: <Icons.ArrowLeft size={16} strokeWidth={2.5} color={theme.colors.black_80} />,
								label: t('content:modals.feedback.cancelLabel', 'Cancel'),
								onClick: onClose,
							}}
							onMsg={msg => {
								switch (msg.type) {
									case 'on_submit_feedback':
										onSubmit?.()
										onClose()
										break
									default:
										notReachable(msg.type)
								}
							}}
						/>
					</VStack>
				</Modal.Content>
			</Modal>
		</ReactModal>
	)
}
