import { notReachable } from '@prostpost/utils'

import { AddChannelModal } from './Modal'
import { AddChannelPlain } from './Plain'

import type { Interface as InterfacePlain, Msg as MsgPlain } from './Plain'
import type { Interface as InterfaceModal, Msg as MsgModal } from './Modal'

type Props =
	| ({ variant: 'plain' } & InterfacePlain & { onMsg: (msg: MsgPlain) => void })
	| ({ variant: 'modal' } & InterfaceModal & { onMsg: (msg: MsgModal) => void })

export const AddChannelVariants = (props: Props) => {
	switch (props.variant) {
		case 'plain':
			return <AddChannelPlain {...props} />
		case 'modal':
			return <AddChannelModal {...props} />
		default:
			return notReachable(props)
	}
}
