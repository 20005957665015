import data from '@emoji-mart/data'
import { init } from 'emoji-mart'
import { useEffect } from 'react'
import type { Scrollbar } from 'react-scrollbars-custom'

import { notReachable } from '@prostpost/utils'
import { useCurrentMedia } from '@prostpost/css'

import { useChannelsStore } from 'app/domains/Channel/store'
import { useChannelNameFromUrl } from 'app/domains/Channel/hooks'

import { FloatingAside, StaticAside, Banners } from './layout'

import '../../../resources/styles/hide-widgets.less'

type Props = {
	isOpen: boolean
	isOpenFloating: boolean
	onToggleFloating: (action: 'show' | 'hide' | 'toggle') => void
} & (
	| {
			withScroller: true
			children: (args: { scroller: Scrollbar }) => React.ReactNode | React.ReactNode[] | null
	  }
	| {
			withScroller: false
			children?: React.ReactNode | React.ReactNode[] | null
	  }
)

export const WithAside = ({ isOpen, isOpenFloating, onToggleFloating, ...props }: Props) => {
	const MEDIA = useCurrentMedia()

	const channels = useChannelsStore()
	const currentChannelName = useChannelNameFromUrl()
	const currentChannel = currentChannelName && channels.getActiveChannelByName(currentChannelName)

	// load emoji data base for Feed
	useEffect(() => {
		init({ data }).catch(console.error)
	}, [])

	if (!MEDIA) return null

	switch (MEDIA) {
		case 'MOBILE':
		case 'TABLET':
			return currentChannel ? (
				props.withScroller ? (
					<FloatingAside
						variant="CHANNEL"
						withScroller
						channel={currentChannel}
						isVisible={isOpenFloating}
						onToggle={onToggleFloating}
					>
						{scroller => (
							<>
								{props.children(scroller)}
								<Banners />
							</>
						)}
					</FloatingAside>
				) : (
					<FloatingAside
						variant="CHANNEL"
						withScroller={false}
						channel={currentChannel}
						isVisible={isOpenFloating}
						onToggle={onToggleFloating}
					>
						{props.children}
						<Banners />
					</FloatingAside>
				)
			) : props.withScroller ? (
				<FloatingAside withScroller variant="NO_CHANNEL" isVisible={isOpenFloating} onToggle={onToggleFloating}>
					{scroller => (
						<>
							{props.children(scroller)}
							<Banners />
						</>
					)}
				</FloatingAside>
			) : (
				<FloatingAside
					withScroller={false}
					variant="NO_CHANNEL"
					isVisible={isOpenFloating}
					onToggle={onToggleFloating}
				>
					{props.children}
					<Banners />
				</FloatingAside>
			)

		case 'DESKTOP_WIDE':
		case 'DESKTOP_NARROW':
			return props.withScroller ? (
				<StaticAside withScroller isOpen={isOpen} variant={currentChannel ? 'CHANNEL' : 'NO_CHANNEL'}>
					{scroller => (
						<>
							{props.children(scroller)}
							<Banners />
						</>
					)}
				</StaticAside>
			) : (
				<StaticAside withScroller={false} isOpen={isOpen} variant={currentChannel ? 'CHANNEL' : 'NO_CHANNEL'}>
					{props.children}
					<Banners />
				</StaticAside>
			)

		default:
			return notReachable(MEDIA)
	}
}
