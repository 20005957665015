import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import type { Editor } from '@tiptap/react'

import { notReachable } from '@prostpost/utils'

import { usePostsStore } from 'app/domains/Post/store'
import type { EditorStore } from 'app/domains/Draft/store'

import type { EditorContext, EditorContentFromContext, PostState } from '../context'

export const usePostStateContext = (editorStore: EditorStore, editor: Editor | null) => {
	const location = useLocation() as Omit<ReturnType<typeof useLocation>, 'state'> & { state: PostState }

	const postsStore = usePostsStore()

	// Hold data to pass to the children routes via Outline context and prepare editor
	const [editorContentFromContext, setEditorContentIntoContext] = useState<EditorContentFromContext | undefined>()

	// Keep state of a post to display for PostList
	const [postState, setPostState] = useState<PostState | undefined>()

	// set Outlet context value
	// we use onMsg here to pass events from DraftEditor up to the WitchChannel
	// and then share their result with PostsList via postState
	const outletContext: EditorContext = {
		editor,
		editorStore,
		editorContentFromContext,
		onWithChannelContextMsg: msg => {
			switch (msg.type) {
				case 'clear_context':
					setEditorContentIntoContext(undefined)
					break
				case 'on_backup_cleaned':
					editorStore.resetContent()
					setEditorContentIntoContext(undefined)
					editorStore.setDraftLoadingStatus('LOADED')
					setPostState({ state: 'normal', postType: msg.postType, postUuid: msg.postUuid })
					break
				case 'on_start_cleaning_backup':
					editorStore.setDraftLoadingStatus('LOADING')
					setPostState({ state: 'loading', postType: msg.postType, postUuid: msg.postUuid })
					break
				case 'on_rescheduled':
					setEditorContentIntoContext(undefined)
					editorStore.setDraftLoadingStatus('LOADED')
					setPostState({ state: 'normal', postType: msg.post.type, postUuid: msg.post.uuid })
					break
				case 'on_republishing_undo':
					postsStore.setUnpublishedVersion(msg.draftUuid, msg.backupUuid)
					setPostState({ state: 'normal', postType: 'INTERNAL', postUuid: msg.draftUuid })
					break
				case 'on_failed_republished':
					editorStore.setDraftLoadingStatus('LOADED')
					postsStore.setUnpublishedVersion(msg.draftUuid, msg.backupUuid)
					setPostState({ state: 'normal', postType: 'INTERNAL', postUuid: msg.draftUuid })
					break
				case 'on_is_republishing':
					setEditorContentIntoContext(undefined)
					editorStore.setDraftLoadingStatus('LOADED')
					setPostState({ state: 'loading', postType: 'INTERNAL', postUuid: msg.uuid })
					break
				case 'on_republished':
					setPostState({ state: 'normal', postType: msg.post.type, postUuid: msg.post.uuid })
					break
				case 'on_scheduled_published':
					setPostState({ state: 'normal', postType: 'INTERNAL', postUuid: msg.postUuid })
					break
				case 'on_scheduled_publishing_start':
					setPostState({ state: 'loading', postType: msg.postType, postUuid: msg.postUuid })
					break
				case 'on_scheduled_publishing_failed':
					setPostState({ state: 'normal', postType: msg.postType, postUuid: msg.postUuid })
					break
				case 'on_failed_republished_review':
					setPostState({
						state: 'edit-existing-backup',
						postType: 'INTERNAL',
						postUuid: msg.draftUuid,
						backupUuid: msg.backupUuid,
						channelUuid: msg.channelUuid,
					})
					break
				default:
					notReachable(msg)
			}
		},
	}

	// accept draft to open/edit from the outside
	useEffect(() => {
		if (location.state?.postUuid) {
			switch (location.state.state) {
				case 'edit':
					setPostState({
						state: 'edit',
						postType: location.state.postType,
						postUuid: location.state.postUuid,
						channelUuid: location.state.channelUuid,
					})
					break
				case 'edit-existing-backup':
					setPostState({
						state: 'edit-existing-backup',
						postType: location.state.postType,
						postUuid: location.state.postUuid,
						channelUuid: location.state.channelUuid,
						backupUuid: location.state.backupUuid,
					})
					break
				case 'normal':
				case 'loading':
					break
				default:
					notReachable(location.state)
			}
		}
	}, [])

	return {
		postState,
		outletContext,
		setPostState,
		clearPostState: () => setPostState(undefined),
		setOutletContext: setEditorContentIntoContext,
	}
}
