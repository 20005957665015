import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Text, Button, PricingPlanTile } from '@prostpost/uikit'

import { UserFeedback } from 'app/modals/UserFeedback'

export const CustomPlanTile = ({ isActive, isPrimaryAction }: { isActive: boolean; isPrimaryAction: boolean }) => {
	const { t } = useTranslation()
	const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)

	return (
		<>
			<PricingPlanTile w="100%" variant={isActive ? 'primary' : 'secondary'}>
				<PricingPlanTile.Title>{t('userSettings.plans.customTitle', 'Custom')}</PricingPlanTile.Title>
				<PricingPlanTile.Features>
					<PricingPlanTile.Feature variant="plain">
						{t(
							'userSettings.plans.customFeatures2',
							'Need higher limits and priority support? Contact us for a personalized offer',
						)}
					</PricingPlanTile.Feature>
				</PricingPlanTile.Features>
				<PricingPlanTile.Side>
					{isActive ? (
						<Text size={14} color="black_80">
							{t('userSettings.plans.current', 'Current')}
						</Text>
					) : (
						<Button.Action
							size="small"
							detail={isPrimaryAction ? 'normal' : 'inverted'}
							variant={isPrimaryAction ? 'primary' : 'secondary'}
							onClick={() => setIsFeedbackModalOpen(true)}
						>
							{t('userSettings.plans.chatAction', "Let's chat")}
						</Button.Action>
					)}
				</PricingPlanTile.Side>
			</PricingPlanTile>
			<UserFeedback
				isOpen={isFeedbackModalOpen}
				onClose={() => setIsFeedbackModalOpen(false)}
				categories={['question', 'bug', 'review', 'feature_request']}
				defaultCategory="question"
			/>
		</>
	)
}
