import { useLocation } from 'react-router-dom'

import { trackEvent } from '../track'

const CATEGORY = 'channel'

export const useNewChannelAnalyticsEvents = () => {
	const ref = useLocation().pathname
	const trackClick = (e: string, params?: UmamiData) => trackEvent(CATEGORY, ref, 'click', e, params)
	const trackError = (e: string, params?: UmamiData) => trackEvent(CATEGORY, ref, 'error', e, params)
	return {
		clicks: {
			addChannel: () => trackClick('add_channel'),
			botExternalUrl: () => trackClick('add_channel_bot_link'),
			modalClosed: () => trackClick('add_channel_modal_closed'),
			submitNewChannel: (name: string) => trackClick('add_channel_submit', { name }),
		},
		errors: {
			invalidChannelName: (name: string) => trackError('add_channel_invalid_name', { name }),
		},
	}
}
