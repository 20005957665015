import { trackEventGrouped } from '../track'
import type { TrackingAction, TrackingGroup } from '../track'

type Referrer = 'editor' | 'overview' | 'settings' | 'feed'

const EVENTS_CATEGORY = 'draft'

const trackDraftEvent = (
	ref: Referrer,
	group: TrackingGroup,
	action: TrackingAction,
	eventName: string,
	params?: UmamiData,
	options?: UmamiOptions,
) => {
	trackEventGrouped(EVENTS_CATEGORY, ref, group, action, eventName, params, options)
}

export const useDraftAnalyticsEvents = (channel: string) => ({
	clicks: {
		newDraft: (ref: 'editor' | 'overview' | 'settings') => trackDraftEvent(ref, { channel }, 'click', 'new_draft'),
		scheduleDraft: (ref: 'editor' | 'feed') => trackDraftEvent(ref, { channel }, 'click', 'schedule'),
	},
})
