import { memo } from 'react'
import { Observer } from 'mobx-react-lite'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { Clock } from 'react-feather'
import * as Icons from 'react-feather'
import type { Moment } from 'moment'

import { useCurrentMedia } from '@prostpost/css'
import { CalendarDate } from '@prostpost/calendar'
import { Button, Flex, Text, Paragraph, Hr, SlidingPopup } from '@prostpost/uikit'
import { datetime, getNow, formatMoment, dateToPeriod } from '@prostpost/utils'

import { useBotStore } from 'app/domains/Bot/store'
import { isScheduleTimeValid } from 'app/domains/Draft/utils'
import { usePostsSchedulingCapacity } from 'app/domains/Statistics/hooks'

import { BotNotConnected } from '../BotNotConnected'

type Msg =
	| { type: 'on_confirm_reschedule'; date: Moment }
	| { type: 'on_click_publish_now' }
	| { type: 'on_open_reschedule_calendar' }
	| { type: 'on_close_reschedule_calendar' }

type Props = {
	time: string
	timeFormat: '12h' | '24h'
	formattedTime: string
	isPostPublishingFailed: boolean
	onMsg: (msg: Msg) => void
}

const arePropsEqual = (prevProps: Props, nextProps: Props) => {
	if (prevProps.time !== nextProps.time) return false
	if (prevProps.timeFormat !== nextProps.timeFormat) return false
	if (prevProps.formattedTime !== nextProps.formattedTime) return false
	return prevProps.isPostPublishingFailed === nextProps.isPostPublishingFailed
}

export const Time = memo(function Time({ time, timeFormat, formattedTime, isPostPublishingFailed, onMsg }: Props) {
	const theme = useTheme()
	const { t } = useTranslation()

	const MEDIA = useCurrentMedia()
	const isMobile = MEDIA === 'MOBILE'

	const botStore = useBotStore()

	const iconProps = {
		size: 16,
		strokeWidth: 2.5,
		color: isPostPublishingFailed ? theme.colors.red_100 : theme.colors.blue_100,
	}

	const {
		calendarCapacityQuery: { data: capacityByDay, status: capacityLoadingStatus },
		setCalendarVisibleMonth,
	} = usePostsSchedulingCapacity()

	return (
		<SlidingPopup
			wrapperProps={{ px: 3, py: 4, borderRadius: 'mid' }}
			onToggle={isOpen => {
				if (isOpen) {
					onMsg({ type: 'on_open_reschedule_calendar' })
				} else {
					onMsg({ type: 'on_close_reschedule_calendar' })
				}
			}}
			use={({ onClick }) => (
				<Flex
					align="center"
					onClick={() => {
						setCalendarVisibleMonth(getNow(false))
						onClick()
					}}
				>
					<Clock {...iconProps} />
					<Text noWrap ml={1} mr={1} size={14} color={isPostPublishingFailed ? 'red_100' : 'blue_100'}>
						{formattedTime}
					</Text>
				</Flex>
			)}
		>
			{({ close }) => (
				<>
					<Observer>
						{() => (botStore.link ? null : <BotNotConnected onClickConnectBot={() => console.log('')} />)}
					</Observer>
					<CalendarDate
						// styles
						maxWidth="360px"
						width={isMobile ? '100%' : '330px'}
						dayTileHeight={40}
						color="white"
						// flags
						isTimeEnabled
						isConfirmEnabled
						isLoading={capacityLoadingStatus === 'loading'}
						isTimeValid={isScheduleTimeValid}
						// data
						marks={capacityByDay}
						timeFormat={timeFormat}
						disabledDates={{ to: getNow(false).subtract(1, 'day') }}
						selectedDate={time ? datetime(time, false, { to: 'local' }) || undefined : undefined}
						// callbacks
						onSetMonth={setCalendarVisibleMonth}
						confirmAction={({ isTimeValid, time: date }) => {
							const relativeTime = dateToPeriod(
								datetime(date, true, {
									to: 'utc',
									timeFormat,
									time: 'keep',
									year: 'yes',
								}),
							)

							return (
								<>
									<Button.Action
										fullWidth
										variant="primary"
										size="mid"
										state={isTimeValid ? 'normal' : 'disabled'}
										mt={3}
										iconPosition="left"
										icon={<Icons.Clock size={16} strokeWidth={2.5} color={theme.colors.black_80} />}
										onClick={() => {
											if (!isTimeValid) return
											onMsg({ type: 'on_confirm_reschedule', date })
											close()
										}}
									>
										{isTimeValid
											? t('common:label.reschedule', 'Schedule {{relativeTime}}', {
													relativeTime,
												})
											: t('common:label.invalidTime', 'Invalid time')}
									</Button.Action>

									{isTimeValid ? (
										<Paragraph centered pt={4} size={12} color="blue_40">
											{t(
												'content:editor.post.willBePublished',
												'Post is going to be published on {{publishAt}}',
												{
													publishAt: formatMoment(date),
												},
											)}
										</Paragraph>
									) : null}

									<Hr mt={4} />

									<Button.Action
										mt={4}
										fullWidth
										size="mid"
										variant="secondary"
										iconPosition="left"
										icon={<Icons.Send size={16} strokeWidth={2.5} color={theme.colors.black_80} />}
										onClick={() => {
											onMsg({ type: 'on_click_publish_now' })
											close()
										}}
									>
										{t('content:editor.post.publishNow', 'Publish now')}
									</Button.Action>
								</>
							)
						}}
					/>
				</>
			)}
		</SlidingPopup>
	)
}, arePropsEqual)
