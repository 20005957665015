import { z } from 'zod'

import type {
	PostsDynamic,
	MembersDelta,
	AvgMinMaxStat,
	ChannelSingleStats,
	ChannelHistoricalEngagementStats,
	ChannelHistoricalForwardsStats,
	ChannelHistoricalMembersStats,
	ChannelHistoricalPostsStats,
	ChannelHistoricalViewsStats,
	ChannelHistoricalErrStats,
	SinglePostsContentStat,
	StatsChannelPostsParsed,
	PostCalendarCapacity,
	ScheduledPostsStats,
	ReactionUsageStats,
	EngagementStats,
} from '..'

const AvgMinMaxStatSchema: z.ZodSchema<AvgMinMaxStat, z.ZodTypeDef, unknown> = z.object({
	avg: z.number(),
	min: z.number(),
	max: z.number(),
})

const PostsDynamicStatSchema: z.ZodSchema<PostsDynamic, z.ZodTypeDef, unknown> = z.object({
	own: z.number(),
	total: z.number(),
	forwarded: z.number(),
})

export const StatsChannelPostsParsedSchema: z.ZodSchema<StatsChannelPostsParsed, z.ZodTypeDef, unknown> = z.object({
	channel: z.string(),
	channelMembers: z.number(),
	postsParsed: z.number(),
	postsLimit: z.number(),
})

export const ChannelEngagementStatsSchema: z.ZodSchema<ChannelHistoricalEngagementStats, z.ZodTypeDef, unknown> = z
	.object({
		data: z.object({
			type: z.literal('engagement'),
			values: z.record(
				z.string(),
				z.object({
					engagement: z.number(),
					reactions: z.number(),
				}),
			),
		}),
	})
	.transform(({ data }) => {
		const result: Array<{ date: string; value: EngagementStats }> = []
		for (const [date, value] of Object.entries<EngagementStats>(data.values)) {
			result.push({ date, value })
		}
		return result
	})

export const ChannelMembersStatsSchema: z.ZodSchema<ChannelHistoricalMembersStats, z.ZodTypeDef, unknown> = z
	.object({
		data: z.object({
			type: z.literal('members'),
			values: z.record(z.string(), z.number()),
		}),
	})
	.transform(({ data }) => {
		const result: Array<{ date: string; value: number }> = []
		for (const [date, value] of Object.entries<number>(data.values)) {
			result.push({ date, value })
		}
		return result
	})

export const ChannelPostsStatsSchema: z.ZodSchema<ChannelHistoricalPostsStats, z.ZodTypeDef, unknown> = z
	.object({
		data: z.object({
			type: z.literal('posts'),
			values: z.record(z.string(), PostsDynamicStatSchema),
		}),
	})
	.transform(({ data }) => {
		const result: Array<{ date: string; value: PostsDynamic }> = []
		for (const [date, value] of Object.entries<PostsDynamic>(data.values)) {
			result.push({ date, value })
		}
		return result
	})

export const ChannelViewsStatsSchema: z.ZodSchema<ChannelHistoricalViewsStats, z.ZodTypeDef, unknown> = z
	.object({
		data: z.object({
			type: z.literal('views'),
			values: z.record(z.string(), AvgMinMaxStatSchema),
		}),
	})
	.transform(({ data }) => {
		const result: Array<{ date: string; value: AvgMinMaxStat }> = []
		for (const [date, value] of Object.entries<AvgMinMaxStat>(data.values)) {
			result.push({ date, value })
		}
		return result
	})

export const ChannelForwardsStatsSchema: z.ZodSchema<ChannelHistoricalForwardsStats, z.ZodTypeDef, unknown> = z
	.object({
		data: z.object({
			type: z.literal('forwards'),
			values: z.record(z.string(), AvgMinMaxStatSchema),
		}),
	})
	.transform(({ data }) => {
		const result: Array<{ date: string; value: AvgMinMaxStat }> = []
		for (const [date, value] of Object.entries<AvgMinMaxStat>(data.values)) {
			result.push({ date, value })
		}
		return result
	})

export const ChannelErrStatsSchema: z.ZodSchema<ChannelHistoricalErrStats, z.ZodTypeDef, unknown> = z
	.object({
		data: z.object({
			type: z.literal('err'),
			values: z.record(z.string(), z.number()),
		}),
	})
	.transform(({ data }) => {
		const result: Array<{ date: string; value: number }> = []
		for (const [date, value] of Object.entries<number>(data.values)) {
			result.push({ date, value })
		}
		return result
	})

const SingleAdPostsStatSchema: z.ZodSchema<number, z.ZodTypeDef, unknown> = z
	.object({
		total: z.number(),
	})
	.transform(({ total }) => total)

const SingleMembersStatsSchema: z.ZodSchema<number, z.ZodTypeDef, unknown> = z
	.object({
		members: z.number(),
	})
	.transform(({ members }) => members)

const SingleMembersDeltaStatSchema: z.ZodSchema<MembersDelta, z.ZodTypeDef, unknown> = z.object({
	start: z.number(),
	end: z.number(),
})

const SinglePostsContentStatSchema: z.ZodSchema<SinglePostsContentStat, z.ZodTypeDef, unknown> = z
	.object({
		total: z.number(),
		avgWords: z.number(),
		minWords: z.number(),
		maxWords: z.number(),
		avgLetters: z.number(),
		minLetters: z.number(),
		maxLetters: z.number(),
		removed: z.number(),
		edited: z.number(),
		silent: z.number(),
		withWebPage: z.number(),
		withImages: z.number(),
	})
	.transform(result => ({
		postsCount: result.total,
		removedPostsCount: result.removed,
		editedPostsCount: result.edited,
		silentPublishedPostsCount: result.silent,
		postsWithWebPageCount: result.withWebPage,
		postsWithImagesCount: result.withImages,
		words: {
			avg: result.avgWords,
			min: result.minWords,
			max: result.maxWords,
		},
		letters: {
			avg: result.avgLetters,
			min: result.minLetters,
			max: result.maxLetters,
		},
	}))

const SinglePostsReactionsStatSchema: z.ZodSchema<ReactionUsageStats, z.ZodTypeDef, unknown> = z.object({
	reaction: z.string(),
	totalCount: z.number(),
})

export const ChannelSingleStatsSchema: z.ZodSchema<ChannelSingleStats, z.ZodTypeDef, unknown> = z
	.object({
		data: z.object({
			members: SingleMembersStatsSchema,
			membersDelta: SingleMembersDeltaStatSchema,
			views: AvgMinMaxStatSchema,
			forwards: AvgMinMaxStatSchema,
			postsPerDay: AvgMinMaxStatSchema,
			postsAd: SingleAdPostsStatSchema,
			postsContent: SinglePostsContentStatSchema,
			reactions: z.array(SinglePostsReactionsStatSchema),
			lastUpdated: z.string(),
		}),
	})
	.transform(({ data }) => data)

export const PostCalendarCapacitySchema: z.ZodSchema<PostCalendarCapacity, z.ZodTypeDef, unknown> = z
	.object({
		capacity: z.record(z.string(), z.record(z.string(), z.record(z.string(), z.boolean()))),
	})
	.transform(({ capacity }) => capacity)

export const ScheduledPostsStatsSchema: z.ZodSchema<ScheduledPostsStats, z.ZodTypeDef, unknown> = z
	.object({
		ad: z.number(),
		posts: z.number(),
		channels: z.record(z.string().uuid(), z.number()),
	})
	.transform(({ channels, ...response }) => ({
		...response,
		byChannels: channels,
	}))
