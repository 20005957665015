import { useLocation } from 'react-router-dom'

import { notReachable } from '@prostpost/utils'

import type { ChannelActive } from 'app/domains/Channel'

import { AUTH_ROUTES } from 'app/config/supabase'

export const routesUrls = {
	inbox: AUTH_ROUTES.inAppHome,
	channel: {
		getSettingsRoute: (channel: ChannelActive) => `/settings/${channel.name}`,
		getOverviewRoute: (channel: ChannelActive) => `/overview/${channel.name}`,
		getEditorRoute: (channel: ChannelActive | string) =>
			`/editor/${typeof channel === 'string' ? channel : channel.name}`,
	},
	onboarding: {
		index: '/onboard',
		addChannel: '/onboard/channel',
		linkBot: '/onboard/link',
		success: '/onboard/success',
	},
	private: {},
	home: '/',
	email: {
		unsubscribe: '/callback/unsubscribe',
		invalidRequest: '/callback/invalidrequest',
	},
	contactUs: '/contact',
	policy: {
		index: '/legal',
		pp: '/legal/privacy',
		terms: '/legal/terms',
		cookie: '/legal/cookie',
		refund: '/legal/refund',
		impressum: '/legal/impressum',
		disclaimer: '/legal/disclaimer',
		use: '/legal/use',
	},
	auth: {
		index: '/auth',
		signin: AUTH_ROUTES.signin,
		signup: AUTH_ROUTES.signup,
		signinOtpRequest: `${AUTH_ROUTES.signin}/otp-request`,
		signinOtpVerify: `${AUTH_ROUTES.signin}/otp-verify`,
	},
}

type ChannelRoutes = 'settings' | 'overview' | 'editor'
type OnboardingRoutes = 'email' | 'channel' | 'link' | 'success'

export const matchChannelRoute = (route: ChannelRoutes | 'any', pathname: string, channel: ChannelActive) => {
	let isMatched: ChannelRoutes | false = false
	if (!channel) return false
	if (pathname.includes(`editor/${channel.name}`)) isMatched = 'editor'
	if (pathname.includes(`overview/${channel.name}`)) isMatched = 'overview'
	if (pathname.includes(`settings/${channel.name}`)) isMatched = 'settings'
	return isMatched && route === 'any' ? true : isMatched === route
}

export const useMatchChannelRoute = (route: ChannelRoutes | 'any', channel: ChannelActive) => {
	const { pathname } = useLocation()
	return matchChannelRoute(route, pathname, channel)
}

export const useMatchOnboardingRoute = (route: OnboardingRoutes | 'any') => {
	const { pathname } = useLocation()
	let isMatched: OnboardingRoutes | false = false
	if (pathname.includes('onboard/channel')) isMatched = 'channel'
	if (pathname.includes('onboard/link')) isMatched = 'link'
	if (pathname.includes('onboard/success')) isMatched = 'success'
	return isMatched && route === 'any' ? true : isMatched === route
}

export const useMatchRoute = (route: 'private' | 'inbox') => {
	const { pathname } = useLocation()
	switch (route) {
		case 'inbox':
			return pathname === '/inbox'
		case 'private':
			return pathname.includes('/private/')
		default:
			return notReachable(route)
	}
}
