import { useState } from 'react'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import * as Icons from 'lucide-react'

import { getNow } from '@prostpost/utils'
import { useCurrentMedia } from '@prostpost/css'
import { HStack, Text, Box, TextLink } from '@prostpost/uikit'

import { useShowTopSticker } from 'app/shared/hooks'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { UserSettings } from 'app/modals/UserSettings'

type Props = {
	onDismiss: () => void
}

const TrialSticker = ({ onDismiss }: Props) => {
	const theme = useTheme()
	const media = useCurrentMedia()

	const { t } = useTranslation()
	const { preferences } = useOnboardedUserStore()

	const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState(false)

	const trialDaysLeft = Math.abs(getNow(false).diff(preferences.nextSubscriptionPeriod.clone(), 'days'))
	const trialEndDatePassed = getNow(false).isAfter(preferences.nextSubscriptionPeriod.clone())

	const trialNotificationMessage = (() => {
		if (trialEndDatePassed) {
			return t(
				'content:sticker.trial.titleExpired',
				'Your trial period is expired. Upgrade plan to continue using the app.',
			)
		}

		if (trialDaysLeft === 1) {
			return t('content:sticker.trial.titleTomorrow', 'Your trial period expires tomorrow')
		} else if (trialDaysLeft === 0) {
			return t('content:sticker.trial.titleToday', 'Your trial period expires today')
		} else {
			return t('content:sticker.trial.title', 'Your trial period expires in {{count}} days', {
				count: trialDaysLeft,
			})
		}
	})()

	return (
		<HStack
			space={0}
			p={2}
			ml={media === 'MOBILE' ? '0' : '-10px'}
			align="center"
			just="space-between"
			position="relative"
			bg="yellow_100"
		>
			<HStack space={1} align="center">
				<Icons.Gift size={14} strokeWidth={2.5} color={theme.colors.black_80} />
				<Text size={13_500} color="black_80">
					{trialNotificationMessage}
				</Text>
			</HStack>

			<HStack space={5} position="absolute" align="center" right={3} top="9px">
				{trialEndDatePassed ? null : (
					<Text size={13_500} color="black_80" mt="-5px">
						<TextLink variant="plain" color="black_80" onClick={() => setIsUserSettingsModalOpen(true)}>
							{'Upgrade now'}
						</TextLink>
					</Text>
				)}
				<Box as="button">
					<Icons.X size={14} strokeWidth={2.5} color={theme.colors.black_80} onClick={onDismiss} />
				</Box>
			</HStack>

			<UserSettings
				currentTab="plan"
				isOpen={isUserSettingsModalOpen}
				onClose={() => setIsUserSettingsModalOpen(false)}
			/>
		</HStack>
	)
}

export const TopSticker = observer(() => {
	const { preferences } = useOnboardedUserStore()
	const { topSticker, onDismissSticker } = useShowTopSticker()

	switch (preferences.plan.name) {
		case 'TRIAL':
			return topSticker.isVisible ? <TrialSticker onDismiss={onDismissSticker} /> : null
		case 'PRO':
		case 'CUSTOM':
		case 'STARTER':
		default:
			return null
	}
})
