import { z } from 'zod'
import { useEffect } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { useForm } from 'react-hook-form'
import type { ComponentProps } from 'react'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { Textarea, Input } from '@prostpost/uikit-next'
import { Flex, Field, VStack, HStack, Rating, Button, Null } from '@prostpost/uikit'

import { useCreateFeedbackMessage } from 'app/domains/Support/api'
import { TurnStileCaptcha } from 'app/shared/components'
import type { Category, QuestionCategory } from 'app/domains/Support'

import { FEEDBACK_CATEGORIES, FEEDBACK_QUESTION_TYPES } from './constants'

export type Msg = { type: 'on_submit_feedback' }

type FormData = {
	category: Category
	captcha?: string
	subCategory?: QuestionCategory
	message?: string
	rating?: number
	email?: string
}

type SecondaryActionProps = Omit<ComponentProps<typeof Button.Action>, 'size' | 'variant' | 'children'> & {
	label: string
}

type Props = {
	defaultCategory: Category
	categories?: Category[]
	secondaryAction?: SecondaryActionProps
	isUserAuthorized: boolean
	onMsg: (msg: Msg) => void
}

const useToasts = () => {
	const { t } = useTranslation()

	const feedbackSent = useToast({
		type: 'success',
		text: t('notify:feedback.create.success', 'Message sent successfully'),
	})

	const feedbackFailed = useToast({
		type: 'error',
		text: t('notify:feedback.create.error', 'Failed to send your message'),
	})

	const feedbackEmailRequired = useToast({
		type: 'error',
		text: t('notify:support.feedback.emailRequired', 'Unable to submit a message without email'),
	})

	const feedbackRequestsPerHourLimitExceeded = useToast({
		type: 'error',
		text: t(
			'notify:support.feedback.requestsPerHourLimitExceeded',
			'You have exceeded the limit of requests per hour',
		),
	})

	return { feedbackSent, feedbackFailed, feedbackEmailRequired, feedbackRequestsPerHourLimitExceeded }
}

export const FeedbackForm = ({
	secondaryAction,
	isUserAuthorized,
	defaultCategory = 'review',
	categories = ['review', 'feature_request', 'bug', 'question'],
	onMsg,
}: Props) => {
	const { t } = useTranslation()
	const { feedbackSent, feedbackFailed, feedbackEmailRequired, feedbackRequestsPerHourLimitExceeded } = useToasts()

	const { useCreateFeedbackMutation } = useCreateFeedbackMessage()

	const formSchema: z.ZodSchema<FormData> = z.object({
		captcha: isUserAuthorized ? z.optional(z.string()) : z.string(),
		email: isUserAuthorized
			? z.optional(z.string().email())
			: z.string().email({ message: t('notify:support.feedback.emailRequired', 'Enter a valid email') }),
		rating: z.optional(z.number()),
		category: z.union([z.literal('review'), z.literal('feature_request'), z.literal('bug'), z.literal('question')]),
		subCategory: z.optional(
			z.union([
				z.literal('billing_and_payments'),
				z.literal('technical_issue'),
				z.literal('account_management'),
				z.literal('data_and_security'),
				z.literal('other'),
			]),
		),
		message: z.optional(
			z.string().max(500, t('notify:support.feedback.commentMaxLength', 'Maximum 500 characters allowed')),
		),
	})

	const {
		watch,
		control,
		setValue,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: '',
			message: '',
			category: defaultCategory,
			captcha: process.env.NODE_ENV === 'development' ? 'any-value-to-bypass-validation' : undefined,
			rating: 0,
		},
	})

	useEffect(() => {
		switch (useCreateFeedbackMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'error':
				console.error(useCreateFeedbackMutation.error)
				switch (useCreateFeedbackMutation.error.code) {
					case 'REQUESTS_PER_HOUR_LIMIT_EXCEEDED':
						feedbackRequestsPerHourLimitExceeded.show()
						break
					case 'EITHER_USER_OR_EMAIL_IS_REQUIRED':
						feedbackEmailRequired.show()
						break
					case 'NOT_VALID_ERROR':
					case 'UNKNOWN_ERROR':
					default:
						feedbackFailed.show()
						break
				}
				break
			case 'success':
				onMsg({ type: 'on_submit_feedback' })
				feedbackSent.show()
				break
			default:
				notReachable(useCreateFeedbackMutation)
		}
	}, [useCreateFeedbackMutation.status])

	const category = watch('category')

	return (
		<VStack space={5} w="100%">
			{categories.includes('review') ? (
				<Flex w="100%" py={4}>
					<Rating name="rating" defaultRating={0} onChange={rating => setValue('rating', rating)} />
				</Flex>
			) : null}

			<VStack space={3}>
				{categories.length > 1 ? (
					<Field.Dropdown
						isSearchEnabled={false}
						options={FEEDBACK_CATEGORIES}
						value={FEEDBACK_CATEGORIES.find(op => op.value === defaultCategory)}
						onSelect={option => setValue('category', (option?.value as Category) || 'review')}
					/>
				) : null}

				{category === 'question' ? (
					<Field>
						{categories.length === 1 ? (
							<Field.Label
								fieldName="subCategory"
								label={t('content:feedbackForm.subCategoryLabel', 'Select category')}
							/>
						) : (
							<Null />
						)}
						<Field.Dropdown
							isSearchEnabled={false}
							options={FEEDBACK_QUESTION_TYPES}
							value={FEEDBACK_QUESTION_TYPES[0]}
							onSelect={option => setValue('subCategory', (option?.value as QuestionCategory) || 'other')}
						/>
					</Field>
				) : null}

				{isUserAuthorized ? null : (
					<Controller
						name="email"
						control={control}
						render={({ field }) => (
							<Input
								size="xs"
								variant={errors.email ? 'error' : 'normal'}
								message={errors.email?.message}
								iconPosition="head"
								placeholder={t('content:support.feedback.yourEmailPlaceholder', 'Your email address')}
								{...field}
								onBlur={() => {
									clearErrors()
									field.onBlur()
								}}
							/>
						)}
					/>
				)}

				<Controller
					name="message"
					control={control}
					render={({ field }) => (
						<Textarea
							size="xs"
							variant={errors.message ? 'error' : 'normal'}
							message={errors.message?.message}
							placeholder={
								defaultCategory === 'question'
									? t(
											'content:support.feedback.commentPlaceholderQuestion',
											'Write down your question and we will find the solution',
										)
									: t(
											'content:support.feedback.commentPlaceholderCommon',
											'Please tell us what is on your mind :)',
										)
							}
							{...field}
							onBlur={() => {
								clearErrors()
								field.onBlur()
							}}
						/>
					)}
				/>
			</VStack>

			<HStack space={2} align="center" just="space-between">
				{secondaryAction && (
					<Button.Action variant="secondary" size="mid" {...secondaryAction}>
						{secondaryAction.label}
					</Button.Action>
				)}

				<Button.Action
					variant="primary"
					size="mid"
					state={useCreateFeedbackMutation.status === 'loading' ? 'pending' : 'normal'}
					onClick={async () => {
						await handleSubmit(data => {
							return useCreateFeedbackMutation.mutate(data)
						})()
					}}
				>
					{category === 'question'
						? t('content:feedbackForm.submitQuestion', 'Submit question')
						: t('content:feedbackForm.submit', 'Submit feedback')}
				</Button.Action>

				{isUserAuthorized ? null : (
					<TurnStileCaptcha
						isVisible
						onTokenSet={token => {
							if (process.env.NODE_ENV !== 'development') {
								setValue('captcha', token)
							}
						}}
					/>
				)}
			</HStack>
		</VStack>
	)
}
