import { useEffect } from 'react'
import { runInAction } from 'mobx'
import { useTranslation } from 'react-i18next'
import type { Moment } from 'moment'

import { notReachable } from '@prostpost/utils'
import { VStack, Heading } from '@prostpost/uikit'

import { usePostsStore } from 'app/domains/Post/store'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { PostPreview } from 'app/domains/Post/features/PostPreview'
import { PostReadonlyModal } from 'app/domains/Post/features/PostReadonlyModal'
import type { DraftScheduled } from 'app/domains/Draft'

import { useLoadingPosts, useSelectedPost } from '../../hooks'
import type { PostState } from '../types'

import { DataLoader } from './DataLoader'

type Msg =
	| { type: 'on_click_edit'; post: DraftScheduled }
	| { type: 'on_remove_post_from_feed'; post: DraftScheduled }
	| { type: 'on_open_editor'; channelName: string }
	| { type: 'on_click_load_unpublished_version'; post: DraftScheduled }

export type Interface = {
	range?: [Moment, Moment?]
	timeFormat: '12h' | '24h'
	postState: Extract<PostState, { state: 'loading' | 'normal' | 'selected' }> | undefined
	onMsg: (msg: Msg) => void
}

export const Tiles = ({ postState, timeFormat, ...props }: Interface) => {
	const { t } = useTranslation()

	const channels = useChannelsStore()
	const postsStore = usePostsStore()
	const { preferences } = useOnboardedUserStore()

	const { isPostLoading, addLoadingPost, removeLoadingPost } = useLoadingPosts()
	const { selectedPost, getSelectionMode, clearSelection, setSelectedPost } = useSelectedPost()

	useEffect(() => {
		if (!postState) return
		switch (postState.state) {
			case 'normal':
				removeLoadingPost(postState.postUuid)
				clearSelection()
				break
			case 'loading':
				clearSelection()
				addLoadingPost(postState.postUuid)
				break
			case 'selected':
				break
			default:
				notReachable(postState.state)
		}
	}, [postState])

	return (
		<>
			<DataLoader {...props}>
				{({ feed }) => (
					<VStack space={4} w="100%">
						<Heading h={4} pt={5}>
							{t('content:feed.scheduledPosts.title', 'Scheduled posts')}
						</Heading>
						{feed.length ? (
							<VStack space={3} dir="column" w="100%">
								{feed.map(post => {
									const channel = channels.activeList.find(ch => ch.uuid === post.channelUuid)
									if (!channel) return null
									return (
										<PostPreview.Scheduled
											key={post.uuid}
											post={post}
											timeFormat={timeFormat}
											channel={channel}
											isDisabled={false}
											isImageVisible={preferences.feed.isPostImagesShown}
											isLoading={isPostLoading(post.uuid)}
											selection={getSelectionMode.scheduled(post, selectedPost)}
											channelsCount={channels.activeList.length}
											onMsg={msg => {
												runInAction(() => {
													switch (msg.type) {
														case 'on_set_post_loading':
															if (msg.isLoading) addLoadingPost(post.uuid)
															else removeLoadingPost(post.uuid)
															break
														case 'on_click_edit':
															props.onMsg({
																type: 'on_click_edit',
																post,
															})
															break
														case 'on_click_load_unpublished_version':
															props.onMsg({
																type: 'on_click_load_unpublished_version',
																post,
															})
															break
														case 'on_published_now':
															// update post object with tgId and other data after actual publication
															// also switch from "publishing" to "published" (internal) type
															postsStore.updatePublishingPostOnPublish(
																post.uuid,
																msg.post,
															)
															break
														case 'on_publish_now_failed':
														case 'on_publish_now_canceled':
															removeLoadingPost(post.uuid)
															break
														case 'on_click_unschedule':
														case 'on_click_publish_now':
														case 'on_start_cleaning_backup':
															addLoadingPost(post.uuid)
															break
														case 'on_unschedule':
															props.onMsg({
																type: 'on_remove_post_from_feed',
																post,
															})
															break
														case 'on_rescheduled':
															postsStore.updateScheduledPost(post.uuid, {
																publishAt: msg.publishAt,
															})
															break
														case 'on_click_toggle_silent':
															postsStore.updateScheduledPost(post.uuid, {
																silent: msg.isSilent,
															})
															break
														case 'on_toggle_silent_failed':
															postsStore.updateScheduledPost(post.uuid, {
																silent: msg.prevIsSilent,
															})
															break
														case 'on_backup_cleaned':
															break
														case 'on_click_open_modal_editor':
															addLoadingPost(msg.draftUuid)
															setSelectedPost({
																type: 'SCHEDULED',
																uuid: msg.draftUuid,
																mode: 'readonly',
															})
															break
														case 'on_click_copy_as_draft':
															break
														default:
															notReachable(msg)
													}
												})
											}}
										/>
									)
								})}
							</VStack>
						) : null}
					</VStack>
				)}
			</DataLoader>

			{selectedPost?.mode === 'readonly' ? (
				<PostReadonlyModal
					isTriggered
					draftType={selectedPost.type}
					draftUuid={selectedPost.uuid}
					onMsg={msg => {
						switch (msg.type) {
							case 'on_close_post_readonly_modal':
								setSelectedPost(null)
								break
							case 'on_post_loaded_for_readonly_modal':
								removeLoadingPost(msg.draftUuid)
								break
							case 'on_open_post_in_editor': {
								const post = postsStore.posts.find(
									post => post.uuid === msg.draftUuid && post.type === 'SCHEDULED',
								) as DraftScheduled

								if (!post) {
									console.error('Unable to find scheduled post to open in Editor')
								}

								props.onMsg({
									type: 'on_click_edit',
									post,
								})
								break
							}
							case 'on_post_loading_failed_for_readonly_modal':
								setSelectedPost(null)
								removeLoadingPost(msg.draftUuid)
								break
							case 'on_click_copy_as_draft':
								break
							default:
								notReachable(msg)
						}
					}}
				/>
			) : (
				<PostReadonlyModal isTriggered={false} />
			)}
		</>
	)
}
