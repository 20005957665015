import { observer } from 'mobx-react-lite'

import { HStack, Null } from '@prostpost/uikit'

import type { PostInternalPublishing } from 'app/domains/Post'
import type { ChannelActive } from 'app/domains/Channel'

import { Time } from '../Internal/Time'
import {
	Header,
	Overlay,
	Views,
	Forwards,
	Wrapper,
	AdTag,
	SilentMark,
	ContextMenuWrapper,
	TextAndImages,
} from '../../components'

type Props = {
	channel: ChannelActive
	timeFormat: '12h' | '24h'
	post: PostInternalPublishing
	isDisabled: boolean
	isImageVisible: boolean
	channelsCount: number
}

export const InternalPublishing = observer(function InternalPublishing({
	isDisabled = false,
	isImageVisible = true,
	channel,
	post,
	timeFormat,
	channelsCount,
}: Props) {
	return (
		<Wrapper isSelectable={!isDisabled} isFailedToPublish={false} isSelected={false}>
			<Overlay isLoading />

			<Header channelsCount={channelsCount} channel={channel}>
				<Header.Time post={post} timeFormat={timeFormat}>
					{({ formattedTime }) => <Time time={formattedTime} />}
				</Header.Time>

				<HStack space={2} mr="auto" ml={2}>
					{post.ad ? <AdTag /> : null}
					{post.silent ? <SilentMark /> : null}
					<Views views={post.views} />
					{post.forwards ? <Forwards forwards={post.forwards} /> : null}
				</HStack>

				<ContextMenuWrapper isDisabled iconColor="blue_20">
					<Null />
				</ContextMenuWrapper>
			</Header>

			<TextAndImages post={post} isImageVisible={isImageVisible} />
		</Wrapper>
	)
})
