import { runInAction } from 'mobx'
import { useEffect, useState } from 'react'

import { notReachable } from '@prostpost/utils'

import { unsupportedPostTypes } from 'app/domains/Post'
import { usePostsStore } from 'app/domains/Post/store'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useCreateBackup, useBackup } from 'app/domains/Post/api'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { PostPreview } from 'app/domains/Post/features/PostPreview'
import { PostReadonlyModal } from 'app/domains/Post/features/PostReadonlyModal'
import type { DraftScheduled, DraftNotScheduled } from 'app/domains/Draft'
import type { ChannelActive } from 'app/domains/Channel'
import type {
	PostInternal,
	PostHistory,
	PostBackup,
	PostInternalPublishing,
	UnsupportedPostType,
} from 'app/domains/Post'

import { useLoadingPosts, useSelectedPost } from '../../hooks'
import type { PostState } from '../types'

import { DataLoader } from './DataLoader'

type Msg =
	| { type: 'on_click_open_in_telegram'; post: PostHistory | PostInternal }
	| { type: 'on_post_removed_from_feed'; post: PostInternal | PostInternalPublishing | DraftScheduled }
	| { type: 'on_post_preparing_for_editing'; channelUuid: string; draftUuid: string }
	| { type: 'on_post_backup_ready_to_edit'; backup: PostBackup }
	| { type: 'on_click_copy_as_draft'; draft: DraftNotScheduled }
	| { type: 'on_start_cleaning_backup' }
	| { type: 'on_backup_cleaned'; postUuid: string }
	| { type: 'on_open_editor'; channelName: string }

export type Interface = {
	timeFormat: '12h' | '24h'
	channel: ChannelActive
	isEditorOpen: boolean
	postState: PostState | undefined // control post state from outside
	onMsg: (msg: Msg) => void
}

export const Tiles = ({ postState, channel, timeFormat, onMsg, ...props }: Interface) => {
	const postsStore = usePostsStore()
	const { preferences } = useOnboardedUserStore()

	const { isPostLoading, addLoadingPost, removeLoadingPost } = useLoadingPosts()
	const { selectedPost, getSelectionMode, setSelectedPost, clearSelection } = useSelectedPost()

	const [backupToLoad, setBackupToLoad] = useState<string | undefined>()

	const channels = useChannelsStore()
	const activeChannelsCount = runInAction(() => channels.activeList.length)

	useBackup(backupToLoad || '', {
		enabled: !!backupToLoad,
		onSuccess: backup => {
			removeLoadingPost(backup.draftUuid)
			onMsg({
				type: 'on_post_backup_ready_to_edit',
				backup,
			})
		},
	})

	const { createPostBackupMutation } = useCreateBackup({
		// we need a callback instead useEffect because Edit component is unmounted after clicking on Edit button
		onSuccess: backup => {
			removeLoadingPost(backup.draftUuid)
			onMsg({
				type: 'on_post_backup_ready_to_edit',
				backup,
			})
		},
	})

	useEffect(() => {
		if (!postState) return
		switch (postState.state) {
			case 'normal':
				setBackupToLoad(undefined)
				removeLoadingPost(postState.postUuid)
				clearSelection()
				break
			case 'loading':
				clearSelection()
				addLoadingPost(postState.postUuid)
				break
			case 'selected':
				setBackupToLoad(undefined)
				break
			case 'edit': {
				addLoadingPost(postState.postUuid)
				createPostBackupMutation.mutate({ uuid: postState.postUuid })
				setSelectedPost({
					mode: 'editing',
					type: postState.postType,
					uuid: postState.postUuid,
				})
				onMsg({
					type: 'on_post_preparing_for_editing',
					channelUuid: postState.channelUuid,
					draftUuid: postState.postUuid,
				})
				break
			}
			case 'edit-existing-backup':
				if (backupToLoad) return
				addLoadingPost(postState.postUuid)
				setBackupToLoad(postState.backupUuid)
				setSelectedPost({
					type: postState.postType,
					uuid: postState.postUuid,
					mode: 'editing',
				})
				onMsg({
					type: 'on_post_preparing_for_editing',
					channelUuid: postState.channelUuid,
					draftUuid: postState.postUuid,
				})
				break
			default:
				notReachable(postState)
		}
		// trigger only if post state is changing or we started working with a different post (from current one)
	}, [postState?.state, postState?.postUuid])

	return (
		<>
			<DataLoader
				channel={channel}
				{...props}
				onMsg={onMsg}
				renderItem={post => {
					const commonPostPreviewProps = {
						isDisabled: selectedPost?.mode === 'editing' || post.type === 'INTERNAL_PUBLISHING', // disable all posts controls when one is editing
						isImageVisible: runInAction(() => preferences.feed.isPostImagesShown),
					}

					switch (post.type) {
						case 'INTERNAL_PUBLISHING':
							return (
								<PostPreview.InternalPublishing
									key={post.uuid}
									post={post}
									channel={channel}
									timeFormat={timeFormat}
									channelsCount={activeChannelsCount}
									{...commonPostPreviewProps}
								/>
							)

						case 'HISTORY': {
							const postType = post.meta?.type
							const showUnsupportedPosts = runInAction(() => preferences.feed.isUnsupportedPostsShown)

							if (
								!showUnsupportedPosts &&
								postType &&
								unsupportedPostTypes.includes(postType as unknown as UnsupportedPostType)
							) {
								return null
							}

							return (
								<PostPreview.History
									key={post.uuid}
									post={post}
									channel={channel}
									timeFormat={timeFormat}
									channelsCount={activeChannelsCount}
									onMsg={msg => {
										switch (msg.type) {
											case 'on_click_open_in_telegram':
												onMsg({ type: 'on_click_open_in_telegram', post })
												break
											default:
												notReachable(msg.type)
										}
									}}
									{...commonPostPreviewProps}
								/>
							)
						}

						case 'INTERNAL':
							return (
								<PostPreview.Internal
									key={post.uuid}
									post={post}
									channel={channel}
									timeFormat={timeFormat}
									{...commonPostPreviewProps}
									channelsCount={activeChannelsCount}
									selection={getSelectionMode.internal(post, selectedPost)}
									isLoading={isPostLoading(post.uuid)}
									onMsg={msg => {
										runInAction(() => {
											switch (msg.type) {
												case 'on_unpublished':
													removeLoadingPost(post.uuid)
													onMsg({
														type: 'on_post_removed_from_feed',
														post,
													})
													break
												case 'on_unpublish_failed':
													removeLoadingPost(post.uuid)
													break
												case 'on_click_edit':
													addLoadingPost(post.uuid)
													createPostBackupMutation.mutate({
														uuid: post.uuid,
													})
													setSelectedPost({
														type: 'INTERNAL',
														uuid: post.uuid,
														mode: 'editing',
													})
													onMsg({
														type: 'on_post_preparing_for_editing',
														channelUuid: post.channelUuid,
														draftUuid: post.uuid,
													})
													break
												case 'on_backup_cleaned':
													removeLoadingPost(post.uuid)
													clearSelection()
													postsStore.clearUnpublishedVersion(msg.postUuid)
													onMsg({
														type: 'on_backup_cleaned',
														postUuid: post.uuid,
													})
													break
												case 'on_click_load_unpublished_version':
													addLoadingPost(post.uuid)
													setBackupToLoad(msg.backupUuid)
													setSelectedPost({
														type: 'INTERNAL',
														uuid: post.uuid,
														mode: 'editing',
													})
													onMsg({
														type: 'on_post_preparing_for_editing',
														channelUuid: post.channelUuid,
														draftUuid: post.uuid,
													})
													break
												case 'on_click_unpublish':
													addLoadingPost(post.uuid)
													break
												case 'on_start_cleaning_backup':
													onMsg(msg)
													break
												case 'on_click_open_modal_editor':
													addLoadingPost(msg.postUuid)
													setSelectedPost({
														type: 'INTERNAL',
														uuid: msg.postUuid,
														mode: 'readonly',
													})
													break
												case 'on_click_open_in_telegram':
													onMsg({ type: 'on_click_open_in_telegram', post })
													break
												case 'on_click_copy_as_draft':
													onMsg(msg)
													break
												default:
													notReachable(msg)
											}
										})
									}}
								/>
							)

						case 'SCHEDULED':
							return (
								<PostPreview.Scheduled
									key={post.uuid}
									post={post}
									channel={channel}
									timeFormat={timeFormat}
									channelsCount={activeChannelsCount}
									{...commonPostPreviewProps}
									isLoading={isPostLoading(post.uuid)}
									selection={getSelectionMode.scheduled(post, selectedPost)}
									onMsg={msg => {
										runInAction(() => {
											switch (msg.type) {
												case 'on_set_post_loading':
													if (msg.isLoading) addLoadingPost(post.uuid)
													else removeLoadingPost(post.uuid)
													break
												case 'on_click_edit':
													addLoadingPost(post.uuid)
													createPostBackupMutation.mutate({
														uuid: post.uuid,
													})
													setSelectedPost({
														type: 'SCHEDULED',
														uuid: post.uuid,
														mode: 'editing',
													})
													onMsg({
														type: 'on_post_preparing_for_editing',
														channelUuid: post.channelUuid,
														draftUuid: post.uuid,
													})
													break
												case 'on_unschedule':
													removeLoadingPost(post.uuid)
													onMsg({
														type: 'on_post_removed_from_feed',
														post,
													})
													break
												case 'on_backup_cleaned':
													removeLoadingPost(post.uuid)
													clearSelection()
													postsStore.clearUnpublishedVersion(msg.postUuid)
													onMsg({
														type: 'on_backup_cleaned',
														postUuid: post.uuid,
													})
													break
												case 'on_click_unschedule':
												case 'on_click_publish_now':
												case 'on_start_cleaning_backup':
													addLoadingPost(post.uuid)
													break
												case 'on_published_now':
													removeLoadingPost(post.uuid)
													// update post object with tgId and other data after actual publication
													// also switch from "publishing" to "published" (internal) type
													postsStore.updatePublishingPostOnPublish(post.uuid, msg.post)
													break
												case 'on_click_load_unpublished_version':
													addLoadingPost(post.uuid)
													setBackupToLoad(msg.backupUuid)
													setSelectedPost({
														type: 'SCHEDULED',
														uuid: post.uuid,
														mode: 'editing',
													})
													onMsg({
														type: 'on_post_preparing_for_editing',
														channelUuid: post.channelUuid,
														draftUuid: post.uuid,
													})
													break
												case 'on_publish_now_failed':
												case 'on_publish_now_canceled':
													removeLoadingPost(post.uuid)
													break
												case 'on_rescheduled':
													postsStore.updateScheduledPost(post.uuid, {
														publishAt: msg.publishAt,
													})
													break
												case 'on_click_toggle_silent':
													postsStore.updateScheduledPost(post.uuid, {
														silent: msg.isSilent,
													})
													break
												case 'on_toggle_silent_failed':
													postsStore.updateScheduledPost(post.uuid, {
														silent: msg.prevIsSilent,
													})
													break
												case 'on_click_open_modal_editor':
													addLoadingPost(msg.draftUuid)
													setSelectedPost({
														type: 'SCHEDULED',
														uuid: msg.draftUuid,
														mode: 'readonly',
													})
													break
												case 'on_click_copy_as_draft':
													onMsg(msg)
													break
												default:
													notReachable(msg)
											}
										})
									}}
								/>
							)
						default:
							return notReachable(post)
					}
				}}
			/>

			{selectedPost?.mode === 'readonly' ? (
				<PostReadonlyModal
					isTriggered
					draftType={selectedPost.type}
					draftUuid={selectedPost.uuid}
					onMsg={msg => {
						switch (msg.type) {
							case 'on_close_post_readonly_modal':
								setSelectedPost(null)
								break
							case 'on_post_loaded_for_readonly_modal':
								removeLoadingPost(msg.draftUuid)
								break
							case 'on_open_post_in_editor':
								addLoadingPost(msg.draftUuid)
								createPostBackupMutation.mutate({
									uuid: msg.draftUuid,
								})
								setSelectedPost({
									type: msg.draftType,
									uuid: msg.draftUuid,
									mode: 'editing',
								})
								onMsg({
									type: 'on_post_preparing_for_editing',
									channelUuid: channel.uuid,
									draftUuid: msg.draftUuid,
								})
								break
							case 'on_post_loading_failed_for_readonly_modal':
								setSelectedPost(null)
								removeLoadingPost(msg.draftUuid)
								break
							case 'on_click_copy_as_draft':
								onMsg(msg)
								break
							default:
								notReachable(msg)
						}
					}}
				/>
			) : (
				<PostReadonlyModal isTriggered={false} />
			)}
		</>
	)
}
