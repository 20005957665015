import { useState } from 'react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation } from 'react-router-dom'
import { UserCog2 } from 'lucide-react'
import loadable from '@loadable/component'
import * as Icons from 'react-feather'
import * as LucideIcons from 'lucide-react'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { useCurrentMedia } from '@prostpost/css'
import { Sidebar, Layout } from '@prostpost/uikit'

import { config } from 'app/config'

import { useSignout } from 'app/domains/User/hooks'
import { MEDIA_URL } from 'app/domains/Media/constants'
import { useChannelsStore } from 'app/domains/Channel/store'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'
import { useUpdateUserPreferences } from 'app/domains/UserPreferences/hooks'
import { AddChannel } from 'app/domains/Channel/features/AddChannel'
import { useNewChannelAnalyticsEvents } from 'app/config/analytics/events/channel'

import { UserSettings } from 'app/modals/UserSettings'
import { UserFeedback } from 'app/modals/UserFeedback'

import { routesUrls, matchChannelRoute, useMatchRoute } from 'app/routes/urls'

type ModalTabs = 'account' | 'plan' | 'preferences'

type Props = {
	children: React.ReactElement | React.ReactElement[]
}

const isDev = import.meta.env.NODE_ENV === 'development'
const FakeLoadable = Promise.resolve(() => null)

const ChannelEditorRow = loadable(() => (isDev ? FakeLoadable : import('../../Channel/Editor/route')))
const ChannelOverviewRow = loadable(() => (isDev ? FakeLoadable : import('../../Channel/Overview/route')))
const ChannelSettingsRow = loadable(() => (isDev ? FakeLoadable : import('../../Channel/Settings/route')))

export const WithSidebar = observer(function WithSidebar(props: Props) {
	const MEDIA = useCurrentMedia()
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const channelEvents = useNewChannelAnalyticsEvents()

	const { t } = useTranslation()

	const channels = useChannelsStore()
	const user = useOnboardedUserStore()

	const { signOut } = useSignout({ redirectTo: routesUrls.auth.signin })
	const { mutate: mutatePreferences } = useUpdateUserPreferences()

	const isInboxRoute = useMatchRoute('inbox')
	const [openModalTab, setOpenModalTab] = useState<ModalTabs | undefined>()

	const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
	const [isAddChannelModalOpen, setIsAddChannelModalOpen] = useState(false)
	const addChannelModalToast = useToast({
		type: 'success',
		text: t('sidebar.context.addChannel.success', 'Channel was successfully added'),
	})

	if (!MEDIA) return null

	return (
		<Layout variant="internal">
			{(() => {
				switch (MEDIA) {
					case 'MOBILE':
						return props.children

					case 'TABLET':
					case 'DESKTOP_WIDE':
					case 'DESKTOP_NARROW':
						return (
							<>
								<Sidebar
									isOpen={user.preferences.more.sidebarIsOpen}
									onLogoClick={() =>
										!isInboxRoute && navigate(`${config.constants.DOMAIN}${routesUrls.inbox}`)
									}
									renderBottom={({ isOpen, onToggle }) => (
										<Sidebar.Content>
											<Sidebar.NavItem
												variant="iconed"
												isActive={false}
												title={t('sidebar.context.settings2', 'Account')}
												icon={UserCog2}
												onClick={() => setOpenModalTab('account')}
											/>
											<Sidebar.NavItem
												variant="iconed"
												isActive={false}
												title={t('sidebar.context.logout', 'Logout')}
												icon={Icons.LogOut}
												onClick={signOut}
											/>
											<Sidebar.Divider />
											<Sidebar.NavItem
												variant="iconed"
												isActive={false}
												title={t('sidebar.context.feedback', 'Feedback')}
												icon={LucideIcons.MessageCircle}
												onClick={() => setIsFeedbackModalOpen(true)}
											/>
											<Sidebar.NavItem
												variant="iconed"
												isActive={false}
												title={t('sidebar.context.collapse', 'Collapse')}
												tooltip={t('sidebar.context.expand', 'Expand')}
												icon={isOpen ? Icons.ChevronLeft : Icons.ChevronRight}
												onClick={() => {
													onToggle()
													mutatePreferences({ more: { sidebarIsOpen: !isOpen } })
												}}
											/>
										</Sidebar.Content>
									)}
								>
									<Sidebar.Content>
										<Sidebar.NavItem
											variant="iconed"
											isActive={isInboxRoute}
											title={t('sidebar.context.inbox', 'Inbox')}
											icon={Icons.Inbox}
											onClick={() => navigate(routesUrls.inbox)}
										/>
									</Sidebar.Content>
									<Sidebar.Divider />
									<Sidebar.Content mt={5}>
										<Sidebar.NavItem
											variant="iconed"
											isActive={false}
											title={t('sidebar.context.addChannel', 'Add channel')}
											icon={Icons.PlusCircle}
											onClick={() => {
												channelEvents.clicks.addChannel()
												setIsAddChannelModalOpen(true)
											}}
										/>
										{channels.activeList.map(channel => {
											const ava = channel.avaSmall || channel.avaBig
											const isSelectedChannel = matchChannelRoute('any', pathname, channel)
											const editorRoute = routesUrls.channel.getEditorRoute(channel)
											const settingsRoute = routesUrls.channel.getSettingsRoute(channel)
											const overviewRoute = routesUrls.channel.getOverviewRoute(channel)
											return (
												<Sidebar.NavItem
													variant="ava"
													key={channel.name}
													isActive={isSelectedChannel}
													isNested={isSelectedChannel}
													title={channel.title || channel.name}
													ava={ava ? MEDIA_URL(ava) : ''}
													onMouseOver={() => ChannelEditorRow.preload()}
													onClick={() => {
														runInAction(() => {
															navigate(editorRoute)
														})
													}}
												>
													<Sidebar.NavSubItem
														title="Editor"
														icon={Icons.File}
														isActive={pathname === editorRoute}
														onMouseOver={() => ChannelEditorRow.preload()}
														onClick={() => {
															runInAction(() => navigate(editorRoute))
														}}
													/>
													<Sidebar.NavSubItem
														title="Overview"
														icon={Icons.Activity}
														isActive={pathname === overviewRoute}
														onMouseOver={() => ChannelOverviewRow.preload()}
														onClick={() => {
															runInAction(() => navigate(overviewRoute))
														}}
													/>
													<Sidebar.NavSubItem
														title="Settings"
														icon={Icons.Settings}
														isActive={pathname === settingsRoute}
														onMouseOver={() => ChannelSettingsRow.preload()}
														onClick={() => {
															runInAction(() => navigate(settingsRoute))
														}}
													/>
												</Sidebar.NavItem>
											)
										})}
									</Sidebar.Content>

									<AddChannel
										size="mid"
										variant="modal"
										title="Add new channel"
										isOpen={isAddChannelModalOpen}
										submitButton={{ label: 'Add channel', icon: LucideIcons.Plus }}
										onMsg={msg => {
											switch (msg.type) {
												case 'on_add_channel_modal_close':
													setIsAddChannelModalOpen(false)
													break
												case 'on_new_channel_added':
													setIsAddChannelModalOpen(false)
													addChannelModalToast.show()
													switch (msg.channel.type) {
														case 'ACTIVE':
															navigate(routesUrls.channel.getEditorRoute(msg.channel))
															break
														case 'LOCKED':
															break
														default:
															notReachable(msg.channel)
													}
													break
												default:
													notReachable(msg)
											}
										}}
									/>

									<UserSettings
										nestedModal={null}
										isOpen={!!openModalTab}
										currentTab={openModalTab || 'plan'}
										onClose={() => setOpenModalTab(undefined)}
									/>

									<UserFeedback
										isOpen={isFeedbackModalOpen}
										onClose={() => setIsFeedbackModalOpen(false)}
										categories={['question', 'bug', 'review', 'feature_request']}
										defaultCategory="review"
									/>
								</Sidebar>

								{props.children}
							</>
						)
					default:
						return notReachable(MEDIA)
				}
			})()}
		</Layout>
	)
})
