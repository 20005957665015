import { useRef } from 'react'
import { Turnstile } from '@marsidev/react-turnstile'
import type { TurnstileInstance } from '@marsidev/react-turnstile'

import { config } from 'app/config'

type Props = {
	isVisible: boolean
	onTokenSet: (token: string) => void
}

export const TurnStileCaptcha = ({ isVisible, onTokenSet }: Props) => {
	const captchaRef = useRef<TurnstileInstance>()

	return (
		<Turnstile
			ref={captchaRef}
			options={{ refreshExpired: 'manual', size: isVisible ? 'normal' : 'invisible' }}
			siteKey={config.captcha.VITE_CF_CAPTCHA_SITE_KEY}
			onSuccess={onTokenSet}
			onExpire={() => captchaRef.current?.reset()}
		/>
	)
}
