import { useEffect } from 'react'
import { useTheme } from '@emotion/react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import styled from '@emotion/styled'

import { canUseDOM } from '@prostpost/utils'
import { useCurrentMedia, MEDIA } from '@prostpost/css'
import { ToastProvider } from '@prostpost/toast'
import { Layout, Fullscreen, Flex, Null, Box } from '@prostpost/uikit'
import { ImageCover } from '@prostpost/uikit-next'

import { config } from 'app/config'
import { Images } from 'app/resources'

import { useIsUserSignedIn } from 'app/domains/User/hooks'

import { routesUrls } from 'app/routes/urls'
import { Loadings } from 'app/routes/Loadings'

import { QUOTES } from '../constants'

const getRandomQuote = () => QUOTES[Math.floor(Math.random() * QUOTES.length)]

const QuoteText = styled.p`
	font-size: 14px;
	font-weight: 400;
	font-style: italic;
	line-height: 1.2;
	max-width: 380px;
	color: ${({ theme }) => theme.colors.blue_40};
`

const QuoteAuthor = styled.cite`
	font-size: 14px;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.blue_40};
`

export const WithAuth = () => {
	const theme = useTheme()
	const currentMedia = useCurrentMedia()
	const { t } = useTranslation()

	// User status
	const isUserSignedIn = useIsUserSignedIn()

	// Get path that we currently on to pass to a Header component
	// - in - Create new account (button)
	// - up - Log in (button)
	// const showSignupLink = !location.pathname.includes('/up')

	useEffect(() => {
		if (canUseDOM() && isUserSignedIn) {
			window.location.assign(routesUrls.inbox)
		}
	}, [isUserSignedIn])

	useEffect(() => {
		return () => {
			config.localStorage.removeItem('isSigningIn')
		}
	}, [])

	if (!currentMedia) return null

	return (
		<Layout variant="fullscreen">
			<Layout.Content fullWidth variant="fullscreen-split">
				<Fullscreen.Main variant="left" size="narrow">
					{config.localStorage.getItem('isSigningIn') ? (
						<Loadings.AlmostThere height="calc(100dvh - 200px)" />
					) : (
						<Flex align="center" just="center" h="100%" w="100%">
							<Outlet />
						</Flex>
					)}
				</Fullscreen.Main>
				{[MEDIA.MOBILE, MEDIA.TABLET].includes(currentMedia) ? (
					<Null />
				) : (
					<Box as="aside" w="50%" h="100%" style={{ borderLeft: `1px solid ${theme.colors.blue_20}` }}>
						<ImageCover
							src={Images.WiseMan}
							alt={t('content:signin.illustration.alt', 'Wise man writing a poem')}
						/>
						<Flex as="blockquote" dir="column" position="absolute" maxW="380px" top={6} right={6}>
							<QuoteText>{'"' + getRandomQuote().quote + '"'}</QuoteText>
							<Flex as="footer" w="100%" align="center" just="flex-end" mt={2}>
								<QuoteAuthor>{'— ' + getRandomQuote().author}</QuoteAuthor>
							</Flex>
						</Flex>
					</Box>
				)}
			</Layout.Content>
			<ToastProvider />
		</Layout>
	)
}
