import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import { parseFirstError } from '@prostpost/jsonapi'
import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import type { Category, QuestionCategory } from '..'

const knownErrors = [
	'UNABLE_TO_CREATE_FEEDBACK_MESSAGE',
	'REQUESTS_PER_HOUR_LIMIT_EXCEEDED',
	'EITHER_USER_OR_EMAIL_IS_REQUIRED',
] as const

type Data = {
	category: Category
	captcha?: string
	subCategory?: QuestionCategory
	message?: string
	rating?: number
	email?: string // if email is passed then user is not authorized
}

const createFeedbackMessage = ({ data }: { data: Data }): Promise<null> => {
	return axiosInstance
		.post<JsonApiResponseSingle>(data.email ? '/auth/feedback-public' : '/auth/feedback', data)
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(() => null)
}

export const useCreateFeedbackMessage = () => {
	return {
		useCreateFeedbackMutation: useMutation<null, ParsedError<(typeof knownErrors)[number]>, Data>(
			'feedback',
			(data: Data) => createFeedbackMessage({ data }),
		),
	}
}
