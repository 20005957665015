import { useMemo } from 'react'
import { removeSlashes } from 'slashes'
import removeMd from 'remove-markdown'

import { truncate } from '@prostpost/utils'

import type { DraftScheduled } from 'app/domains/Draft'
import type { FeedPost } from 'app/domains/Post'

const TEXT_LENGTH = 160

type Options = {
	trimMd?: boolean
}

export const usePostTextPreview = (post: DraftScheduled | FeedPost, options?: Options): string[] => {
	const initialTextContent = post.type === 'HISTORY' ? post.text : post.mdText
	const text = useMemo(() => {
		if (initialTextContent) {
			// Find and replace custom emojis
			const emojiRegex = /!?\[.*?\]\(tg:\/\/emoji\?id=(\d+)\)/g
			const postText = initialTextContent.replace(emojiRegex, (_, id) => `{{emoji::${id}}}`)

			// calculate length of extra characters due to parsed custom emojis
			// we replace it with [emoji::<id>] here because [](tg://...) will be removed by removeMd
			const replacedEmojiRegex = /\{{emoji::\d+\}}/g
			const emojiMatches = initialTextContent.match(replacedEmojiRegex)
			const customEmojisLength = emojiMatches
				? emojiMatches.reduce((totalLength, emoji) => totalLength + emoji.length, 0)
				: 0

			return truncate(
				options?.trimMd
					? removeSlashes(removeMd(postText, { stripListLeaders: false, useImgAltText: false }))
					: removeSlashes(postText),
				TEXT_LENGTH + customEmojisLength,
			)
				.replaceAll(/\n/g, ' ')
				.replaceAll('*', '') // sometimes there are leftovers from bold entity
				.replaceAll('||', '') // spoilers
				.trim()
		}
		return ''
	}, [initialTextContent])

	const emojiRegex = /\{{emoji::(\d+)\}}/g
	return text.split(emojiRegex)
}
