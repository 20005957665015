import { useQuery } from 'react-query'
import type { AxiosError } from 'axios'
import type { UseQueryOptions } from 'react-query'

import type { JsonApiErrorResponse, JsonApiResponseSingle, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError, parseSingle } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

import { ScheduledDraftsCountSchema } from './schemas'

const knownErrors = ['UNABLE_TO_COUNT_SCHEDULED_DRAFTS'] as const

type ResponseData = { count: number }

export const fetchScheduledDraftsCounter = (): Promise<ResponseData> =>
	axiosInstance
		.get<JsonApiResponseSingle>('/drafts/stats/total-scheduled')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => {
			const result = parseSingle(response.data)
			return ScheduledDraftsCountSchema.parse(result.data)
		})

export const useCountScheduledDrafts = (
	options: Omit<
		UseQueryOptions<ResponseData, ParsedError<(typeof knownErrors)[number]>, ResponseData>,
		'queryKey' | 'queryFn'
	> = {},
) => {
	return {
		scheduledDraftsCountQuery: useQuery<ResponseData, ParsedError<(typeof knownErrors)[number]>, ResponseData>(
			'stats-total-scheduled',
			fetchScheduledDraftsCounter,
			options,
		),
	}
}
