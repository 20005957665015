import { makeLoggable } from 'mobx-log'
import { createContext, useContext } from 'react'
import { observable, action, computed, makeObservable } from 'mobx'

import type { Post, FeedPost, PostHistory, PostInternal, PostInternalPublishing } from 'app/domains/Post'
import type { DraftScheduled } from 'app/domains/Draft'

type InitArgs = {
	monthlyPostsLimit: number
	monthlyPostsQuantity: number
}

export class StorePosts {
	declare posts: FeedPost[]

	/**
	 * Posts quantity that are allowed for a month
	 * to be scheduled/published within user's plan
	 */
	declare monthlyPostsLimit: number

	/**
	 * Posts quantity that were already
	 * was used (scheduled/published) in a month
	 */
	declare monthlyPostsQuantity: number

	/**
	 * Limit of posts per page
	 */
	declare POSTS_PER_PAGE: number

	/**
	 * Check if posts limit was reached
	 * @returns {boolean} If posts limit is reached
	 */
	get postsLimitReached(): boolean {
		return this.monthlyPostsLimit <= this.monthlyPostsQuantity
	}

	init(args: InitArgs) {
		this.posts = []
		this.monthlyPostsLimit = args.monthlyPostsLimit
		this.monthlyPostsQuantity = args.monthlyPostsQuantity
		this.POSTS_PER_PAGE = 20
	}

	constructor(args: InitArgs) {
		this.init(args)

		makeObservable(this, {
			posts: observable,
			monthlyPostsLimit: observable,
			monthlyPostsQuantity: observable,

			postsLimitReached: computed,

			init: action,
			addScheduledPost: action,
			removeScheduledPost: action,
			setPosts: action,
			setMonthlyPostsLimit: action,
			setMonthlyPostsQuantity: action,
			updatePublishedPost: action,
			updateScheduledPost: action,
			getPost: action,
			clearUnpublishedVersion: action,
			setUnpublishedVersion: action,
		})

		makeLoggable(this)
	}

	getPost(uuid: string) {
		return this.posts.find(post => post.uuid === uuid)
	}

	setPosts(posts: FeedPost[]): void {
		this.posts = posts
	}

	addScheduledPost(draft: DraftScheduled): void {
		if (this.posts.length) {
			const currentScheduledPosts = this.posts.filter(
				(post: FeedPost): post is DraftScheduled => post.type === 'SCHEDULED',
			)

			const { index, isLastElement } = this.getIndexToInsertScheduledPost(currentScheduledPosts, draft)

			// We don't want to add a post to the list if based on it's publishAt date it's outside of already loaded pages
			// 1. because we can't find the exact place
			// 2. we don't need to find it because this post still will be loaded from the BE when user scrolls and triggers next page loading
			if (
				// Check updatedScheduledPosts.length because we have to add at least one post to perform a check and it's always the right action
				currentScheduledPosts.length > 0 &&
				// check that our draft is about to be added at the end of scheduled drafts list (if not - no problem we just insert and have some already loaded posts after it)
				isLastElement &&
				// check that last loaded post was not the last post in scheduled posts list (before we pushed a new one to the end of scheduled posts list)
				this.posts[this.posts.length - 1].uuid === currentScheduledPosts[currentScheduledPosts.length - 1]?.uuid
			) {
				return
			}

			// concat feed back
			this.posts.splice(index, 0, draft)
		} else {
			this.setPosts([draft])
		}
	}

	addPublishingPost(post: PostInternalPublishing): void {
		if (this.posts.length) {
			const scheduledPosts = this.posts.filter((p: FeedPost): p is DraftScheduled => p.type === 'SCHEDULED')
			this.setPosts([
				...scheduledPosts,
				post,
				...this.posts.filter((post: FeedPost): post is Post => post.type !== 'SCHEDULED'),
			])
		} else {
			this.setPosts([post])
		}
	}

	addPublishedPost(post: PostHistory): void {
		if (this.posts.length) {
			const firstPublishedPost = this.posts.findIndex(post => post.type === 'INTERNAL' || post.type === 'HISTORY')
			if (firstPublishedPost === -1) this.setPosts([post, ...this.posts])
			else
				this.setPosts([
					...this.posts.slice(0, firstPublishedPost),
					post,
					...this.posts.slice(firstPublishedPost),
				])
		} else {
			this.setPosts([post])
		}
	}

	removeScheduledPost(uuid: string): void {
		this.setPosts(this.posts.filter(post => post.uuid !== uuid))
	}

	removePublishedPost(uuid: string): void {
		this.setPosts(this.posts.filter(post => post.uuid !== uuid))
	}

	setMonthlyPostsLimit(posts: number): void {
		this.monthlyPostsLimit = posts
	}

	setMonthlyPostsQuantity(action: 'set' | 'inc' | 'dec', posts?: number): void {
		switch (action) {
			case 'inc':
				this.monthlyPostsQuantity = this.monthlyPostsQuantity ? this.monthlyPostsQuantity + 1 : 1
				break
			case 'dec':
				this.monthlyPostsQuantity = this.monthlyPostsQuantity ? this.monthlyPostsQuantity - 1 : 0
				break
			default:
				this.monthlyPostsQuantity = posts || 1
				break
		}
	}

	updateScheduledPost(
		uuid: string,
		data: Partial<Omit<DraftScheduled, 'type' | 'createdAt' | 'uuid' | 'channelUuid'>>,
	): void {
		this.setPosts(this.posts.map(p => (p.uuid === uuid ? { ...p, ...data } : p)))
	}

	updatePublishedPost(
		uuid: string,
		data: Partial<Omit<PostInternal, 'type' | 'createdAt' | 'uuid' | 'channelUuid'>>,
	): void {
		this.setPosts(this.posts.map(p => (p.uuid === uuid ? { ...p, ...data } : p)))
	}

	updatePublishingPostOnPublish(uuid: string, data: PostInternal): void {
		this.setPosts(
			this.posts.map(p => {
				const result =
					p.uuid === uuid && p.type !== 'HISTORY' ? { ...p, ...data, type: 'INTERNAL' as const } : p
				return result
			}),
		)
	}

	setUpdatedPostPublishing(uuid: string): void {
		this.setPosts(
			this.posts.map(p => {
				const result =
					p.uuid === uuid && p.type === 'INTERNAL' ? { ...p, type: 'INTERNAL_PUBLISHING' as const } : p
				return result
			}),
		)
	}

	clearUnpublishedVersion(uuid: string): void {
		this.setPosts(this.posts.map(p => (p.uuid === uuid ? { ...p, unpublishedVersion: undefined } : p)))
	}

	setUnpublishedVersion(uuid: string, backupUuid: string): void {
		this.setPosts(this.posts.map(p => (p.uuid === uuid ? { ...p, unpublishedVersion: backupUuid } : p)))
	}

	private getIndexToInsertScheduledPost(feed: DraftScheduled[], element: DraftScheduled) {
		// first scheduled post
		if (feed.length === 0) {
			return {
				index: 0,
				isLastElement: true,
			}
		}

		// has one ore more scheduled posts
		// by default place to the end (first to publish)
		let indexToInsert = feed.length

		// iterate over existing scheduled posts
		for (const [index, post] of feed.entries()) {
			if (post.publishAt < element.publishAt) {
				indexToInsert = index
				break
			}
		}

		return {
			index: indexToInsert,
			isLastElement: indexToInsert === feed.length - 1,
		}
	}
}

export const PostsStoreContext = createContext<StorePosts | undefined>(undefined)

export const usePostsStore = () => {
	const store = useContext(PostsStoreContext)
	if (store === undefined) {
		throw Error('usePostsStore can be used only within PostsStoreContext')
	}
	return store
}
