import type { ComponentProps, FC } from 'react'

import { Flex, Box } from '@prostpost/uikit'
import { Typography } from '@prostpost/uikit-next'

import { Icons } from 'app/resources'

import type { Languages } from '../types'

export const LANGUAGES: Record<Languages, { title: string; icon: FC }> = {
	en: { title: 'English', icon: Icons.EnFlag },
	de: { title: 'Deutsch', icon: Icons.DeFlag },
}

type Props = ComponentProps<typeof Flex> & {
	lang: Languages
	onToggle: (lang: Languages) => void
}

export const LanguageToggle = ({ lang, onToggle, ...flexProps }: Props) => {
	const LangIcon = LANGUAGES[lang].icon
	return (
		<Flex cursor="pointer" align="center" w="85px" gap={2} onClick={() => onToggle(lang)} {...flexProps}>
			<Box w="18px" h="18px">
				<LangIcon />
			</Box>
			<Typography variant="text" size={14} color="common_black">
				{LANGUAGES[lang].title}
			</Typography>
		</Flex>
	)
}
