import { useEffect } from 'react'
import { observable, runInAction } from 'mobx'

import { getNow, datetime } from '@prostpost/utils'

import { config } from 'app/config'
import { useOnboardedUserStore } from 'app/domains/User/store/slices'

// we need a shared store here since we use useShowTopSticker is a lot of places
// to check if a sticker should be shown or not so local React state doesn't work here
const topStickerStore = observable({ isVisible: true })

export const useShowTopSticker = () => {
	const { preferences } = useOnboardedUserStore()
	const showUntilDate = config.localStorage.getItem('showTrialSticker')

	const onDismissSticker = () => {
		runInAction(() => {
			topStickerStore.isVisible = false
			const yesterday = datetime(getNow(false).subtract(1, 'day'), false).format('YYYY-MM-DD[T]HH:mm:ss')
			config.localStorage.setItem('showTrialSticker', yesterday)
		})
	}

	useEffect(() => {
		runInAction(() => {
			if (preferences.plan.name !== 'TRIAL') {
				topStickerStore.isVisible = false
				config.localStorage.removeItem('showTrialSticker')
			} else if (!showUntilDate) {
				config.localStorage.setItem(
					'showTrialSticker',
					preferences.nextSubscriptionPeriod.format('YYYY-MM-DD[T]HH:mm:ss'),
				)
			}
		})
	}, [preferences.plan.name])

	useEffect(() => {
		runInAction(() => {
			if (showUntilDate) {
				topStickerStore.isVisible = datetime(showUntilDate, false).isAfter(getNow(false))
			} else {
				topStickerStore.isVisible = false
			}
		})
	}, [showUntilDate])

	return {
		topSticker: topStickerStore,
		onDismissSticker,
	}
}
