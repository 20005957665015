import { useState } from 'react'
import { Observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'

import { Typography } from '@prostpost/uikit-next'
import { Modal, Button, VStack, Group, Flex, Tile, Loading } from '@prostpost/uikit'
import { notReachable } from '@prostpost/utils'

import { routesUrls } from 'app/routes/urls'

import { useSignout } from 'app/domains/User/hooks'
import { PricingPlans } from 'app/domains/PricingPlan/features/PricingPlans'
import { FeedbackForm } from 'app/domains/Support/features/FeedbackForm'
import { useCountScheduledDrafts } from 'app/domains/Draft/api'
import { useLimitsResetDate } from 'app/domains/UserPreferences/hooks'
import type { UserLimits } from 'app/domains/UserPreferences'

import { ReactModal } from 'app/modals'

import { Limits } from '../UserSettings/layout/Limits'

type Props = {
	userTrialLimits: UserLimits
}

const TrialExpiredLockContent = ({ userTrialLimits }: Props) => {
	const theme = useTheme()

	const { t } = useTranslation()
	const { signOut } = useSignout({ redirectTo: routesUrls.auth.signin })

	const [screen, setScreen] = useState<'trial_ended' | 'select_plan' | 'feedback'>('trial_ended')

	const limitsResetDate = useLimitsResetDate()
	const { scheduledDraftsCountQuery } = useCountScheduledDrafts()
	const lastSchedulingDate = limitsResetDate.add(3, 'days').format('MMMM DD')

	const onClickNeedHelp = () => setScreen('feedback')

	if (['idle', 'loading'].includes(scheduledDraftsCountQuery.status)) {
		return (
			<Modal variant="content" mobileScrollOffset={94}>
				<Modal.Content>
					<Flex w="100%" minH="443px" align="center" just="center">
						<Loading />
					</Flex>
				</Modal.Content>
			</Modal>
		)
	}

	return (
		<Modal variant="content" mobileScrollOffset={94}>
			<Modal.Content>
				{(() => {
					switch (screen) {
						case 'trial_ended':
							return (
								<Observer>
									{() => (
										<VStack space={4}>
											<Limits.Trial postsLimit={userTrialLimits.posts} />
											<Tile
												gap={1}
												mt={5}
												dir="column"
												borderRadius="mid"
												borderColor="blue_20"
												bg="blue_20_opaque"
											>
												<Flex gap={1} align="center">
													<Icons.TriangleAlert size={18} strokeWidth={2.5} color="#F33838" />
													<Typography variant="heading" color="error" size={4}>
														{t(
															'content:modals.trialBlock.header',
															'Your trial period is expired',
														)}
													</Typography>
												</Flex>
												<Typography variant="paragraph" color="gray_200" size={14}>
													{t(
														'content:modals.trialBlock.header',
														'Thank you for using Prostpost! Please upgrade your plan to continue delivering the best content to your audience.',
													)}
												</Typography>
											</Tile>

											{scheduledDraftsCountQuery.data && scheduledDraftsCountQuery.data.count ? (
												<Tile gap={1} dir="column" borderRadius="mid" bg="red_120">
													<Flex gap={1} align="center">
														<Icons.ClockAlert
															size={18}
															strokeWidth={2.5}
															color={theme.colors.white_100}
														/>
														<Typography variant="heading" color="common_white" size={4}>
															{t(
																'content:modals.trialBlock.scheduledPostsWarningTitle',
																'You have {{count}} scheduled posts',
																{ count: scheduledDraftsCountQuery.data.count },
															)}
														</Typography>
													</Flex>
													<Typography variant="paragraph" color="common_white" size={14}>
														{t(
															'content:modals.trialBlock.scheduledPostsWarningUnknown2',
															'Posts scheduled until {{lastSchedulingDate}} will be published as planned. Posts that are scheduled for publishing later will be canceled and converted to drafts. To cancel scheduled posts, remove our bot from your channel or contact us via email, or contact form.',
															{ lastSchedulingDate },
														)}
													</Typography>
												</Tile>
											) : null}

											<Flex w="full" just="space-between" align="center" mt={4}>
												<Button.Inline size="small" color="blue_40" onClick={onClickNeedHelp}>
													{t('content:modals.trialBlock.needHelp', 'Need help?')}
												</Button.Inline>
												<Button.Action
													size="mid"
													variant="primary"
													iconPosition="right"
													icon={
														<Icons.ArrowRight
															size={16}
															strokeWidth={2.5}
															color={theme.colors.black_80}
														/>
													}
													onClick={() => setScreen('select_plan')}
												>
													{t('content:modals.trialBlock.selectPlan', 'See our plans')}
												</Button.Action>
											</Flex>
										</VStack>
									)}
								</Observer>
							)
						case 'select_plan':
							return (
								<VStack space={6}>
									<Group>
										<Group.Title>{t('userSettings.plans.title', 'Plans')}</Group.Title>
										<PricingPlans variant="tiles-group" />
									</Group>
									<Flex w="full" just="center" align="center" gap={2}>
										<Button.Inline size="small" onClick={signOut}>
											{t('content:modals.trialBlock.signOut', 'Sign out')}
										</Button.Inline>
										<Button.Inline size="small" onClick={onClickNeedHelp}>
											{t('content:modals.trialBlock.needHelp', 'Need help?')}
										</Button.Inline>
									</Flex>
								</VStack>
							)
						case 'feedback':
							return (
								<VStack space={5}>
									<Typography variant="heading" color="common_black" size={3}>
										{t('content:modals.trialBlock.feedbackTitle', 'Contact us')}
									</Typography>
									<FeedbackForm
										isUserAuthorized
										defaultCategory="question"
										categories={['question']}
										secondaryAction={{
											icon: (
												<Icons.ArrowLeft
													size={16}
													strokeWidth={2.5}
													color={theme.colors.black_80}
												/>
											),
											label: t('content:modals.trialBlock.needHelpBack', 'Back to plans'),
											onClick: () => setScreen('select_plan'),
										}}
										onMsg={msg => {
											switch (msg.type) {
												case 'on_submit_feedback':
													setScreen('trial_ended')
													break
												default:
													notReachable(msg.type)
											}
										}}
									/>
								</VStack>
							)
						default:
							return notReachable(screen)
					}
				})()}
			</Modal.Content>
		</Modal>
	)
}

// we need it as a separate component, so we render "observer" in portal, and it doesn't exist in React DOM tree
// when a modal is not opened
export const TrialExpiredLock = ({ isOpen, ...props }: Props & { isOpen: boolean; onClose: () => void }) => {
	return (
		<ReactModal
			isOpen={isOpen}
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			onRequestClose={e => {
				e.stopPropagation()
				e.preventDefault()
				props.onClose()
			}}
		>
			<TrialExpiredLockContent {...props} />
		</ReactModal>
	)
}
