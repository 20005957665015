import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

import { useToast } from '@prostpost/toast'
import { notReachable } from '@prostpost/utils'
import { Paragraph, Modal, Heading } from '@prostpost/uikit'

import { useDeleteTemplate } from 'app/domains/Template/api'
import type { TemplateShortInfo } from 'app/domains/Template'

import { useCommonLocalizations } from 'app/shared/hooks'

import { ReactModal } from 'app/modals'

type Props = {
	isOpen: boolean
	template: TemplateShortInfo
	onClose: () => void
	onRemoved: (template: TemplateShortInfo) => void
}

const useToasts = () => {
	const { t } = useTranslation()

	const templateRemoved = useToast({
		type: 'success',
		text: t('content:templates.delete.success', 'Template was deleted'),
	})

	const templateRemoveFailed = useToast({
		type: 'error',
		text: t('content:templates.delete.error', 'Unable to delete a template'),
	})

	return { templateRemoveFailed, templateRemoved }
}

export const ConfirmationModal = observer(function ConfirmationModal({ template, isOpen, onRemoved, onClose }: Props) {
	const { t } = useTranslation()
	const { actionCancel } = useCommonLocalizations(t)
	const toasts = useToasts()

	const { deleteTemplateMutation } = useDeleteTemplate(template.uuid)
	const isLoading = deleteTemplateMutation.status === 'loading'

	useEffect(() => {
		switch (deleteTemplateMutation.status) {
			case 'idle':
			case 'loading':
				break
			case 'success':
				onRemoved(template)
				onClose()
				toasts.templateRemoved.show()
				break
			case 'error':
				console.error(deleteTemplateMutation.error)
				toasts.templateRemoveFailed.show()
				break
			default:
				notReachable(deleteTemplateMutation)
		}
	}, [deleteTemplateMutation.status])

	return (
		<ReactModal
			shouldCloseOnEsc
			shouldCloseOnOverlayClick
			isOpen={isOpen}
			onRequestClose={e => {
				e.preventDefault()
				e.stopPropagation()
				onClose()
			}}
		>
			<Modal variant="confirm">
				<Modal.Close onClick={onClose} />
				<Modal.Content>
					<Heading h={4}>{t('content:template.delete.title', 'Delete template')}</Heading>
					<Paragraph my={3} size={14} color="blue_40">
						{t(
							'content:template.delete.description',
							'This template will be permanently deleted. Are you sure you want to delete it?',
						)}
					</Paragraph>
				</Modal.Content>
				<Modal.ConfirmSecondaryAction onClick={onClose}>{actionCancel}</Modal.ConfirmSecondaryAction>
				<Modal.ConfirmPrimaryAction
					variant="danger"
					state={isLoading ? 'pending' : 'normal'}
					onClick={deleteTemplateMutation.mutate}
				>
					{t('content:template.delete.submit', 'Delete')}
				</Modal.ConfirmPrimaryAction>
			</Modal>
		</ReactModal>
	)
})
