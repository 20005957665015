import { useTranslation } from 'react-i18next'

import { Group } from '@prostpost/uikit'

import { PricingPlans } from 'app/domains/PricingPlan/features/PricingPlans'

export const PlansGroup = () => {
	const { t } = useTranslation()

	return (
		<Group>
			<Group.Title>{t('userSettings.plans.title', 'Plans')}</Group.Title>
			<PricingPlans variant="tiles-group" />
		</Group>
	)
}
