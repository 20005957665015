import { toJS, runInAction } from 'mobx'
import { EditorContent } from '@tiptap/react'

import { notReachable } from '@prostpost/utils'
import { Box, Flex, VStack } from '@prostpost/uikit'
import { useCurrentMedia } from '@prostpost/css'

import { usePredefinedEditor } from 'app/domains/Draft/hooks'
import { getHashtagSuggestions } from 'app/domains/Draft/features/DraftEditor/extensions'
import { LinkPreview } from 'app/domains/Draft/features/DraftEditor/features/LinkPreview'
import { ExternalLink } from 'app/domains/Draft/features/DraftEditor/features/ExternalLink'
import type { DraftContent, DraftScheduled } from 'app/domains/Draft'
import type { PostInternal } from 'app/domains/Post'

import { EditorGlobalStyles } from '../DraftEditor/styles'

import { Gallery } from './Gallery'

const MAX_EDITOR_CONTENT_WIDTH = '960px'

type Props = {
	draft: DraftScheduled | PostInternal
}

const convertDraftToContent = (draft: DraftScheduled | PostInternal): DraftContent => {
	const data = {
		images: draft.images,
		tags: draft.tags,
		ad: draft.ad,
		silent: draft.silent,
		text: draft.text,
		mdText: draft.mdText,
		webPage: draft.webPage,
		colorTag: draft.colorTag,
		externalLink: draft.externalLink,
	}

	switch (draft.type) {
		case 'SCHEDULED':
			return {
				...data,
				publishAt: draft.publishAt,
			}
		case 'INTERNAL':
			return data
		default:
			notReachable(draft)
	}
}

export const DraftEditorReadonly = ({ draft }: Props) => {
	const editor = usePredefinedEditor({ content: draft.text || '', recentTags: getHashtagSuggestions([]) })
	const content = runInAction(() => toJS(convertDraftToContent(draft)))

	const MEDIA = useCurrentMedia()
	const isMobile = MEDIA === 'MOBILE'

	return (
		<Flex dir="column" position="relative" just="space-between" h={isMobile ? 'calc(100dvh - 140px)' : 'auto'}>
			<Box position="absolute" w="100%" h="100%" top={0} left={0} zIndex={1} />
			{/* We should not wrap Editor in observer because it prevents it's re-renders that are causing bugs */}
			<Box w="100%" h="100%" position="relative" minH="300px" maxW={MAX_EDITOR_CONTENT_WIDTH}>
				<EditorGlobalStyles />
				<EditorContent editor={editor} />
			</Box>

			<VStack space={3} w="100%" maxW={MAX_EDITOR_CONTENT_WIDTH}>
				{content.images.length > 0 ? <Gallery images={toJS(content.images)} /> : null}
				{content.webPage ? <LinkPreview isRemovable={false} url={content.webPage} /> : null}
				{content.externalLink ? (
					<ExternalLink isRemovable={false} externalLink={toJS(content.externalLink)} />
				) : null}
			</VStack>
		</Flex>
	)
}
