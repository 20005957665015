import { toJS, runInAction } from 'mobx'

import { useOnboardedUserStore } from 'app/domains/User/store/slices'

type Return = {
	postsLimitUsed: number
	postsLimitTotal: number | null
	postsLimitReached: boolean
}

export const useUserPostsLimit = (): Return => {
	const user = useOnboardedUserStore()

	const { postsLimitUsed, postsLimitTotal } = runInAction(() => {
		const postsLimitUsed = user.preferences.limits.monthlyPublishedPosts
		const postsLimitTotal = user.preferences.limits.posts
		return toJS({ postsLimitUsed, postsLimitTotal })
	})

	return {
		postsLimitUsed,
		postsLimitReached: !!(postsLimitTotal && postsLimitUsed >= postsLimitTotal),
		postsLimitTotal: postsLimitTotal || 0,
	}
}
