import { useTranslation } from 'react-i18next'
import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'

import { Button, HStack, Skeleton } from '@prostpost/uikit'
import { notReachable } from '@prostpost/utils'

import { useCopyDraft } from 'app/domains/Draft/hooks'
import type { DraftNotScheduled } from 'app/domains/Draft'

type InternalMsg = { type: 'on_open_post_in_editor' } | { type: 'on_click_copy_as_draft'; draft: DraftNotScheduled }
type ScheduledMsg = { type: 'on_open_post_in_editor' } | { type: 'on_click_copy_as_draft'; draft: DraftNotScheduled }

type Props = {
	postUuid: string
	isPostLoaded: boolean
} & (
	| {
			postType: 'SCHEDULED'
			onMsg: (msg: ScheduledMsg) => void
	  }
	| {
			postType: 'INTERNAL'
			onMsg: (msg: InternalMsg) => void
	  }
)

const ActionsSkeleton = () => (
	<HStack space={2} align="center">
		<Skeleton width="82px" height={34} />
		<Skeleton width="142px" height={43} />
	</HStack>
)

const ActionsMain = ({ isPostLoaded, postUuid, ...props }: Props) => {
	const theme = useTheme()
	const { t } = useTranslation()

	const { copyDraft, isCreatingDraftCopy } = useCopyDraft({
		uuid: postUuid,
		onSuccess: draft => {
			if (isPostLoaded) {
				props.onMsg({
					type: 'on_click_copy_as_draft',
					draft,
				})
			}
		},
	})

	if (!isPostLoaded) {
		return <ActionsSkeleton />
	}

	switch (props.postType) {
		case 'INTERNAL':
		case 'SCHEDULED': {
			const { onMsg } = props
			return (
				<HStack space={2} align="center">
					<Button.Action
						size="small"
						variant="secondary"
						icon={<Icons.Pencil size={14} strokeWidth={3} color={theme.colors.black_80} />}
						onClick={() => {
							if (isPostLoaded) {
								onMsg({
									type: 'on_open_post_in_editor',
								})
							}
						}}
					>
						{t('content:post.editLabel', 'Edit')}
					</Button.Action>
					<Button.Action
						size="small"
						variant="secondary"
						state={isCreatingDraftCopy ? 'pending' : 'normal'}
						icon={<Icons.Copy size={14} strokeWidth={3} color={theme.colors.black_80} />}
						onClick={() => {
							if (isPostLoaded) {
								copyDraft()
							}
						}}
					>
						{t('content:post.copyDraftLabel', 'Copy as draft')}
					</Button.Action>
				</HStack>
			)
		}
		default:
			return notReachable(props)
	}
}

export const Actions = Object.assign(ActionsMain, { Skeleton: ActionsSkeleton })
