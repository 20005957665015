import { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useCurrentMedia } from '@prostpost/css'
import { Box, Button, Layout, Nav, Flex } from '@prostpost/uikit'

import { config } from 'app/config'

import '../styles.less'
import { routesUrls } from '../../urls'
import { WithFooter, WithAuthNav } from '../../Wrappers'

import { LanguageToggle } from '../components'
import type { Languages } from '../types'

export const WithPolicy = () => {
	const MEDIA = useCurrentMedia()
	const { t } = useTranslation()
	const [lang, setLang] = useState<Languages>('en')

	return (
		<Layout.WithCustomScroll>
			{() => (
				<Layout variant="article">
					<WithAuthNav
						routes={{
							signIn: routesUrls.auth.signin,
							signUp: routesUrls.auth.signup,
							redirectKnownUserTo: `${config.constants.DOMAIN}${routesUrls.inbox}`,
						}}
					>
						<WithFooter contactUsRouteUrl={routesUrls.contactUs} policyRouteUrl={routesUrls.policy.pp}>
							<Layout.Content variant="public" py={[8, 8, 8, 8]}>
								<Flex
									w="auto"
									align={MEDIA === 'MOBILE' || MEDIA === 'TABLET' ? 'start' : 'center'}
									dir={MEDIA === 'MOBILE' || MEDIA === 'TABLET' ? 'column' : 'row'}
									gap={4}
								>
									<LanguageToggle
										lang={lang}
										mb={MEDIA === 'MOBILE' || MEDIA === 'TABLET' ? 6 : 0}
										onToggle={l => (l === 'en' ? setLang('de') : setLang('en'))}
									/>
									<Nav mediaVerticalOnRes="not-desktop">
										<Nav.Item variant="nav">
											<Button.Nav
												to={routesUrls.policy.pp}
												title={t('pp.nav.pp', 'Privacy Policy')}
											/>
										</Nav.Item>
										<Nav.Item variant="nav">
											<Button.Nav
												to={routesUrls.policy.terms}
												title={t('pp.nav.ts', 'Terms of Service')}
											/>
										</Nav.Item>
										<Nav.Item variant="nav">
											<Button.Nav
												to={routesUrls.policy.cookie}
												title={t('pp.nav.cp', 'Cookie Policy')}
											/>
										</Nav.Item>
										<Nav.Item variant="nav">
											<Button.Nav
												to={routesUrls.policy.use}
												title={t('pp.nav.user', 'Use policy')}
											/>
										</Nav.Item>
										<Nav.Item variant="nav">
											<Button.Nav
												to={routesUrls.policy.refund}
												title={t('pp.nav.rp', 'Refund Policy')}
											/>
										</Nav.Item>
										<Nav.Item variant="nav">
											<Button.Nav
												to={routesUrls.policy.disclaimer}
												title={t('pp.nav.disc', 'Disclaimer')}
											/>
										</Nav.Item>
										<Nav.Item variant="nav">
											<Button.Nav
												to={routesUrls.policy.impressum}
												title={t('pp.nav.imprint', 'Imprint')}
											/>
										</Nav.Item>
									</Nav>
								</Flex>
							</Layout.Content>

							{/* Box with min height to keep Footer stick to the bottom if content takes less than full window height */}
							{/* where 350px is space taken by header and navigation */}
							<Box minH="calc(100dvh - 350px)">
								<Outlet context={{ lang }} />
							</Box>
						</WithFooter>
					</WithAuthNav>
				</Layout>
			)}
		</Layout.WithCustomScroll>
	)
}
