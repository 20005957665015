import camelcase from 'camelcase-keys'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useState } from 'react'
import type { SupabaseClient } from '@supabase/supabase-js'

type AccessTokenAppMetadata = {
	isPlanExpired?: boolean
}

type DecodedAccessToken = {
	app_metadata: AccessTokenAppMetadata
}

export const useUserMetadata = (auth: SupabaseClient['auth']) => {
	const [userMetadata, setUserMetadata] = useState<AccessTokenAppMetadata | undefined>()
	useEffect(() => {
		;(async () => {
			const { data } = await auth.getSession()
			const accessToken = data.session?.access_token
			const decodedAccessToken = accessToken && jwtDecode<DecodedAccessToken>(accessToken)
			if (decodedAccessToken) {
				setUserMetadata(camelcase(decodedAccessToken.app_metadata))
			}
		})()
	}, [])
	return userMetadata
}
