import { useEffect } from 'react'
import type { PropsWithChildren } from 'react'

import { useSignout, useUserLoader, useCannySSO } from 'app/domains/User/hooks'

import { config } from 'app/config'

import { routesUrls } from 'app/routes/urls'
import { Loadings } from 'app/routes/Loadings'

export const WithUserChecker = ({ children }: PropsWithChildren) => {
	const { signOut } = useSignout({ redirectTo: routesUrls.auth.signin })
	const { data: user, retriesLimitReached } = useUserLoader({ throwError: false })

	const isCannyChecked = useCannySSO(routesUrls.auth.signin, routesUrls.inbox)

	useEffect(() => {
		window.__dev__logout = signOut
		window.__dev__toggle_umami = enable => config.localStorage.setItem('umami.disabled', enable ? '1' : '0')
	}, [])

	useEffect(() => {
		if (retriesLimitReached) signOut()
	}, [retriesLimitReached])

	return user && isCannyChecked ? <>{children}</> : <Loadings.AlmostThere />
}
