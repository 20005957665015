import { useTheme } from '@emotion/react'
import * as Icons from 'lucide-react'
import type { LucideIcon } from 'lucide-react'

import { Box } from '@prostpost/uikit'

import type { FeedbackCategoryOption, FeedbackQuestionTypeOption } from './types'

const IconWrapper = ({ icon: Icon }: { icon: LucideIcon }) => {
	const theme = useTheme()
	return (
		<Box mt="1px">
			<Icon size={16} color={theme.colors.blue_40} />
		</Box>
	)
}

export const FEEDBACK_CATEGORIES: FeedbackCategoryOption[] = [
	{
		value: 'review',
		label: 'Feedback',
		prefix: <IconWrapper icon={Icons.MessageSquare} />,
	},
	{
		value: 'feature_request',
		label: 'Feature request',
		prefix: <IconWrapper icon={Icons.PackagePlus} />,
	},
	{
		value: 'bug',
		label: 'Bug',
		prefix: <IconWrapper icon={Icons.Bug} />,
	},
	{
		value: 'question',
		label: 'Question',
		prefix: <IconWrapper icon={Icons.CircleHelp} />,
	},
]

export const FEEDBACK_QUESTION_TYPES: FeedbackQuestionTypeOption[] = [
	{
		value: 'billing_and_payments',
		label: 'Billing and payments',
		prefix: <IconWrapper icon={Icons.CreditCard} />,
	},
	{
		value: 'technical_issue',
		label: 'Technical issue',
		prefix: <IconWrapper icon={Icons.Bug} />,
	},
	{
		value: 'data_and_security',
		label: 'Data and security',
		prefix: <IconWrapper icon={Icons.Shield} />,
	},
	{
		value: 'other',
		label: 'Other',
		prefix: <IconWrapper icon={Icons.MessageCircleMore} />,
	},
]
