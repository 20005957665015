import { useTranslation } from 'react-i18next'

import { HStack, Text, Loading } from '@prostpost/uikit'

import { useCommonLocalizations } from 'app/shared/hooks'

type Props = {
	height?: string
}

export const AlmostThere = ({ height = '100dvh' }: Props) => {
	const { t } = useTranslation()
	const { almostThere } = useCommonLocalizations(t)
	return (
		<HStack space={4} just="center" align="center" w="100%" h={height}>
			<Loading size="mid" />
			<Text size={16} weight={500} color="blue_40">
				{almostThere}
			</Text>
		</HStack>
	)
}
