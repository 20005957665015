import { useState } from 'react'
import { observer } from 'mobx-react-lite'

import { notReachable } from '@prostpost/utils'
import { Flex, HStack } from '@prostpost/uikit'

import type { PostHistory } from 'app/domains/Post'
import type { ChannelActive } from 'app/domains/Channel'

import {
	Header,
	Wrapper,
	Views,
	Forwards,
	SilentMark,
	Overlay,
	TextAndImages,
	Reactions,
	ForwardFrom,
} from '../../components'

import { Time } from './Time'
import { ContextMenu } from './ContextMenu'
import { Unsupported } from './Unsupported'

type Msg = { type: 'on_click_open_in_telegram' }

type Props = {
	post: PostHistory
	channel: ChannelActive
	timeFormat: '12h' | '24h'
	isDisabled: boolean
	isImageVisible: boolean
	channelsCount: number
	onMsg: (msg: Msg) => void
}

export const History = observer(function PostPreview({
	post,
	channel,
	timeFormat,
	channelsCount,
	isDisabled = false,
	isImageVisible = true,
	onMsg,
}: Props) {
	const [isContextOpen, setIsContextOpen] = useState(false)
	const showFooter = post.reactions || post.forwardFromTitle

	return (
		<Wrapper isSelectable={!isDisabled} isFailedToPublish={false} isSelected={false} isHover={isContextOpen}>
			{isDisabled ? <Overlay isLoading={false} /> : null}

			<Header channelsCount={channelsCount} channel={channel}>
				<Header.Time post={post} timeFormat={timeFormat}>
					{({ formattedTime }) => <Time time={formattedTime} />}
				</Header.Time>

				<Flex align="center" mr="auto" w="auto" ml={2} gap={2}>
					{post.silent ? <SilentMark /> : null}
					<Views views={post.views} />
					{post.forwards ? <Forwards forwards={post.forwards} /> : null}
				</Flex>

				<ContextMenu
					post={post}
					onMsg={msg => {
						switch (msg.type) {
							case 'on_click_open_in_telegram':
								onMsg(msg)
								break
							case 'on_context_menu_toggle':
								setIsContextOpen(msg.isOpen)
								break
							default:
								notReachable(msg)
						}
					}}
				/>
			</Header>

			{(() => {
				switch (post.meta?.type) {
					case 'text':
					case 'photo':
					case 'photo_with_caption':
					case 'gallery':
						return <TextAndImages post={post} isImageVisible={isImageVisible} />
					case 'poll':
						return <Unsupported.Poll meta={post.meta} />
					case 'audio':
						return <Unsupported.Audio meta={post.meta} />
					case 'video':
						return <Unsupported.Video meta={post.meta} />
					case 'voice':
						return <Unsupported.Voice meta={post.meta} />
					case 'document':
						return <Unsupported.Document meta={post.meta} />
					case 'location':
						return <Unsupported.Location meta={post.meta} />
					case 'sticker':
						return <Unsupported.Sticker meta={post.meta} />
					case 'video_note':
						return <Unsupported.VideoNote meta={post.meta} />
					case 'unknown':
						return <Unsupported.Unknown />
					default:
						return <TextAndImages post={post} isImageVisible={isImageVisible} />
				}
			})()}

			{/* Footer */}
			{showFooter ? (
				<HStack space={2} mt={2}>
					{post.forwardFromTitle ? <ForwardFrom title={post.forwardFromTitle} /> : null}
					{post.reactions ? <Reactions reactions={post.reactions} /> : null}
				</HStack>
			) : null}
		</Wrapper>
	)
})
