import localforage from 'localforage'
import { makeLoggable } from 'mobx-log'
import { toJS, observable, action, computed, makeObservable, runInAction } from 'mobx'

import { noop, constant, uuid, getNow, datetime, notReachable } from '@prostpost/utils'

import { postToDraft } from 'app/domains/Post/utils'
import type { Draft, DraftContent, DraftNotScheduled } from 'app/domains/Draft'
import type { Post, PostBackup, PostInternalPublishing } from 'app/domains/Post'

import { isUtcDateBeforeNow } from './utils'

// we need that key to use as React key for a list item to avoid jumping animation
// when on creation of a new draft we assign it a temporary local UUID and then switch it with the one from data source
export type DraftWithLocalEditorKey = DraftNotScheduled & {
	localKey: string
}

type ContentTimestamps = {
	createdAt: string
	updatedAt?: string
	publishAt?: string
}

type Options = {
	clearFromOfflineStorageAfterDays?: number
	onContentClean?: () => void
	onContentSync?: (uuid: string) => void
}

type DraftLoadingStatus = 'NO_DRAFT' | 'LOADING' | 'LOADED' | 'ERROR'

type OfflineStorageContent = Omit<DraftContent, 'externalLink' | 'publishAt'> &
	ContentTimestamps & {
		channelUuid: string | null
		localUuid: string | undefined
		isDraftBackup: boolean
		isSyncedWithDataSource: boolean
		externalLink: [string, string?] | undefined
	}

type InitArgs = {
	options?: Options
}

const defaultContentProps = {
	tags: [],
	images: [],
	ad: false,
	silent: false,
}

export class EditorStore {
	declare draftsCount: number
	declare draftUuid: string | undefined
	declare hasExternalDataSource: boolean
	declare drafts: DraftWithLocalEditorKey[]

	// DO NOT USE IT IN LOGIC !!! It's here just to share this variable between components for VISUAL EFFECTS ONLY
	// TODO: Move to state and remove from that store
	declare draftLoadingStatus: DraftLoadingStatus

	declare private isContentSyncedWithDataSource: boolean
	declare private content: (DraftContent & ContentTimestamps) | undefined
	declare private lastSyncedContentText: string | null | undefined
	declare private offlineStorage: typeof localforage
	declare private clearFromOfflineStorageAfterDays: number
	declare private onContentSync: (uuid: string) => void
	declare private onContentClean: () => void

	init({ options }: InitArgs) {
		this.draftUuid = undefined
		this.drafts = []
		this.draftsCount = 0

		this.content = undefined
		this.lastSyncedContentText = undefined
		this.draftLoadingStatus = 'NO_DRAFT'

		this.hasExternalDataSource = false
		this.isContentSyncedWithDataSource = true // private flag please use isLocalContentAheadOfDataSource in the app as it has some extra conditions

		this.clearFromOfflineStorageAfterDays = options?.clearFromOfflineStorageAfterDays || 3
		this.onContentSync = options?.onContentSync || noop
		this.onContentClean = options?.onContentClean || noop

		// setup offline storage
		this.offlineStorage = localforage.createInstance({
			version: 1.0,
			storeName: 'prostpost-editor',
			description: 'Offline storage to keep a queue of unsaved changes for Prostpost editor',
		})

		// clean up old items from storage
		this.cleanUpOfflineStorage()
	}

	constructor(args: InitArgs) {
		this.init(args)

		makeObservable<
			EditorStore,
			| 'draftUuid'
			| 'content'
			| 'drafts'
			| 'draftsCount'
			| 'draftLoadingStatus'
			| 'lastSyncedContentText'
			| 'isEmptyContent'
			| 'isContentTextSynced'
			| 'isContentSyncedWithDataSource'
			| 'addContentToOfflineStorage'
		>(this, {
			// Observables
			draftUuid: observable,
			drafts: observable,
			draftsCount: observable,
			content: observable.deep,
			draftLoadingStatus: observable,
			isContentSyncedWithDataSource: observable,
			lastSyncedContentText: observable,
			hasExternalDataSource: observable,

			// Computed
			isContentTextSynced: computed,
			isLocalContentAheadOfDataSource: computed,
			isEmptyContent: computed,

			// Actions
			init: action,
			setDrafts: action,
			setDraftsCount: action,
			setContentFromDraft: action,
			setDraftUuid: action,
			setDraftLoadingStatus: action,
			setSyncedWithRemoteStatus: action,
			setChannelUuid: action,
			clearDrafts: action,
			updateContent: action,
			contentSyncSuccess: action,
			resetContent: action,
			removeDraft: action,
			addNotScheduledDraft: action,
			addContentToOfflineStorage: action,
		})

		makeLoggable(this)
	}

	// ------------------------------------------------ COMPUTED -------------------------------------------------

	get isEmptyContent(): boolean {
		// consider a post with only external link as empty: !this.content?.externalLink
		return !this.content?.text && !this.content?.webPage && !this.content?.images?.length
	}

	get isLocalContentAheadOfDataSource(): boolean {
		return this.isContentTextSynced !== true || !this.isContentSyncedWithDataSource
	}

	private get isContentTextSynced(): string | true {
		// when this property is asked we return random string every time when content is not synced
		// so the external trigger can be run
		return this.compareTextContent(this.content?.text, this.lastSyncedContentText) || uuid(6)
	}

	// ------------------------------------------------- GETTERS -------------------------------------------------

	getContent(options?: { omitTimestamps?: boolean; omitInvalidPublishAt?: boolean }): DraftContent {
		let content: DraftContent & Partial<ContentTimestamps> = this.content || defaultContentProps

		// For posts failed to publish on schedule do not update publishAt field
		// otherwise BE will throw an error UNABLE_TO_SCHEDULE_DRAFT_IN_THE_PAST
		// That's a really edge case and must not happen but we should consider it anyway
		if (this.content?.publishAt && options?.omitInvalidPublishAt && isUtcDateBeforeNow(this.content.publishAt)) {
			const { publishAt: _, ...contentWithoutPublishAt } = content
			content = contentWithoutPublishAt
		}

		// Omit timestamps
		if (options?.omitTimestamps) {
			const { createdAt, updatedAt, ...contentWithoutTimestamps } = content
			content = contentWithoutTimestamps
		}

		return content
	}

	// ------------------------------------------------- SETTERS -------------------------------------------------

	setOptions(options: Options): void {
		if (options.onContentSync) {
			this.onContentSync = options.onContentSync
		}

		if (options.onContentClean) {
			this.onContentClean = options.onContentClean
		}
	}

	setDraftUuid(uuid: string): void {
		this.draftUuid = uuid
	}

	setChannelUuid(channelUuid: string | null, draftUuid: string): Promise<OfflineStorageContent | null> {
		return this.offlineStorage
			.getItem<OfflineStorageContent>(draftUuid)
			.then(data => {
				if (!data) throw Error('Draft data from offline storage is null')
				if (data.isDraftBackup) throw Error('Unable to change channel for scheduled/published post')
				return this.offlineStorage.setItem<OfflineStorageContent>(draftUuid, {
					...data,
					channelUuid,
					isSyncedWithDataSource: false,
				})
			})
			.catch(() => {
				console.error(`Unable to get a draft [${draftUuid}] from offline storage to update channel UUID`)
				return null
			})
	}

	setDraftLoadingStatus(status: DraftLoadingStatus): void {
		this.draftLoadingStatus = status
	}

	setContentFromDraft(draft: Draft | PostBackup): void {
		const content = this.convertDraftToContent(draft)
		const timestamps = this.extractTimestampsFromDraft(draft)

		const isBackupData = { isDraftBackup: false, uuid: draft.uuid }
		switch (draft.type) {
			case 'INTERNAL':
			case 'SCHEDULED':
				isBackupData.isDraftBackup = true
				break
			case 'NOT_SCHEDULED':
				isBackupData.isDraftBackup = false
				break
			default:
				notReachable(draft)
		}

		// we assume that Draft object IS an external data source
		// and it's a responsibility of the app to know if it was saved somewhere before
		this.hasExternalDataSource = true

		this.draftUuid = draft.uuid
		this.content = { ...content, ...timestamps }
		this.isContentSyncedWithDataSource = true
		this.lastSyncedContentText = content.text
		this.onContentSync(draft.uuid)

		// add to local storage
		void this.addContentToOfflineStorage({
			channelUuid: draft.channelUuid || null,
			content: { ...content, ...timestamps },
			localUuid: undefined,
			isSyncedWithDataSource: true,
			...isBackupData,
		})
	}

	setDrafts(drafts: DraftNotScheduled[]): void {
		this.drafts = drafts.map(draft => ({ ...draft, localKey: draft.uuid }))
	}

	setDraftsCount(count: number) {
		this.draftsCount = count
	}

	contentSyncSuccess({
		draft,
		...rest
	}:
		| { action: 'update'; draft: DraftNotScheduled | PostBackup }
		| { action: 'create'; draft: DraftNotScheduled; localUuid?: string }): void {
		this.isContentSyncedWithDataSource = true

		// update local uuid & sync flag with remote uuid for any draft inside an offline storage
		switch (rest.action) {
			case 'create': {
				if (!rest.localUuid) return
				this.setDraftLoadingStatus('LOADED')

				// update local id of a draft to remote uuid
				this.drafts = this.drafts.map(d => {
					return d.uuid === rest.localUuid ? { ...d, uuid: draft.uuid, localKey: rest.localUuid } : d
				})

				// update local storage
				void this.addContentToOfflineStorage({
					uuid: draft.uuid,
					isDraftBackup: false,
					// we pass local UUID on creation so we can find an existing record
					// in localforage by that UUID and update it with a remote one
					localUuid: rest.localUuid,
					channelUuid: draft.channelUuid || null,
					isSyncedWithDataSource: true,
					// do not update whole content because we want to
					// client side changes have a priority since if user made changes while
					// update/create request has been being made we will overwrite them in local storage
					// with the data from the server that is already outdated
					content: {
						createdAt: draft.createdAt,
						updatedAt: draft.updatedAt,
					},
				})
				break
			}
			case 'update': {
				let isDraftBackup = false
				switch (draft.type) {
					case 'INTERNAL':
					case 'SCHEDULED':
						isDraftBackup = true
						break
					case 'NOT_SCHEDULED':
						break
					default:
						notReachable(draft)
				}

				void this.addContentToOfflineStorage({
					uuid: draft.uuid,
					localUuid: undefined,
					channelUuid: draft.channelUuid || null,
					isSyncedWithDataSource: true,
					isDraftBackup,
					// same logic here as for 'create' action above
					content: {
						updatedAt: draft.updatedAt,
					},
				})
				break
			}
			default:
				notReachable(rest)
		}
	}

	syncContentToRemoteFromOfflineStorage(
		syncFn: (
			uuid: string,
			content: DraftContent,
			channelUuid?: string | null,
			localUuid?: string,
			isDraftBackup?: boolean,
		) => void,
	): void {
		this.offlineStorage
			.iterate<OfflineStorageContent, void>((record, uuid) => {
				if (!record.isSyncedWithDataSource) {
					const { publishAt, webPage, colorTag, externalLink, ...content } =
						this.extractContentFromOfflineStorageRecord(record)

					// set nulls for undefined values to clear on the server
					const nullableContent = {
						webPage: webPage || null,
						colorTag: colorTag || null,
						externalLink: externalLink?.url ? externalLink : null,
					}

					// do not send publishAt to BE if it's in the past to avoid an error
					if (publishAt && isUtcDateBeforeNow(publishAt)) {
						syncFn(
							uuid,
							{ ...content, ...nullableContent },
							record.channelUuid,
							record.localUuid,
							record.isDraftBackup,
						)
					} else {
						syncFn(
							uuid,
							{ publishAt, ...content, ...nullableContent },
							record.channelUuid,
							record.localUuid,
							record.isDraftBackup,
						)
					}
				}
			})
			.then(constant(null))
			.catch(e => {
				throw Error('Unable to iterate through offline storage to sync a queue', { cause: e })
			})
	}

	syncContentToRemoteFromOfflineStorageByUuid(
		uuid: string,
		syncFn: ({
			content,
			isBackup,
			isSyncedWithRemote,
			channelUuid,
			localUuid,
		}: {
			content: DraftContent
			isBackup: boolean
			isSyncedWithRemote: boolean
			channelUuid?: string | null
			localUuid?: string
		}) => Promise<DraftContent>,
	): Promise<DraftContent | null> {
		return this.offlineStorage
			.getItem<OfflineStorageContent>(uuid)
			.then(record => {
				if (!record) throw Error('No draft found in local store', { cause: uuid })
				return record
			})
			.then(record => {
				const { publishAt, webPage, colorTag, externalLink, ...content } =
					this.extractContentFromOfflineStorageRecord(record)

				// set nulls for undefined values to clear on the server
				const nullableContent = {
					webPage: webPage || null,
					colorTag: colorTag || null,
					externalLink: externalLink?.url ? externalLink : null,
				}

				// if already synced
				if (record.isSyncedWithDataSource) {
					return syncFn({
						isBackup: record.isDraftBackup,
						isSyncedWithRemote: true,
						channelUuid: record.channelUuid,
						localUuid: record.localUuid,
						content: { ...content, ...nullableContent, publishAt },
					})
				}

				// do not send publishAt to BE if it's in the past to avoid an error
				if (publishAt && isUtcDateBeforeNow(publishAt)) {
					return syncFn({
						isSyncedWithRemote: false,
						isBackup: record.isDraftBackup,
						channelUuid: record.channelUuid,
						localUuid: record.localUuid,
						content: { ...content, ...nullableContent },
					})
				} else {
					return syncFn({
						isSyncedWithRemote: false,
						isBackup: record.isDraftBackup,
						channelUuid: record.channelUuid,
						localUuid: record.localUuid,
						content: { publishAt, ...content, ...nullableContent },
					})
				}
			})
			.catch(e => {
				console.error(e)
				throw Error(`Unable to get a draft from local store [UUID: ${uuid}]`, { cause: e })
			})
	}

	setSyncedWithRemoteStatus(uuid: string, isSynced: boolean): Promise<OfflineStorageContent> {
		return this.offlineStorage.getItem<OfflineStorageContent>(uuid).then(record => {
			if (!record) throw Error('Draft data from offline storage is null')
			return this.offlineStorage.setItem<OfflineStorageContent>(uuid, {
				...record,
				isSyncedWithDataSource: isSynced,
			})
		})
	}

	getDraftFromOfflineStorageIfNoRemote({
		uuid,
		onMsg,
	}: {
		uuid: string
		onMsg: (msg: { type: 'on_error' } | { type: 'on_success'; draft: Draft } | { type: 'on_has_remote' }) => void
	}) {
		this.setDraftLoadingStatus('LOADING')

		// check if remote copy of a draft has been already saved
		this.getDraftFromOfflineStorage(uuid)
			.then(result => {
				if (!result) return null
				const { draft: draftFromOfflineStorage, hasRemoteCopy } = result
				if (!hasRemoteCopy) {
					return draftFromOfflineStorage
				} else {
					return null
				}
			})
			.then(draftFromOfflineStorage => {
				// populate from offline storage if there is no remote copy (meaning a draft still has localUuid)
				if (draftFromOfflineStorage) {
					onMsg({ type: 'on_success', draft: draftFromOfflineStorage })
					return null
				}

				// if there is no offline copy or that copy has been synced to remote - load remote copy
				return onMsg({ type: 'on_has_remote' })
			})
			.catch(e => {
				console.error(e)
				this.setDraftLoadingStatus('ERROR')
				this.resetContent()
				onMsg({ type: 'on_error' })
			})
	}

	clearDrafts() {
		this.setDrafts([])
		this.setDraftsCount(0)
	}

	addNotScheduledDraft(post: Post | PostInternalPublishing | Draft): DraftNotScheduled | undefined {
		this.setDraftsCount(this.draftsCount + 1)
		switch (post.type) {
			case 'INTERNAL': {
				const draft = { ...postToDraft(post), publishAt: undefined }
				this.setDrafts([draft, ...this.drafts])
				return draft
			}
			case 'NOT_SCHEDULED': {
				const draft = { ...post, publishAt: undefined }
				this.setDrafts([draft, ...this.drafts])
				return draft
			}
			case 'SCHEDULED':
			case 'INTERNAL_PUBLISHING': {
				const draft = { ...post, publishAt: undefined, type: 'NOT_SCHEDULED' as const }
				this.setDrafts([draft, ...this.drafts])
				return draft
			}
			case 'HISTORY':
				console.error('Unable to add a draft from history post')
				return undefined
			default:
				notReachable(post)
		}
	}

	removeDraft(uuid: string): DraftNotScheduled {
		const draft = this.drafts.find(draft => draft.uuid === uuid)
		if (!draft) throw Error('Unable to find draft to remove by UUID')

		this.drafts = this.drafts.filter(draft => draft.uuid !== uuid)
		this.setDraftsCount(this.draftsCount - 1)
		this.resetContent()
		this.removeFromOfflineStorage(uuid)
		return draft
	}

	resetContent(): void {
		this.draftUuid = undefined
		this.content = undefined
		this.lastSyncedContentText = undefined
		this.isContentSyncedWithDataSource = true
		this.hasExternalDataSource = false
		this.onContentClean()
	}

	cleanUpOfflineStorage(): void {
		this.offlineStorage
			.iterate<OfflineStorageContent, void>((record, uuid) => {
				if (
					(record.isSyncedWithDataSource || record.isDraftBackup) &&
					record.updatedAt &&
					datetime(record.updatedAt, false).diff(getNow(false), 'days') >
						this.clearFromOfflineStorageAfterDays
				) {
					this.removeFromOfflineStorage(uuid)
				}
			})
			.then(constant(null))
			.catch(e => {
				console.error('Unable to remove some drafts from local forage', e)
			})
	}

	removeFromOfflineStorage(uuid: string) {
		this.offlineStorage
			.removeItem(uuid)
			.then(constant(null))
			.catch(e => {
				console.error(`Unable to remove draft [${uuid}] from local forage`, e)
			})
	}

	updateContent(
		content: Partial<DraftContent>,
		channelUuid: string | null,
	): Promise<OfflineStorageContent | undefined> {
		const extraContent: Partial<DraftContent> = {}

		// Check if any of the properties have been actually assigned a new value
		let contentChanged = false
		if ('text' in content && !this.compareTextContent(this.content?.text, content.text)) {
			contentChanged = true
			extraContent.tags = this.parseTagsFromText(content.text || '')
		} else {
			let property: keyof DraftContent
			for (property in content) {
				if (property === 'text') continue // we've already checked text above
				if (Object.hasOwnProperty.call(content, property) && content[property] !== this.content?.[property]) {
					contentChanged = true
					break
				}
			}
		}

		// No actual changes have been made
		if (!contentChanged) return Promise.resolve(undefined)

		const timestamps = {
			updatedAt: getNow(true, { to: 'utc' }),
			createdAt: this.content ? this.content.createdAt : getNow(true, { to: 'utc' }),
		}

		// Update content with changes
		this.isContentSyncedWithDataSource = false
		this.content = {
			...defaultContentProps,
			...this.content,
			...content,
			...extraContent,
			...timestamps,
		}

		if (!this.draftUuid) {
			const content = this.content
			const tempUUID = uuid(4)
			this.setDraftUuid(tempUUID)
			this.setDraftsCount(this.draftsCount + 1)

			const draftContentToAdd = {
				...content,
				// convert null values to undefined
				text: content.text || undefined,
				mdText: content.mdText || undefined,
				webPage: content.webPage || undefined,
				externalLink: content.externalLink || undefined,
				colorTag: content.colorTag || undefined,
			}

			this.drafts = [
				{
					...timestamps,
					...draftContentToAdd,
					type: 'NOT_SCHEDULED',
					uuid: tempUUID,
					localKey: tempUUID,
					channelUuid,
				},
				...this.drafts,
			]

			// update offline storage
			return this.addContentToOfflineStorage({
				content: draftContentToAdd,
				uuid: undefined,
				localUuid: tempUUID,
				isDraftBackup: false,
				isSyncedWithDataSource: false,
				channelUuid,
			})
		} else {
			const content = this.content
			const dataSourceUuid = this.draftUuid
			this.drafts = this.drafts.map(draft => {
				return draft.uuid === dataSourceUuid
					? {
							...draft,
							...content,
							uuid: dataSourceUuid,
							type: 'NOT_SCHEDULED',
							channelUuid,
							// convert null values to undefined
							text: content.text || undefined,
							mdText: content.mdText || undefined,
							webPage: content.webPage || undefined,
							externalLink: content.externalLink || undefined,
							colorTag: content.colorTag || undefined,
						}
					: draft
			})

			// update offline storage
			if (this.hasExternalDataSource) {
				return this.addContentToOfflineStorage({
					content,
					uuid: dataSourceUuid,
					localUuid: undefined,
					isSyncedWithDataSource: false,
					channelUuid,
				})
				// it can be the case when we quickly create a new draft with some content property and then updated
				// this draft's content again before it was synced to the BE and we received a remote UUID
				// in this case we want to keep working with localUUID until this draft will be created remotely
				// and we receive it's remote uuid back to update
			} else {
				return this.addContentToOfflineStorage({
					content,
					uuid: undefined,
					localUuid: dataSourceUuid,
					isDraftBackup: false,
					isSyncedWithDataSource: false,
					channelUuid,
				})
			}
		}
	}

	// ------------------------------------------------- UTILS -------------------------------------------------

	private getDraftFromOfflineStorage(uuid: string): Promise<{ draft: Draft; hasRemoteCopy: boolean } | null> {
		return this.offlineStorage
			.getItem<OfflineStorageContent>(uuid)
			.then(data => {
				if (!data) return null
				const {
					localUuid,
					text,
					webPage,
					colorTag,
					externalLink,
					isDraftBackup,
					publishAt,
					...offlineContent
				} = data

				if (isDraftBackup && publishAt && !offlineContent.channelUuid) {
					throw Error('Scheduled draft does not have assigned channel!')
				}

				const draft: Draft =
					isDraftBackup && publishAt && offlineContent.channelUuid
						? {
								...offlineContent,
								uuid,
								text: text || undefined,
								webPage: webPage || undefined,
								colorTag: colorTag || undefined,
								...(externalLink
									? { externalLink: { url: externalLink[0], title: externalLink[1] } }
									: {}),
								type: 'SCHEDULED' as const,
								publishAt,
								channelUuid: offlineContent.channelUuid,
							}
						: {
								...offlineContent,
								uuid,
								text: text || undefined,
								webPage: webPage || undefined,
								colorTag: colorTag || undefined,
								...(externalLink
									? { externalLink: { url: externalLink[0], title: externalLink[1] } }
									: {}),
								type: 'NOT_SCHEDULED' as const,
								publishAt,
								channelUuid: offlineContent.channelUuid,
							}

				return {
					hasRemoteCopy: !localUuid,
					draft,
				}
			})
			.catch(e => {
				console.error('Unable to get draft from local forage', e)
				return null
			})
	}

	private addContentToOfflineStorage({
		uuid,
		localUuid,
		isSyncedWithDataSource,
		channelUuid,
		isDraftBackup,
		...rest
	}: {
		channelUuid: string | null
		isDraftBackup?: boolean
		isSyncedWithDataSource: boolean
		content: undefined | Partial<DraftContent & ContentTimestamps>
	} & (
		| { uuid: string; localUuid: string }
		| { uuid: undefined; localUuid: string }
		| { uuid: string; localUuid: undefined }
	)): Promise<OfflineStorageContent | undefined> {
		if (!uuid && !localUuid) return Promise.resolve(undefined)
		const uuidToGetRecord = (localUuid || uuid) as string
		const uuidToSetRecord = (uuid || localUuid) as string

		// check if we already have it in a storage
		return (
			this.offlineStorage
				.getItem<OfflineStorageContent>(uuidToGetRecord)
				.then(data => {
					return runInAction(() => {
						// incoming content is newer than content in a store
						if (
							!data ||
							!data.updatedAt ||
							!rest.content?.updatedAt ||
							// we have to check for the "same" here otherwise we can skip an update
							datetime(data.updatedAt, false).isSameOrBefore(datetime(rest.content.updatedAt, false))
						) {
							// use can clean externalLink so here we should check for a key instead of a value
							const externalLinkShouldBeUpdated = Object.prototype.hasOwnProperty.call(
								rest.content,
								'externalLink',
							)

							const { url, title } = rest.content?.externalLink || {}
							return {
								...defaultContentProps,
								...data,
								...rest.content,
								isDraftBackup: isDraftBackup != null ? isDraftBackup : data?.isDraftBackup || false,
								isSyncedWithDataSource,
								localUuid: uuid && localUuid ? undefined : localUuid,
								externalLink: externalLinkShouldBeUpdated
									? url || title
										? ([url, title] as [string, string?])
										: undefined
									: data?.externalLink,
							}
						}

						// content in a store is newer than incoming content
						return undefined
					})
				})
				// update content in a storage
				.then(contentToSaveToStorage => {
					return runInAction(() => {
						const contentToSave = toJS(contentToSaveToStorage)
						return contentToSave
							? this.offlineStorage.setItem<OfflineStorageContent>(uuidToSetRecord, {
									...contentToSave,
									channelUuid,
									tags: toJS(contentToSave.tags),
									images: toJS(contentToSave.images),
									createdAt: contentToSave.createdAt || getNow(true, { to: 'utc' }),
									updatedAt: contentToSave.updatedAt || getNow(true, { to: 'utc' }),
									// we need "null"s to clear values on BE
									webPage: contentToSave.webPage,
									externalLink: contentToSave.externalLink,
								})
							: undefined
					})
				})
				// remove local version if local uuid was updated with the remote one
				.then(content => {
					return runInAction(() => {
						if (uuid && localUuid) {
							// if this draft is open in Editor
							if (uuidToGetRecord === this.draftUuid) {
								this.setDraftUuid(uuid)
								this.isContentSyncedWithDataSource = true
								this.hasExternalDataSource = true
								this.onContentSync(uuid)
							}

							this.offlineStorage.removeItem(localUuid)
							this.drafts = this.drafts.map(draft => {
								return draft.uuid === localUuid ? { ...draft, uuid } : draft
							})
						}
						return content
					})
				})
				.catch(e => {
					console.error(e)
					throw Error('Unable to check draft content in the offline storage', { cause: e })
				})
		)
	}

	private parseTagsFromText(text: string): string[] {
		const parser = new DOMParser()
		const doc = parser.parseFromString(text, 'text/html')
		const tagsWithPossibleDuplicates = Array.from(doc.querySelectorAll('span[data-type="tags"]'))
			.map(span => (span.textContent ? span.textContent : ''))
			.map(tag => (tag === '#' ? '' : tag))
			.filter(Boolean)
		return [...new Set(tagsWithPossibleDuplicates)]
	}

	private convertDraftToContent(draft: Draft | PostBackup): DraftContent {
		const data = {
			images: draft.images,
			tags: draft.tags,
			ad: draft.ad,
			silent: draft.silent,
			text: draft.text,
			mdText: draft.mdText,
			webPage: draft.webPage,
			colorTag: draft.colorTag,
			externalLink: draft.externalLink,
		}

		switch (draft.type) {
			case 'SCHEDULED':
			case 'NOT_SCHEDULED':
				return {
					...data,
					publishAt: draft.publishAt,
				}
			case 'INTERNAL':
				return data
			default:
				notReachable(draft)
		}
	}

	private extractTimestampsFromDraft(draft: Draft | PostBackup): ContentTimestamps {
		switch (draft.type) {
			case 'INTERNAL':
			case 'SCHEDULED':
				return {
					createdAt: draft.createdAt,
					updatedAt: draft.updatedAt,
					publishAt: draft.publishAt,
				}
			case 'NOT_SCHEDULED':
				return {
					createdAt: draft.createdAt,
					updatedAt: draft.updatedAt || undefined,
				}
			default:
				return notReachable(draft)
		}
	}

	private extractContentFromOfflineStorageRecord(record: OfflineStorageContent): DraftContent {
		const { localUuid, createdAt, updatedAt, channelUuid, isSyncedWithDataSource, externalLink, ...content } =
			record
		const externalLinkObject = externalLink ? { url: externalLink[0], title: externalLink[1] } : undefined
		return {
			...content,
			externalLink: externalLinkObject,
		}
	}

	private compareTextContent(current: string | null | undefined, previous: string | null | undefined): boolean {
		return (
			// same characters
			current == previous ||
			// empty text: both can be different in a moment that will trigger false actions
			// when actually data and UI is the same, so here we check for all falsy values at once for both
			// e.g. null, undefined and ''
			(!current && !previous)
		)
	}
}
