import { useState } from 'react'
import { runInAction } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useMatch } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Settings, BarChart2, File, UserCog2 } from 'lucide-react'
import * as LucideIcons from 'lucide-react'
import * as Icons from 'react-feather'

import { useToast } from '@prostpost/toast'
import { useCurrentMedia } from '@prostpost/css'
import { notReachable, truncate } from '@prostpost/utils'
import { HStack, Button, Heading, ContextMenu, Null } from '@prostpost/uikit'

import { routesUrls } from 'app/routes/urls'
import { HEADER_HEIGHT } from 'app/shared/constants'
import { useChannelsStore } from 'app/domains/Channel/store'
import { AddChannel } from 'app/domains/Channel/features/AddChannel'
import type { EditorStore } from 'app/domains/Draft/store'
import type { ChannelActive } from 'app/domains/Channel'

import { Search } from '../Search'
import { Templates } from '../Templates'
import type { PostState } from '../../context'

type Msg =
	| { type: 'on_toggle_feed' }
	| { type: 'on_toggle_drafts' }
	| { type: 'on_click_new_draft' }
	| { type: 'on_drafts_search_changed'; search: string }
	| { type: 'on_click_user_settings' }
	| { type: 'on_click_inbox' }
	| { type: 'on_click_channel_editor' }
	| { type: 'on_click_channel_settings' }
	| { type: 'on_click_channel_overview' }

type PropsMobile = {
	isFeedOpen: boolean
	isDraftsOpen: boolean
	isEditorOpen: boolean
	channel: ChannelActive
	postState: PostState | undefined
	onMsg: (msg: Msg) => void
}

type PropsNotMobile = {
	isFeedOpen: boolean
	isEditorOpen: boolean
	channel: ChannelActive
	editorStore: EditorStore
	resetEditorState: (content: string) => void
	onMsg: (msg: Msg) => void
}

const MobileChannelsList = () => {
	const navigate = useNavigate()
	const channelStore = useChannelsStore()
	return channelStore.activeList
		.filter(ch => ch.uuid !== channelStore.current?.uuid)
		.map(channel => (
			<ContextMenu.Item
				key={channel.name}
				onClick={() => {
					runInAction(() => {
						navigate(routesUrls.channel.getEditorRoute(channel))
					})
				}}
			>
				{channel.title || channel.name}
			</ContextMenu.Item>
		))
}

const Mobile = observer(({ isFeedOpen, isDraftsOpen, isEditorOpen, postState, channel, onMsg }: PropsMobile) => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const isInboxRoute = !!useMatch(routesUrls.inbox)
	const isEditorRoute = !!useMatch(routesUrls.channel.getEditorRoute(channel))
	const isSettingsRoute = !!useMatch(routesUrls.channel.getSettingsRoute(channel))
	const isOverviewRoute = !!useMatch(routesUrls.channel.getOverviewRoute(channel))

	const channelStore = useChannelsStore()
	const [isAddChannelModalOpen, setIsAddChannelModalOpen] = useState(false)
	const addChannelModalToast = useToast({
		type: 'success',
		text: t('sidebar.context.addChannel.success', 'Channel was successfully added'),
	})

	return (
		<HStack space={2} align="center" just="space-between" w="100%" px={4} h={`${HEADER_HEIGHT}px`}>
			<HStack space={2}>
				<ContextMenu
					// position doesn't matter for mobile but it's a required prop
					placement="bottom-start"
					use={({ onClick }) => (
						<Button.Multi icon={{ Icon: Icons.Menu, position: 'left' }} onClick={onClick} />
					)}
				>
					<ContextMenu.Item
						icon={File}
						hasBadge={isEditorRoute}
						onClick={() => onMsg({ type: 'on_click_channel_editor' })}
					>
						{t('sidebar.context.channelEditor', 'Editor')}
					</ContextMenu.Item>
					<ContextMenu.Item
						icon={Settings}
						hasBadge={isSettingsRoute}
						onClick={() => onMsg({ type: 'on_click_channel_settings' })}
					>
						{t('sidebar.context.channelSettings', 'Settings')}
					</ContextMenu.Item>
					<ContextMenu.Item
						icon={BarChart2}
						hasBadge={isOverviewRoute}
						onClick={() => onMsg({ type: 'on_click_channel_overview' })}
					>
						{t('sidebar.context.channelOverview', 'Overview')}
					</ContextMenu.Item>

					<ContextMenu.Divider />

					<ContextMenu.Item
						icon={Icons.Inbox}
						hasBadge={isInboxRoute}
						onClick={() => onMsg({ type: 'on_click_inbox' })}
					>
						{t('sidebar.context.inbox', 'Inbox')}
					</ContextMenu.Item>
					<ContextMenu.Item icon={Icons.PlusCircle} onClick={() => setIsAddChannelModalOpen(true)}>
						{t('content:mobile.nav.addChannel', 'Add channel')}
					</ContextMenu.Item>
					<ContextMenu.Item icon={UserCog2} onClick={() => onMsg({ type: 'on_click_user_settings' })}>
						{t('sidebar.context.userSettings', 'Preferences')}
					</ContextMenu.Item>

					{channelStore.activeList.length > 1 ? <ContextMenu.Divider /> : <Null />}
					<MobileChannelsList />
				</ContextMenu>
				{(() => {
					if (!isEditorOpen) return null

					if (!postState) {
						return (
							<Button.Multi
								state={isDraftsOpen ? 'on' : 'off'}
								bgs={{ off: 'white_100', on: 'blue_10', disabled: 'blue_10' }}
								icon={{ Icon: Icons.Sidebar, position: 'left' }}
								onClick={() => onMsg({ type: 'on_toggle_drafts' })}
							>
								{t('content:channel.header.drafts', 'Drafts')}
							</Button.Multi>
						)
					}

					switch (postState.state) {
						case 'normal':
							return (
								<Button.Multi
									state={isDraftsOpen ? 'on' : 'off'}
									bgs={{ off: 'white_100', on: 'blue_10', disabled: 'blue_10' }}
									icon={{ Icon: Icons.Sidebar, position: 'left' }}
									onClick={() => onMsg({ type: 'on_toggle_drafts' })}
								>
									{t('content:channel.header.drafts', 'Drafts')}
								</Button.Multi>
							)
						case 'loading':
							return (
								<Button.Multi
									state="disabled"
									bgs={{ off: 'white_100', on: 'blue_10', disabled: 'blue_10' }}
									icon={{ Icon: Icons.Sidebar, position: 'left' }}
								>
									{t('content:channel.header.drafts', 'Drafts')}
								</Button.Multi>
							)
						case 'edit':
						case 'edit-existing-backup':
							return null
						default:
							notReachable(postState)
					}
				})()}
			</HStack>
			<HStack space={2} position="static">
				<Button.Multi onClick={() => onMsg({ type: 'on_click_new_draft' })}>
					{t('content:channel.header.new', 'New')}
				</Button.Multi>
				<Button.Multi
					state={isFeedOpen ? 'on' : 'off'}
					bgs={{ off: 'white_100', on: 'blue_10', disabled: 'blue_10' }}
					icon={{ Icon: Icons.MessageSquare, position: 'left' }}
					onClick={() => onMsg({ type: 'on_toggle_feed' })}
				>
					{t('content:channel.header.feed', 'Feed')}
				</Button.Multi>
			</HStack>

			<AddChannel
				size="mid"
				variant="modal"
				title="Add new channel"
				isOpen={isAddChannelModalOpen}
				submitButton={{ label: 'Add channel', icon: LucideIcons.Plus }}
				onMsg={msg => {
					switch (msg.type) {
						case 'on_add_channel_modal_close':
							setIsAddChannelModalOpen(false)
							break
						case 'on_new_channel_added':
							setIsAddChannelModalOpen(false)
							addChannelModalToast.show()
							switch (msg.channel.type) {
								case 'ACTIVE':
									navigate(routesUrls.channel.getEditorRoute(msg.channel))
									break
								case 'LOCKED':
									break
								default:
									notReachable(msg.channel)
							}
							break
						default:
							notReachable(msg)
					}
				}}
			/>
		</HStack>
	)
})

const NotMobile = observer(
	({ channel, isFeedOpen, isEditorOpen, editorStore, resetEditorState, onMsg }: PropsNotMobile) => {
		const { t } = useTranslation()

		const MEDIA = useCurrentMedia()
		const isTablet = MEDIA === 'TABLET'

		return (
			<HStack
				space={2}
				align="center"
				just="space-between"
				w="100%"
				// 32px as normal + 5px to align with Editor Channel/Schedule button on Tablet
				pr={isTablet ? '37px' : 6}
				// 22px is space[6] which is 32px minus 10px which we already have as a padding from rounded corners of Sidebar
				pl="22px"
				h={`${HEADER_HEIGHT}px`}
			>
				{isTablet && isEditorOpen ? (
					<Button.Multi
						state={isFeedOpen ? 'on' : 'off'}
						bgs={{ off: 'white_100', on: 'blue_10', disabled: 'blue_10' }}
						icon={{ Icon: Icons.Sidebar, position: 'left' }}
						onClick={() => onMsg({ type: 'on_toggle_drafts' })}
					>
						{t('content:channel.header.drafts', 'Drafts')}
					</Button.Multi>
				) : (
					<HStack space={6} w="100%" align="center">
						<Heading h={3} whiteSpace="nowrap">
							{truncate(channel.title || `@${channel.name}`, 24)}
						</Heading>
						{isEditorOpen ? <Search onMsg={onMsg} /> : null}
					</HStack>
				)}
				<HStack space={2} position="static">
					<Button.Multi
						stopPropagation={false}
						onClick={() => onMsg({ type: 'on_click_new_draft' })}
						secondaryAction={({ isClickAllowed }) => (
							<Templates
								editorStore={editorStore}
								resetEditorState={resetEditorState}
								trigger={({ isOpen, onClick }) => (
									<Button.MultiSecondary
										showHr
										border="blue_20"
										isClickAllowed={isClickAllowed}
										icon={isOpen ? Icons.ChevronUp : Icons.ChevronDown}
										onClick={onClick}
									/>
								)}
							/>
						)}
					>
						{t('content:channel.header.new', 'New')}
					</Button.Multi>
					<Button.Multi
						state={isFeedOpen ? 'on' : 'off'}
						bgs={{ off: 'white_100', on: 'blue_10', disabled: 'blue_10' }}
						icon={{ Icon: Icons.MessageSquare, position: 'left' }}
						onClick={() => onMsg({ type: 'on_toggle_feed' })}
					>
						{t('content:channel.header.feed', 'Feed')}
					</Button.Multi>
				</HStack>
			</HStack>
		)
	},
)

export const ChannelScreenHeader = (props: PropsNotMobile & PropsMobile) => {
	const MEDIA = useCurrentMedia()
	const isMobile = MEDIA === 'MOBILE'
	return isMobile ? <Mobile {...props} /> : <NotMobile {...props} />
}
