import type { AxiosError } from 'axios'
import { useMutation } from 'react-query'

import type { JsonApiErrorResponse, ParsedError } from '@prostpost/jsonapi'
import { parseFirstError } from '@prostpost/jsonapi'

import { axiosInstance } from 'app/config/network'

const knownErrors = ['CANNY_KEY_IS_NOT_SET'] as const

const obtainCannySSOToken = (): Promise<string> =>
	axiosInstance
		.post<string>('/auth/canny')
		.catch((e: AxiosError<JsonApiErrorResponse>) => {
			throw parseFirstError(e, knownErrors)
		})
		.then(response => response.data)

export const useCannySSO = () => {
	return {
		authCannyMutation: useMutation<string, ParsedError<(typeof knownErrors)[number]>>(obtainCannySSOToken),
	}
}
